import React, {useState} from 'react';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton';
import { useLazyIndexValuesQuery } from '../../store/indexOneApi';

import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField'

//data
import { DataGrid,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';

const columns = [
  { field: 'time', headerName: 'Time', flex: 1 },
  { field: 'value', headerName: 'Index Value', flex:1 },
]

const durations = [
  {value:'d',label:'Day'},
  {value:'mo',label:'Month'},
  {value:'y',label:'Year'},
  {value:'ytd',label:'Year-to-date'},
  {value:'qtd',label:'Quarter-to-date'},
  {value:'itd',label:'Inception-to-date'}
]

const increments = [
  {value:'eod',label:'End-of-day'},
  {value:'h',label:'Hour'},
  {value:'d',label:'Day'},
  {value:'w',label:'Week'},
  {value:'mo',label:'Month'},
  {value:null,label:'None'}
]

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

export default function IndexValuesExport(props) {
    const [values, setValues] = useState([])
    const [duration, setDuration] = useState('ytd')
    const [durationValue, setDurationValue] = useState(null)
    const [increment, setIncrement] = useState('eod')
    const [limit,setLimit] = useState(null)
    const [trigger,{ data = [], error, isLoading, isFetching }] = useLazyIndexValuesQuery()

    const handleSubmit = async () => {
      var d = duration
      if((durationValue !== null) && (!['ytd','itd','qtd'].includes(duration))){
        d = durationValue.toString() + duration
      } else {
        d = duration
      }
      var args = {
        duration:d,
        increment:increment,
        id:props.indexId
      }
      if(limit){
        args.limit = limit
      }
      //const vals = await getIndexValues(props.indexId,args)
      const vals = await trigger(args,true).unwrap()
      console.log(vals)
      setValues(vals)
    }

  return (
    <Container disableGutters>
      <Stack direction='column' spacing={1}>
      <Stack direction='row' spacing={3}>
        <TextField
            id="outlined-select-currency-native"
            select
            fullWidth
            label="Select Duration"
            value={duration}
            onChange={e=>setDuration(e.target.value)}
          >
            {durations.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
        </TextField>
        { !['ytd','itd','qtd'].includes(duration) &&
          <TextField
            fullWidth
            id="outlined-number"
            label="Duration Value"
            type="number"
            value={durationValue}
            onChange={e=>setDurationValue(e.target.value)}
          />
        }
      </Stack>
      <TextField
            id="outlined-select-currency-native"
            fullWidth
            select
            label="Select Increments"
            value={increment}
            onChange={e=>setIncrement(e.target.value)}
          >
            {increments.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
      </TextField>
      <TextField
            fullWidth
            id="outlined-number"
            label="Limit"
            type="number"
            value={limit}
            onChange={e=>setLimit(e.target.value)}
      />
      <LoadingButton loading={isLoading} variant='outlined' fullWidth onClick={e=>handleSubmit()}>Get Values</LoadingButton>
      <DataGrid
        getRowId={(r) => r.time}
        rows={values}
        rowHeight={25}
        columns={columns}
        loading={isLoading}
        autoHeight={true}
        components={{
          Toolbar: CustomToolbar,
        }}
      />
      </Stack>
    </Container>
  )
}