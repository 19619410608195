import React, { useState, useEffect } from 'react';
import { Container,Box,LinearProgress,Button,Grid,Alert,Link,AlertTitle,Stack,TextField,MenuItem } from '@mui/material';


import FileUploadIcon from '@mui/icons-material/FileUpload';

export default function WeightingConfig(props) {

  return (
    <Container maxWidth={false} sx={{height:'100%',padding:'2em'}}>
      <TextField onChange={e => props.handleChange({"rebalancing_config":{...props.indexSpecifications.rebalancing_config,...{"type":e.target.value}}})} value={props.indexSpecifications.rebalancing_config.type} select variant="outlined" size='small' id="universe-select" label="Universe">
          <MenuItem key="equal" value="equal">equal</MenuItem>
          <MenuItem key="proportional" value="proportional">proportional</MenuItem>
          <MenuItem key="default" value="default">default</MenuItem>
        </TextField>
    </Container>
  );
}