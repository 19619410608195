import React, { useState } from "react";
import ReactDOM from "react-dom";
import styled from "@emotion/styled";
import {set} from 'lodash/fp'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Tooltip, Container, Grid, Stack, Box, RootRef, ListItem, IconButton, Button, Grow, Collapse, Paper, Slider } from "@mui/material";

//
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

//icons
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

//inputs
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog'
import SecuritySearch from './SecuritySearch'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

//timeline
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import HotelIcon from '@mui/icons-material/Hotel';
import RepeatIcon from '@mui/icons-material/Repeat';
import Chip from '@mui/material/Chip';

const schedules = {
  "0 16 1 1 *": "Annually",
  "0 16 1 */3 *": "Quarterly",
  "0 16 1 * *": "Monthly",
  "0 16 * * FRI": "Weekly",
  "0 16 * * *": "Daily"
}

const currencies = {
  "local":"Local",
  "USD": "USD",
  "EUR": "EUR",
  "GBP": "GBP",
  "CAD": "CAD"
}

const defaultCorporateActionValues = {
  "dividend": {
    "deployment_scheme": "portfolio",
    "deploy_at": {
      "expression_type": "date",
      "value": "ex_date"
    }
  },
  "split":{
    "deployment_scheme": "security",
    "expression_type":"date",
    "value":"ex_date",
    "deploy_at":{
      "expression_type": "date",
      "value": "ex_date"
    }
  }
}

const deploymentSchemes = {
  "portfolio":"Portfolio",
  "security":"Security",
  "equal":"Equal"
}

const deploymentTimes = {
  "ex_date":"Ex Date",
  "payment_date":"Payment Date"
}


export default function IndexSpecifications(props) {
  const [securitySearchShow, setSecuritySearchShow] = useState(false)

  const addBenchmark = (item) => {
    let newBenchmarks = Array.from(props.spec.benchmark)
    newBenchmarks.push(item)
    props.setSpec(p => ({ ...p, benchmark: newBenchmarks }))
    setSecuritySearchShow(false)
  }

  const deleteBenchmark = (idx) => {
    let newFilters = Array.from(props.spec.benchmark)
    newFilters.splice(idx, 1)
    props.setSpec(p => ({ ...p, benchmark: newFilters }))
  }

  const toggleCorporateAction = (key) => {
    let newState = JSON.parse(JSON.stringify(props.spec))
    if (newState.adjustment_policies.hasOwnProperty(key)){
      delete newState.adjustment_policies[key]
    } else {
      newState.adjustment_policies[key] = defaultCorporateActionValues[key] || {}
    }
    props.setSpec(newState)
  }


  return (
    <Container maxWidth={true} disableGutters={true} sx={{maxHeight:'100%'}}>
      <Dialog sx={{padding:'1em'}} open={securitySearchShow} onClose={e=>setSecuritySearchShow(false)}>
        <SecuritySearch
          setShow={setSecuritySearchShow}
          show={securitySearchShow}
          selected={[]}
          selectFunction={e => addBenchmark(e)}
        />
      </Dialog>
      <Grid container spacing={4}>
        <Grid item>
        <Stack direction='column' spacing={2}>
        <Typography>Index Timing</Typography>
        <Timeline position="right" sx={{maxHeight:"55vh",overflow:'auto',overflowX:'auto',margin:0,padding:0}}>
        {props.schedule.map((event) => {
            //const thisId = row.id.concat('-',row.version.toString())
            return(
              <TimelineItem>
              <TimelineOppositeContent
                sx={{ m: 'auto 0',marginLeft:0, paddingLeft:0 }}
                align="right"
                variant="body2"
                color="text.secondary"
              >
                {event.time}
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot color="primary" variant="outlined">
                  <RepeatIcon />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{ py: '23px', px: 1 }}>
                  <Chip label="Rebalancing" size="small" />
              </TimelineContent>
            </TimelineItem>
            )
        })}
    </Timeline>
    </Stack>
        </Grid>
        <Grid item>
          <Stack direction='column' spacing={2}>
          <Typography>Index Timing</Typography>
          <TextField
            size='small'
            id="start_time"
            label="Start Time"
            type="date"
            value={props.spec.start_time}
            onChange={e => props.setSpec(p => ({ ...p, start_time: e.target.value }))}
            sx={{ width: 220 }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            size='small'
            id="sched"
            label="Rebalancing Schedule"
            select
            value={props.spec.schedule}
            onChange={e => props.setSpec(p => ({ ...p, schedule: e.target.value }))}
          >
            {Object.entries(schedules).map(([key, value]) =>
              <MenuItem key={key} value={key}>
                {value}
              </MenuItem>
            )}
          </TextField>
          </Stack>
        </Grid>
        <Grid item>
          <Stack direction='column' spacing={2}>
          <Typography>Calculation Parameters</Typography>
          <TextField
            size='small'
            id="start_value"
            label="Start Value"
            type="number"
            value={props.spec.start_value}
            onChange={e => props.setSpec(p => ({ ...p, start_value: e.target.value }))}
          />
          <TextField
            size='small'
            id="base_currency"
            label="Base Currency"
            select
            value={props.spec.base_currency}
            onChange={e => props.setSpec(p => ({ ...p, base_currency: e.target.value }))}
          >
            {Object.entries(currencies).map(([key, value]) =>
              <MenuItem key={key} value={key}>
                {value}
              </MenuItem>
            )}
          </TextField>
          <TextField
            size='small'
            id="transaction_cost_rate"
            label="Transaction Cost Rate"
            type="number"
            value={props.spec.transaction_cost_rate*100}
            onChange={e => props.setSpec(p => ({ ...p, transaction_cost_rate: e.target.value/100 }))}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
          <TextField
            size='small'
            id="withholding_tax_rate"
            label="Withholding Tax Rate"
            type="number"
            value={props.spec.withholding_tax_rate}
            onChange={e => props.setSpec(p => ({ ...p, withholding_tax_rate: e.target.value }))}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
          </Stack>
        </Grid>
        <Grid item direction='column'>
          <Tooltip title='Compare index performance to a list of benchmarks'><Typography>Benchmarks</Typography></Tooltip>
          <List sx={{ width: '100%', bgcolor: 'background.paper', maxHeight: '80vh', overflow: 'auto' }}>
            {props.spec.benchmark.map((item, idx) =>
              <Grow
                in={true}
                timeout={Math.min(idx * 1000, 10000)}
              >
                <ListItem
                  secondaryAction={
                    <IconButton edge="end" aria-label="delete" onClick={e => deleteBenchmark(idx)}>
                      <DeleteOutlineIcon />
                    </IconButton>
                  }
                  alignItems="flex-start"
                >
                  <ListItemAvatar>
                    <Avatar alt={item[1]} src={`https://storage.googleapis.com/iex/api/logos/${item[1]}.png`} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={item[0]}
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: 'inline' }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          {item[1]}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </Grow>
            )}
            <IconButton onClick={e => setSecuritySearchShow(true)} aria-label="select">
              <AddIcon color='inherit' />
            </IconButton>

          </List>
        </Grid>
        <Grid item>
          <Tooltip title='Corporate action methodology'><Typography>Corporate Actions</Typography></Tooltip>
          <Stack direction='row' spacing={2}>
          <Stack direction='column' spacing={1}>
            <FormControlLabel control={<Switch checked={props.spec.adjustment_policies.dividend} onChange={e=>toggleCorporateAction("dividend")} />} label="Dividend" />
            {props.spec.adjustment_policies.dividend && //if dividend selected, show options
              <Stack direction='column' spacing={1}>
              <TextField
                id="dividend-deployment-scheme"
                size="small"
                select
                label="Deployment Scheme"
                onChange={e=>props.setSpec(set(["adjustment_policies","dividend","deployment_scheme"],e.target.value,props.spec))}
                value={props.spec.adjustment_policies.dividend.deployment_scheme || ""}
                defaultValue="portfolio"
                helperText="Select dividend deployment scheme"
              >
                {Object.entries(deploymentSchemes).map(([key,label]) => (
                  <MenuItem key={key} value={key}>{label}</MenuItem>
                ))}
              </TextField>
              <TextField
              id="dividend-deployment-time"
              size="small"
              select
              label="Deployment Time"
              onChange={e=>props.setSpec(set(["adjustment_policies","dividend","deploy_at","value"],e.target.value,props.spec))}
              value={props.spec.adjustment_policies.dividend.deploy_at.value || ""}
              defaultValue="portfolio"
              helperText="Select dividend deployment time"
            >
              {Object.entries(deploymentTimes).map(([key,label]) => (
                <MenuItem key={key} value={key}>{label}</MenuItem>
              ))}
            </TextField>
            </Stack>
            }
            </Stack>
            <Stack direction='column' spacing={1}>
            <FormControlLabel control={<Switch checked={props.spec.adjustment_policies.split} onChange={e=>toggleCorporateAction("split")} />} label="Split" />
            {props.spec.adjustment_policies.split && //if dividend selected, show options
              <Stack direction='column' spacing={1}>
              <TextField
                id="split-deployment-scheme"
                size="small"
                select
                label="Deployment Scheme"
                onChange={e=>props.setSpec(set(["adjustment_policies","split","deployment_scheme"],e.target.value,props.spec))}
                value={props.spec.adjustment_policies.split.deployment_scheme || ""}
                defaultValue="portfolio"
                helperText="Select split deployment scheme"
              >
                {Object.entries(deploymentSchemes).map(([key,label]) => (
                  <MenuItem key={key} value={key}>{label}</MenuItem>
                ))}
              </TextField>
              <TextField
              id="split-deployment-time"
              size="small"
              select
              label="Deployment Time"
              onChange={e=>props.setSpec(set(["adjustment_policies","split","deploy_at","value"],e.target.value,props.spec))}
              value={props.spec.adjustment_policies.split.deploy_at.value || ""}
              defaultValue="portfolio"
              helperText="Select split deployment time"
            >
              {Object.entries(deploymentTimes).map(([key,label]) => (
                <MenuItem key={key} value={key}>{label}</MenuItem>
              ))}
            </TextField>
            </Stack>
            }
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
}