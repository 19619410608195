import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PhoneIcon from '@mui/icons-material/Phone';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import HelpIcon from '@mui/icons-material/Help';
import ShoppingBasket from '@mui/icons-material/ShoppingBasket';
import ThumbDown from '@mui/icons-material/ThumbDown';
import ThumbUp from '@mui/icons-material/ThumbUp';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container'

//images
import indices from '../../images/indices.PNG'
import index from '../../images/index.PNG'
import weighting from '../../images/weighting.png'
import corporateactions from '../../images/index_corporateactions.png'
import distribution from '../../images/distribution.svg'

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
    style:{
      fontSize:18,
      color:'#000000'
    }
  };
}

const useStyles = makeStyles((theme) => ({
  root: { 
    flexGrow: 1,
    width: '100%',
    justifyContent:'center'
  },
  screen:{
    color:'#353b41',
    boxShadow:'10px 10px 27px 0px',
    borderRadius:10,
    width:'100%'
  },
  scroller:{
    flexGrow:'0'
  }
}));

export default function HorizontalTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root} style={{minHeight:450}}>
      <AppBar position="static" color="transparent" style={{boxShadow:'none'}}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          centered={true}
          scrollButtons="auto"
          indicatorColor="primary"
          aria-label="scrollable force tabs example"
          classes={{ root: classes.root, scroller: classes.scroller }}
        >
          <div style={{flexGrow:1}}/>
          <Tab label="Index Design" {...a11yProps(0)} />
          <Tab label="Backtesting"  {...a11yProps(1)} />
          <Tab label="Rebalancing"  {...a11yProps(2)} />
          <Tab label="Corporate Actions" {...a11yProps(3)} />
          <Tab label="Access & Distribution"  {...a11yProps(4)} />
          <div style={{flexGrow:1}}/>
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={1}>
        <Grid container spacing={3}>
            <Grid item sm={12} md>
                <Typography variant='h6'>
                  Design indices using a step-by-step rules-based methodology in a matter of minutes, while maintaining full control over the most important aspects of your index.
                </Typography>
                <Grid container>
                  <Grid item>
                    <Typography variant='h6'>
                      <ul>
                        <li>Backtest Duration</li>
                        <li>Reconstitution Frequency</li>
                        <li>Rebalancing Frequency</li>
                        <li>Tax Treatment</li>
                        <li>Weighting Scheme</li>
                      </ul>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant='h6'>
                      <ul>
                        <li>Universe Selection</li>
                        <li>Calculation Frequency</li>
                        <li>Leverage</li>
                        <li>Corporate Action Management</li>
                        <li>more...</li>
                      </ul>
                    </Typography>
                  </Grid>
                </Grid>
            </Grid>
            <Grid item sm={12} md>
              <img className={classes.screen} src={indices} />
            </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Grid container spacing={3}>
            <Grid item sm={12} md>
                <Typography variant='h6'>
                  Backtest index strategies or defined baskets on up to 15-years of curated data. Tweak index parameters based on detailed data on index constitution, risk, performance, and exposure.
                </Typography>
            </Grid>
            <Grid item sm={12} md>
              <img className={classes.screen} src={index} />
            </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={3}>
      <Grid container spacing={3}>
            <Grid item sm={12} md>
                <Typography variant='h6'>
                  Fully automated reconstitution and rebalancing based on pre defined rules, at any frequency or schedule, supporting a wide range of methodologies.
                </Typography>
                <Grid container>
                  <Grid item>
                    <Typography variant='h6'>
                      <ul>
                        <li>Equal Weigthing</li>
                        <li>Market Cap Weighting</li>
                        <li>Free-float Adjustments</li>
                        <li>Proprietary Schemes</li>
                        <li>Intra-day adjustments</li>
                      </ul>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant='h6'>
                      <ul>
                        <li>Weighting Constraints</li>
                        <li>Banding</li>
                        <li>Whitelists / Blacklists</li>
                        <li>Custom Universe</li>
                        <li>more...</li>
                      </ul>
                    </Typography>
                  </Grid>
                </Grid>
            </Grid>
            <Grid item sm={12} md>
              <img className={classes.screen} src={weighting} />
            </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={4}>
      <Grid container spacing={3}>
            <Grid item sm={12} md>
                <Typography variant='h6'>
                  Manage corporate actions which affect index constitution and performance, with broad coverage for standard and exotic events.
                </Typography>
                <Grid container>
                  <Grid item>
                    <Typography variant='h6'>
                      <ul>
                        <li>Cash Dividends</li>
                        <li>Stock Dividends</li>
                        <li>Delistings</li>
                        <li>Bankruptcies</li>
                      </ul>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant='h6'>
                      <ul>
                        <li>Rights Issues</li>
                        <li>Mergers & Acquisitions</li>
                        <li>Spin-offs</li>
                        <li>Splits</li>
                      </ul>
                    </Typography>
                  </Grid>
                </Grid>
            </Grid>
            <Grid item sm={12} md>
              <img className={classes.screen} src={corporateactions} />
            </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={5}>
      <Grid container spacing={3}>
            <Grid item sm={12} md>
                <Typography variant='h6'>
                  Determine index visibility and access rights, from completely private to full public access. Distribute index data in multiple different formats.
                </Typography>
                <Grid container>
                  <Grid item>
                    <Typography variant='h6'>
                      <ul>
                        <li>API</li>
                        <li>File Export</li>
                        <li>SFTP</li>
                      </ul>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant='h6'>
                      <ul>
                        <li>Websocket</li>
                        <li>Email</li>
                        <li>more...</li>
                      </ul>
                    </Typography>
                  </Grid>
                </Grid>
            </Grid>
            <Grid item sm={12} md>
              <img className={classes.screen} src={distribution} />
            </Grid>
        </Grid>
      </TabPanel>
    </div>
  );
}