import React, { useState } from "react";
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Box, Grid, Typography, Fab, Stack, Divider } from '@mui/material'

import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

//icons
import MyLocationIcon from '@mui/icons-material/MyLocation';
import RuleOutlinedIcon from '@mui/icons-material/RuleOutlined';
import CodeOutlinedIcon from '@mui/icons-material/CodeOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';


import FileUpload from './FileUpload'
import Alert from '@mui/material/Alert';

export default function BasketUpload(props) {

  const updateWeightingObj = (type,data) => {
    if(type == 'panel'){type = 'basket'}
    if(type.includes('unweighted')){
      props.setWeightingObjective(p => ({...p,universe:data}))
      props.setWeightingObjective(p => ({...p,type:'equal'}))
    } else {
      const wObj = {
        type:type,
        value:data
      }
      props.setWeightingObjective(wObj)
      //props.setWeightingObjective(p => ({...p,value:data}))
    }
    if(["basket","panel"].includes(type)){
      props.setWeightingHistoricalTab(Object.keys(data)[0])
    }
    props.setWeightingType(type)
    //setSnackbarMessage('File upload successful.')
    //setSnackbarOpen(true)
    props.setDialogOpen(false)
  }

  return (
      <>
        <Alert severity="info">
            Allowed file extensions: .CSV
            <br/>
            All date formats in YYYY-MM-DD
            <br/>
            No column headers except for those specified in the samples below
          </Alert>
          <Grid container spacing={3} sx={{paddingTop:'1em'}} alignItems='stretch'>
            <Grid item xs={6}>
              <FileUpload setWeighting={updateWeightingObj} type='panel'/>
            </Grid>
            <Grid item xs={6}>
              <FileUpload setWeighting={updateWeightingObj} type='basket'/>
            </Grid>
            <Grid item xs={6}>
              <FileUpload setWeighting={updateWeightingObj} type='basket_unweighted'/>
            </Grid>
            <Grid item xs={6}>
              <FileUpload setWeighting={updateWeightingObj} type='fixed'/>
            </Grid>
            <Grid item xs={6}>
              <FileUpload setWeighting={updateWeightingObj} type='fixed_unweighted'/>
            </Grid>
          </Grid>
      </>
  )
}