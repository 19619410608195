import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import ContactForm from '../../elements/ContactForm'

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'

//accirdion
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

//fab
import Fab from '@mui/material/Fab';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';


//card
import Paper from '@mui/material/Paper' 
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import StarIcon from '@mui/icons-material/StarBorder';


//images
import isignup1 from '../../../images/pillar/signup1.jpg'
import isignup2 from '../../../images/pillar/signup2.jpg'
import isignup3 from '../../../images/pillar/signup3.jpg'
import isignup4 from '../../../images/pillar/signup4.jpg'
import isignup5 from '../../../images/pillar/signup5.jpg'
import isignup6 from '../../../images/pillar/signup6.jpg'

import i1a from '../../../images/pillar/1a.jpg'
import i1b from '../../../images/pillar/1b.jpg'
import i1c from '../../../images/pillar/1c.jpg'
import i2a from '../../../images/pillar/2a.jpg'
import i3a from '../../../images/pillar/3a.jpg'
import i3b from '../../../images/pillar/3b.jpg'
import i3c from '../../../images/pillar/3c.jpg'
import i3d from '../../../images/pillar/3d.jpg'

import infographic_1 from '../../../images/pillar/infographic_1.jpg'
import infographic_2 from '../../../images/pillar/infographic_2.jpg'
import infographic_3 from '../../../images/pillar/infographic_3.jpg'
import infographic_4 from '../../../images/pillar/infographic_4.jpg'
import infographic_5 from '../../../images/pillar/infographic_5.jpg'


//tab
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';




const commonTerms = {
  "Custom indexing": "custom indexing is the process of setting specific parameters on the stocks you’d like to invest money in, allowing you to personalize your investments based on your individual values, goals, preferences, risk tolerance and tax positioning.",
  "Direct indexing": "direct indexing is an investing strategy that involves purchasing the individual stocks within an index, maintaining the same weights in the index.",
  "Active management": "active management involves an investment manager making investment decisions by tracking the performance of an investment portfolio.",
  "Passive management": "passive management involves a fund’s portfolio mirroring a market index, by selecting stocks to be included in a portfolio, unlike active management. ",
  "Market index": "A market index is a hypothetical portfolio that contains investment holdings. The value of a market index is based on the prices of the underlying holdings. ",
  "Efficient market hypothesis": "EHM, or efficient market hypothesis is a theory coined by Eugene Farma, which states that active managers can beat the market only for a given period of time, as their success is simply a matter of chance. EHM suggests that long-term passive management delivers better results than asset management. ",
  "Active investing": "active investing involves the ongoing buying and selling of securities by monitoring market index.",
  "Passive investing": "passive investing is a long-term strategy that involves buying securities that mirrors a market index.",
  "Thematic investing": "thematic investing focuses on investing in long-term or macro-level trends. Examples of thematic investment themes include water, robotics & AI, gaming & e-sports, and space exploration.",
  "ESG investing": "ESG investing emphasizes on investments that prioritizes optimal environmental, social and governance outcomes.",
  "Factor investing": "factor investing is a type of portfolio management strategy that targets quantifiable metrics or factors that can explain differences in stock returns. These factors often include value, size, volatility, momentum, and quality. ",
  "ETF": "exchange traded funds, or ETFs refer to a type of investment fund that is traded on a stock exchange. An ETF usually tracks a generic market index and allows an investor to potentially lower risks and exposure, while diversifying their portfolio.",
  "RIA": "A registered investment advisor, or RIA, is an individual or firm that advises clients on investment decisions and manages their investment portfolios.",
  "Backtesting": "backtesting allows an investor to test an investment strategy using historical data to assess how it would have performed before earning actual returns.  ",
  "Rebalancing": "rebalancing involves the process of realigning the weightings of assets within a portfolio, by buying or selling the assets to maintain the original or desired level of asset allocation or risk. ",
  "Systematic portfolio": "a systematic portfolio contains securities that maintains a price higher than the predetermined level by a systematic manager. A systematic portfolio strategy invovvles trading decisions based on market price trends. ",
  "Rules-based strategies": "a rules-based investment strategy follows smart investment rules and aims to deliver active returns in a cost-efficient manner.",
  "Active returns": "an active return is the percentage difference between a benchmark and the actual return.",
  "Index provider": "an index provider is a firm that creates, calculates and maintains market indices based on any given investment strategy. ",
  "Sustainable investing": "sustainable investing is a type of investment strategy that prioritizes environmental, social and corporate governance impacts before investing in a particular company, venture or  fund. It is also called ESG investing or SRI.",
  "Investment strategy": "an investment strategy is a set of principles that guide an investor to make sound investment decisions based on their financial goals, values, risk tolerance and preferences. ",
  "Alpha investment strategies": "alpha strategies are active investment strategies that choose investments that have the potential to beat the market. Alpha is also known as “excess returns” or “abnormal rate of return.”",
  "Benchmarking": "benchmarking is the process of setting a standard against which the performance of an investment strategy can be measured. ",
  "Reconstitution": "reconstitution is the re-evaluation of a market index to ensure that an index reflects up-to-date market cap and is balanced. ",
  "Bonds": "a bond is a type of security where the issuer of a bond owes the holder of the bond a debt, and the issuer is obligated to repay the principal of the bond at the maturity date, as well as interest on the bond. ",
  "Asset allocation": "asset allocation is the process of dividing an investment among different types of assets, such as stocks, bonds and cash. ",
  "Quant": "quant, or quantitative analysis, is the process of using mathematical and statistical methods to make investment decisions.",
  "Index Funds": "index funds are a type of mutual fund or exchange-traded fund (ETF) that aim to replicate the performance of a specific market index.",
  "Mutual Funds": "a mutual fund is a type of investment fund that pools money from several investors to purchase securities. ",
  "Derivatives Structured Products": "derivative structured products are financial instruments that combine derivatives with other underlying assets to create investment products with unique risk and return characteristics.",
  "Hedge Funds": "similar to mutual funds, a hedge fund is a type of pooled investment fund that trades in relatively liquid assets. Hedge funds primarily use portfolio construction, complex trading and risk management techniques in an attempt to improve performance. ",
  "Index Front-running": "traders who watch market prices know when an index fund will update its components, allowing them to front-run the trade by buying or selling the shares to get ahead of the market and gain an edge. This is not considered illegal because it rewards individuals who pay close attention to information that already exists in the market. However, SEC Rule 17(j)-1 prohibits insiders from taking advantage of their knowledge of client trades for personal gain."
}

const faqs = {
  "What is index construction?": "Index construction refers to the process of creating and maintaining a market index, which is a hypothetical portfolio of securities that represents a specific segment of the overall market.",
  "What is the difference between custom indexing vs direct indexing?": "While direct indexing requires you to choose amongst pre-packaged solutions, custom indexing allows for unlimited customization within different factors, allowing investors to build a portfolio that truly reflects their unique investment goals and preferences. ",
  "What is the difference between active investing vs passive investing?": "While active investing strategies focus on individual securities and a more hands-on approach, passive investing strategies tend to focus on purchasing shares of index funds or ETFs in an attempt to mirror or beat the performance of market indexes.",
  "What is the difference between securities vs stocks?": "A security is the ownership or debt with value. A stock is a type of security that gives the holder of the stock ownership or equity of the publicly-traded company.",
  "What is the difference between stocks vs shares?": "A share is a unit of ownership measured by the number of shares you own, whereas a stock is a unit of equity, measured by the percentage of ownership of the company.",
  "What is the difference between portfolio manager vs asset manager?": "A portfolio manager handles investments and other financial products that make up a portfolio. An asset manager may also manage portfolios, but they mainly handle cash and assets, which a portfolio manager does not. ",
  "What is the difference between Index vs Benchmark?": "While a benchmark only serves as a standard to measure index performance against, an index is created for a variety of reasons, and one of its purposes is to act as a benchmark. In other words, a benchmark is usually always an index, but an index doesn’t necessarily have to be a benchmark.",
  "What is the difference between reconstitution vs rebalancing?": "Rebalancing is a more automated process where price and market-cap weighted indices are rebalanced automatically. Reconstitution, on the other hand, requires the manual adding and removal of securities from an index, based on whether or not these securities are meeting index criteria.",
  "What is the difference between an index fund vs ETF": "An index fund is a mutual fund which tracks an index, while an ETF is an exchange traded asset tracking the performance of an index.",
  "Can you invest directly in an index?": "An index is a hypothetical basket of stocks. In order to invest in an index, it would need to be an investable product that tracks an index. A few examples of an investable product are mutual funds and ETFs. ",
  "How does index rebalancing work?": "Index rebalancing refers to the process of adjusting the composition and weights of securities within an index. It is typically done periodically to maintain the index's target representation and desired characteristics. Index rebalancing helps maintain the integrity of the index and ensures that it continues to accurately reflect the targeted market segment. It allows for adjustments to account for changes in market conditions, company fundamentals, and other factors that may affect the composition and weights of the index components. ",
  "How to create a stock index?": "Creating a stock index involves several steps and considerations, including defining the index objective, selecting the index components, determining the weighting methodology, setting the initial index values, establishing the index calculation methodology, regular maintenance and rebalancing, index calculation and dissemination and index governance and oversight. ",
  "How to construct an index for research?": "Constructing an index for research purposes involves a tailored approach to meet specific research objectives. This includes defining research objectives, selecting the relevant securities, determining inclusion and exclusion criteria, determining weighting methodology, setting the index universe, establishing index calculation methodology, data collection and management, performing backtesting and validation, documenting index construction methodology and analyzing and interpreting results. ",
  "How to create your own index?": "Creating your own index requires careful consideration of various factors, including your investment objectives, the availability of data, and the resources needed to maintain and calculate the index. It may be beneficial to seek professional advice or consult with experts in index construction to ensure the integrity and accuracy of your self-created index.",
  "How to make your own index fund?": "Creating your own index fund involves several steps and considerations: define the investment objective, select the index components, determine the weighting methodology, set the initial fund composition, establish a rebalancing strategy, implement the fund's portfolio, track and monitor performance, consider legal and regulatory requirements, consider administration and custody, develop a distribution strategy, and comply with reporting and governance. "
}

document.querySelectorAll('a[href^="#"]').forEach(anchor => {
  anchor.addEventListener('click', function (e) {
    e.preventDefault();

    document.querySelector(this.getAttribute('href')).scrollIntoView({
      behavior: 'smooth'
    });
  });
});



export default function Pillar(props) {
  
  const navigate = useNavigate()
  document.title = `The Ultimate Guide to Custom Index Construction | Index One`
  const [selected, setSelected] = useState('fa')

  const returnImage = (img, link, altText="index one") => {
    return (
      <Stack direction='row' alignItems='center' justifyContent='center' sx={{ marginTop: '5em', marginBottom: '5em' }}>
        <a href={link}><img alt={altText} style={{ display: 'block', maxHeight: '500px', maxWidth: '700px', height: 'auto', width: 'auto' }} src={img} /></a>
      </Stack>
    )
  }

  const returnInfographic = (img, link, altText="index one") => {
    return (
      <Stack direction='row' alignItems='center' justifyContent='center'>
        <a href={link}><img alt={altText} style={{ display: 'block', maxHeight: '500px', maxWidth: '600px', height: 'auto', width: 'auto' }} src={img} /></a>
      </Stack>
    )
  }

  const returnVideo = (embedId) => {
    return (
      <Stack direction='row' alignItems='center' justifyContent='center'>
        <iframe
          width="853"
          height="480"
          src={`https://www.youtube.com/embed/${embedId}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      </Stack>
    )
  }



  return (

    <Container component="main" disableGutters maxWidth={false}>
      <a id="top" />
      <Fab sx={{ position: 'fixed', bottom: 20, right: 20 }} color="primary" aria-label="scroll-up" size='small' href='#top'>
        <ArrowUpwardIcon />
      </Fab>

      <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' style={{ backgroundColor: '#343a40', minHeight: '40vh', marginTop: 0, padding: '5em' }}>
        <Typography style={{ color: '#fafafa', margin: '1rem', textAlign: 'center' }} variant="h3">The Ultimate Guide to Custom Indexing </Typography>
        <br />
        <Container style={{ textAlign: 'center' }}>
          <Typography style={{ color: '#fafafa', margin: '1rem', marginBottom: '2rem' }} variant="h5">Learn everything you need to know about how to build a custom index,<br /> and provide a differentiated service to your clients by managing specific investment needs.</Typography><br />
        </Container>
      </Box>


      <div style={{ clipPath: 'polygon(0 0, 100% 0, 100% 80%, 0% 100%)', padding: '7em 0%', background: '#3d5a80', color: '#fafafa', overflow: 'hidden', paddingTop: '200px', paddingBottom: '200px' }}>
        <Container disableGutters maxWidth='lg'>
          <Typography variant='h2' style={{ textAlign: 'center', margin: '1rem' }}>What is custom indexing?</Typography>
        </Container>
      </div>
      <Stack direction='column'>
        <Container style={{ textAlign: 'center' }}>
          <Grid container spacing={3} sx={{paddingTop:'2em',paddingBottom:'2em'}}>
            <Grid item xs={12} sm={6}>
              <Paper sx={{padding:'2em'}}>
              <b>Custom indexing is personalized indexing.</b> It is a process of creating an investment portfolio based on the specific needs and objectives of the investor. In finance, indexing refers to the practice of tracking the performance of a specific market index, such as the S&P 500 or the Dow Jones Industrial Average. <br/><br/>However, these broad market indices may not always align with the investor's specific investment goals or risk tolerance.
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper sx={{padding:'2em'}}>
              Custom indexing involves creating a tailored investment portfolio that is designed to meet the specific needs of the investor. The process typically begins with an assessment of the investor's goals, risk tolerance, and investment preferences. <br/><br/>The investor's investment objectives may be focused on income generation, capital preservation, or capital growth.
              </Paper>
            </Grid>
          </Grid>
          <Typography>
            In simple terms, custom indexing is the process of setting specific parameters on the stocks you’d like to invest money in. This gives you control over the individual stocks you want to invest in, unlike investing in mutual funds or ETFs that provide ‘pre-packaged’ products with limited ability to personalize.
          </Typography>
          <Typography><b>Custom indexing lets you personalize your investments based on your individual values, goals, preferences, risk tolerance and tax positioning.</b>
            <br />
            <br />This means you <a href="https://www.linkedin.com/pulse/custom-index-strategies-high-net-worth-clients-one-sri-rangan/">don’t have to work with a standard index</a> that doesn’t make sense to your situation and investment objectives.</Typography>
        <br/>
        <br/>


            <Paper sx={{padding:'2em'}}>
              <b>Define direct indexing:</b>
              <br/>
              The terms custom indexing and direct indexing are sometimes used interchangably.<br/><br/> 
              Direct indexing refers to an investment approach that allows investors to build a portfolio that closely replicates the performance of a specific benchmark index.
              <br/><br/>
              In <b>direct indexing</b>, an investor constructs a portfolio by purchasing the underlying securities of the index in the same weightings as the benchmark. On the other hand, <b>custom indexing</b> refers to the creation of a unique index that is specifically designed to meet certain criteria or objectives. 
              </Paper>
              <br/><br/>

              <Paper sx={{padding:'2em'}}>
              <b>Direct indexing for advisors:</b>
              <br/>
              Direct indexing can be a valuable tool for financial advisors in managing their clients' portfolios, promoting customization, tax efficiency, enhanced control, cost considerations and client education. 
              <br/><br/>
              This is facilitated by modern <a href="https://indexone.io/indexbuilder">direct indexing technology</a>, enabling advisors to provide custom index investing strategies, a personalized service and value-add to their clients.
              </Paper>
          <br/><br/>
              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Direct indexing software:</b><br />
             Index One provides clients with a state-of-the-art and comprehensive direct indexing solution that caters to various use cases witihn the financial industry.
                  <br/><br/>
                <a href="https://indexone.io/indexbuilder">Explore our index builder.</a>
              </Paper>

        </Container>

        <Stack direction='column'>
          <div style={{ clipPath: 'polygon(0 0, 100% 20%, 100% 80%, 0% 100%)', padding: '7em 0%', background: '#3d5a80', color: '#fafafa', overflow: 'hidden', paddingTop: '200px', paddingBottom: '200px' }}>
            <Container disableGutters maxWidth='lg'>
              <Typography variant='h2' style={{ textAlign: 'center', margin: '1rem' }}>Who uses custom indexing?</Typography>
            </Container>
          </div>
        </Stack>

        <Stack direction='column'>
          <Container style={{ textAlign: 'center' }}>
            <Typography>
            Custom indexing is used by a wide range of professionals and institutions, including investment managers, hedge fund managers, and pension fund managers. 
            <br />
            <Grid container spacing={3} sx={{paddingTop:'2em',paddingBottom:'2em'}}>
            <Grid item xs={12} sm={6}>
              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Investment managers</b> may create custom indexes to track specific sectors or investment themes, such as healthcare or emerging markets.
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Hedge fund managers</b> may create custom indexes to track their own proprietary investment strategies, giving them an edge in the market. 
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Pension fund managers</b> may use custom indexing to track specific asset classes or investment styles, such as value investing or growth investing.
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Individual investors</b> also use custom indexing. With the rise of exchange-traded funds (ETFs) and other passive investment vehicles, investors can create their own custom portfolios using indexes that track specific market segments or investment strategies.
              </Paper>
            </Grid>
          </Grid>
            <br/>
              <b>The advantages of custom indexing are leveraged by many players within the finance industry.</b> <br/><br/> Being a relatively new technology, many investment advisors, wealth managers, portfolio managers and equity research analysts are adopting custom indexing within their firms to cater to unique client needs and provide a differentiated service to get ahead of their competition.
              <br />
              <br />In addition, custom indexing is increasing in demand amongst investors as it allows them to <a href="linkedin.com/pulse/rise-passive-management-index-one-gayathiri-sri-rangan/">build a strong portfolio that clearly reflects their investment goals.</a> Indexing solutions like Index One facilitates easy and flexible custom indexing.
            </Typography>
          </Container>
        </Stack>
        <br/>
        <Grid Item xs={12} sm={8} sx={{textAlign:'center'}}>
            <Button onClick={e => navigate(`/login`)} variant="outlined" size="large">create your own basket of stocks</Button>
            </Grid>

        <Stack direction='column'>
          <div style={{ clipPath: 'polygon(0 0, 100% 20%, 100% 80%, 0% 100%)', padding: '7em 0%', background: '#3d5a80', color: '#fafafa', overflow: 'hidden', paddingTop: '200px', paddingBottom: '200px' }}>
            <Container disableGutters maxWidth='lg'>
              <Typography variant='h2' style={{ textAlign: 'center', margin: '1rem' }}>Why is custom indexing popular?</Typography>
            </Container>
          </div>
        </Stack>

        <Container maxWidth='false'>
          <Container>
            <Grid container spacing={5}>
              <Grid item>{returnInfographic(infographic_1, '/register')}</Grid>

              <Grid item sm={4}><Typography>
                <i>“By 2025, most financial advisors will use web-based software to create and manage Custom Indexes for their clients.” <a href="https://canvas.osam.com/Commentary/BlogPost?Permalink=custom-indexing-the-next-evolution-of-index-investing">(via OSAM)</a></i>
                <br />
                <br />
                Custom indexing is a powerful tool used by many professionals and institutions in finance. 
                <br /><br />
                It allows investors to track specific market segments or investment strategies, create investment products aligned with their investment goals, and manage risk effectively. As the financial industry continues to evolve, custom indexing is likely to become an even more important tool for investors and institutions alike.
                <br /><br />
                Custom indexing is a technology. This means that there is a much smaller margin for error, and limitations found within standard indexing are removed. <b>In addition to the personalization and flexibility</b> advantage that custom indexing brings in, it also <b>provides a low-cost and more automated solution, giving advisors and investors more opportunity to focus on their investment strategy and reap the other benefits it has to offer.</b>
                <br/>
                <br/>
              </Typography></Grid>
            </Grid>

            <Paper align='center' sx={{padding:'2em',height:'100%'}}>
              There are several custom index solutions on the market, including Index One's low-cost index calculation platform that provides a holistic indexing solution for financial professionals.<br/>
              <br/>
              <Stack direction='row' justifyContent='center'>

<Button onClick={e => navigate(`/contact`)} variant="outlined" size="large">Learn More</Button>

</Stack> </Paper>

              

          </Container>
        </Container>
        


        <div style={{ clipPath: 'polygon(0 0, 100% 20%, 100% 80%, 0% 100%)', padding: '7em 0%', background: '#3d5a80', color: '#fafafa', overflow: 'hidden', paddingTop: '200px', paddingBottom: '200px' }}>
          <Container disableGutters maxWidth='lg'>

            <Stack direction='column' spacing={5}>
              <Container style={{ textAlign: 'center' }}>
                <Typography variant='h2' style={{ textAlign: 'center', margin: '1rem' }}>What are the benefits of custom indexing?</Typography>
              </Container>
            </Stack>
          </Container>
        </div>

        <Container maxWidth='false'>
          <Container>
            <Stack direction='column' spacing={5}>
              <Container style={{ textAlign: 'center' }}>
                <Typography>
                One of the main benefits of custom indexing is that it <u>allows investors to align their investments with their specific objectives</u>, rather than simply tracking a broad market index. By creating a custom index, investors can tailor their investments to their unique preferences, risk tolerance, and investment goals.
                <br /><br />
Another benefit of custom indexing is that it allows investors to potentially <u>achieve better investment outcomes by targeting specific sectors, industries, or asset classes</u> that may be more appropriate for their investment goals. For example, an investor who is focused on income generation may benefit from a custom index that includes high-yielding dividend stocks, while an investor focused on growth may benefit from a custom index that includes technology stocks.
<br /><br />
                  There are a few benefits to custom indexing that you wouldn’t get by investing in a standard index. <b>The direct ownership of custom indices facilitates a high level of precision and informed financial decision making</b> within these aspects:<br/>
            <br/>
            <Grid container spacing={3} alignItems='stretch'>
            <Grid item xs={12} sm={3}>
              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Tax-loss harvesting:</b><br/>By owning individual shares within an index, custom indexing allows you to actively capture losses on an ongoing basis, especially with tech-enabled tax-loss harvesting. According to OSAM Research, automating the tax-loss harvesting process within a custom index “can add between 0.5%-1.0% of annual after-tax excess return.”
              </Paper>
            </Grid>

            <Grid item xs={12} sm={3}>
              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Allocation flexibility:</b><br/>ETFs, mutual funds and direct indexing limits your ability to choose asset allocations to your liking. With custom indexing, you are able to choose multiple allocations within one portfolio, based on your own unique exposures based on several factors such as region, size, factor or risk exposures, and more.
              </Paper>
            </Grid>

            <Grid item xs={12} sm={3}>
              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Distribution of risks:</b><br/> Custom indexing allows you to manage risks more efficiently by reducing concentrated stock risks. This can be done in a few ways, including building a custom portfolio that is optimized around the concentrated risk by underweighting or excluding the concentrated stock, or leverage tax loss harvesting to offset any capital gains from losses generated from stocks within the custom index.
              </Paper>
            </Grid>

            <Grid item xs={12} sm={3}>
              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>ESG and SRI:</b><br/> ESG (environmental, social, governance) and SRI (socially responsible investing) has evolved over the years, driven by varying demands in what should be included or excluded in a portfolio based on an investor’s values. Custom indexing has been, and will continue to pave the way for the future of ESG by providing investors the ability to personalize their portfolios, <a href="linkedin.com/pulse/esg-investing-etfs-portfolio-construction-index-one-sri-rangan/">tailored to very specific needs within ESG investing and SRI.</a>
              </Paper>
            </Grid>
            </Grid>
            
                <br />
                <Button onClick={e => navigate(`/contact`)} variant="outlined" size="large">Leverage custom indexing for your business</Button>
                </Typography>
              </Container>
            </Stack>


            {returnImage(isignup1, '/resources', "Free Download: Index One Overview. Find out how Index One is relevant to your business by discovering our DIY custom indexing capabilities. Download Now button.")}
            
            <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Custom indexing solutions:</b><br />
              Custom indexing solutions refer to specialized index construction and management services tailored to meet the specific needs of institutional clients, asset managers, or investors. These solutions offer flexibility and customization beyond standard off-the-shelf indices.
                  <br/><br/>
                  Index One provides a flexible tool to build, calculate, maintain and integrate custom indices. <a href="https://indexone.io/indexbuilder">Find out how.</a>
              </Paper>

            <br/>
            <br/>
            <br/>

          </Container>
        </Container>

        <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' style={{ backgroundColor: '#343a40', minHeight: '40vh', paddingTop: '2em', paddingBottom: '2em', marginBottom: '0px' }}>
          <Grid container justifyContent='space-around'>
            <Grid item xs={3}>
              <Stack direction='column' spacing={2} alignItems='center' justifyContent='center'>
                <Typography variant='h5' style={{ color: 'white' }}>You are a...</Typography>
                <Button fullWidth variant='contained' href='#financial_advisor' onClick={e => setSelected('fa')}>Investment / Financial Advisor</Button>
                <Button fullWidth variant='contained' href='#asset_manager' onClick={e => setSelected('am')}>Asset Manager</Button>
                <Button fullWidth variant='contained' href='#portfolio_manager' onClick={e => setSelected('am')}>Portfolio Manager</Button>
                <Button fullWidth variant='contained' href='#equity_firms' onClick={e => setSelected('am')}>Equity Research Analyst</Button>
                <Button fullWidth variant='contained' href='#etf_issuer' onClick={e => setSelected('am')}>ETF Issuer</Button>
                <Button fullWidth variant='contained' href='#esg_provider' onClick={e => setSelected('am')}>ESG Data Provider</Button>
                <Button fullWidth variant='contained' href='#hedge_fund' onClick={e => setSelected('am')}>Hedge Fund</Button>
                <Button fullWidth variant='contained' href='#structured_products' onClick={e => setSelected('am')}>Structured Products Provider</Button>
                <Button fullWidth variant='contained' href='#robo_advisor' onClick={e => setSelected('am')}>Robo Advisor</Button>
                <Button fullWidth variant='contained' href='#financial_media' onClick={e => setSelected('am')}>Financial Media Organization</Button>

              </Stack>
            </Grid>
            <Grid item xs={3}>
              <Stack direction='column' spacing={2} alignItems='center' justifyContent='center'>
                <Typography variant='h5' style={{ color: 'white' }}>You are looking for...</Typography>
                <Button fullWidth variant='contained' href='#index_backtesting' onClick={e => setSelected('fa')}>Index Backtesting</Button>
                <Button fullWidth variant='contained' href='#custom_index' onClick={e => setSelected('am')}>Custom Index Calculation</Button>
                <Button fullWidth variant='contained' href='#benchmarking' onClick={e => setSelected('am')}>Benchmarking</Button>
                <Button fullWidth variant='contained' href='#rebalancing' onClick={e => setSelected('am')}>Rebalancing</Button>
                <Button fullWidth variant='contained' href='#equity_research' onClick={e => setSelected('am')}>Equity Research Ratings</Button>
                <Button fullWidth variant='contained' href='#data_display' onClick={e => setSelected('am')}>Data Display</Button>
              </Stack>
            </Grid>
          </Grid>
        </Box>


        <div id='financial_advisor' style={{ clipPath: 'polygon(0 0, 100% 0, 100% 80%, 0% 100%)', padding: '7em 0%', background: '#3d5a80', color: '#fafafa', overflow: 'hidden', paddingTop: '200px', paddingBottom: '200px' }}>
          <Container disableGutters maxWidth='lg'>
            <Typography variant='h2' style={{ textAlign: 'center', margin: '1rem' }}>Custom Indexing for Financial Advisors</Typography>
          </Container>
        </div>
        <Container maxWidth='false'>
          <Container>
            <Stack direction='row' spacing={3}>
              <Stack direction='column'>
                <Typography>
                  <Typography variant='h3'>What does an investment adviser do?</Typography><br/>An investment advisor or a financial advisor is any independent individual or group that makes financial and investment analysis and recommendations in return for a fee, whilst upholding standards of fiduciary responsibility.<br /><br /> A registered investment advisor (RIA) is considered an SEC investment advisor if an advisor has sufficient capital and assets to be registered with the Securities and Exchange Commission.
                  <br />
                  <br />
                  <Typography variant='h3'>Common issues faced by investment advisers</Typography><br />
                  Being an investment advisor or a financial advisor comes with its challenges. The job of an investment advisor includes being an asset manager, financial planner, marketer and more. Some of the most common issues faced by investment and financial advisors include the following:
                </Typography>
                <br/>
              </Stack>
              <Grid item sx={{textAlign:'center'}}>{returnInfographic(infographic_2, '/register')} 
              <br/>
              <Button onClick={e => navigate(`/contact`)} variant="outlined" size="large">Learn more</Button></Grid>
              <Grid item sm={4}></Grid>
            </Stack>
            <br/>

            <Typography>
            <Grid container spacing={3} direction='column'>
            <Grid item xs={12} sm={6}>
              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Managing client expectations:</b><br/> The best financial advisors know how to manage client expectations in order to succeed. Every client has unique expectations, biases and preferences, and it is the job of an investment and financial advisor to understand this and provide a personalized service to their clients.
Custom indexing allows investment and financial advisors like yourself to define client objectives with ease, providing you with tools to build custom portfolios that better reflect client values and goals, and help you provide an overall differentiated service.
              </Paper>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Providing transparency:</b><br/> Investment advisors are increasingly adopting technology that <a href="https://www.linkedin.com/pulse/secs-possible-review-index-industry-alleged-conflict-one-sri-rangan/">provide clients with full transparency</a> into strategy performances.
This is possible with custom indexing tools that allow advisors to provide a high level of accuracy and transparency in the index methodology of calculating actual and hypothetical performance.
              </Paper>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Cost-efficient tools for investment advisors:</b><br/> Every investment and financial advisor aim to reduce operating costs by employing tools and technology that are cost-efficient. In addition, advisors are constantly competing with peers in the industry to provide a low-cost and personalized service to clients.
As an investment advisor, you have the opportunity to <a href="https://www.linkedin.com/pulse/declining-license-fees-index-providers-one-gayathiri-sri-rangan/">reduce expenses and focus on scaling your business</a> to ensure that your clients are getting the most value out of your services, by utilizing low-cost custom indexing platforms.
              </Paper>
            </Grid>
            </Grid>
            <br/><br/>
            <Grid Item xs={12} sm={8} sx={{textAlign:'center'}}>
            <Button onClick={e => navigate(`/login`)} variant="outlined" size="large">Custom indexing for investment advisors</Button>
            </Grid>
                <br />
              {returnImage(isignup2, '/resources', "Free Download: The New SEC Marketing Rules. Find out how RIAs are affected by the new SEC Marketing Rules and what you can do to comply with these rules. Download Now button.")}


              <Typography variant='h3'>Example of investment management: MoveUp Financial</Typography><br />
              With the increasing demand for index customization and flexible index calculation, investment advisors are constantly looking for custom indexing tools that remove barriers in building custom portfolios that reflect clients’ personal objectives and values.
              <br />
              <br />
              MoveUp Financial is an independent Registered Investment Advisor based in Texas, United States. MoveUp Financial designs and maintains actively managed investment solutions for investors at low cost, but found running systematic strategies in a cost-efficient manner to be a cumbersome process due to the many activities involved.
              <br />
              <br />
              <b>Custom indexing allowed MoveUp Financial to solve those problems and build a custom index based on their bespoke investment strategies, allowing the independent investment advisor to effectively track an index, resulting in higher accuracy and higher cost efficiency.</b>

              <br />
              <br />
              {returnVideo('HzNbL4SP3TM')}
              <br />
              {returnImage(isignup3, '/resources', "Free Download: MoveUp Financial Case Study. Find out how Index One supports RIAs such as MoveUp Financial with custom indexing capabilities. Download Now button.")}
            </Typography>
          </Container>
        </Container>




        <div id='asset_manager' style={{ clipPath: 'polygon(0 0, 100% 20%, 100% 80%, 0% 100%)', padding: '7em 0%', background: '#3d5a80', color: '#fafafa', overflow: 'hidden', paddingTop: '200px', paddingBottom: '200px' }}>
          <Container disableGutters maxWidth='lg'>
            <Typography variant='h2' style={{ textAlign: 'center', margin: '1rem' }}>Custom Indexing for Asset Managers</Typography>
          </Container>
        </div>
        <Container maxWidth='false'>
          <Container>
            <Stack direction='row' spacing={3}>
              <Stack direction='column'>
                <br />
                <Typography>
                  <Typography variant='h3'>What does an asset manager do?</Typography><br />
                  Asset management involves increasing the total wealth of a client by managing investments that have the potential to grow in value. There are different types of asset management, such as linear asset management or digital asset management. Asset managers carry out the functions of acquiring, maintaining and trading investments to maximize the value of an investment portfolio over time.

                  <br />
                  <br />
                  <Typography variant='h3'>Common issues faced by asset managers</Typography><br />
                  Asset management professionals face many challenges within the industry as you are required to keep up with ever-changing client needs and wants. Some of these challenges include the following:
                </Typography>
                <br/>
              </Stack>
              <Grid Item sx={{textAlign:'center'}}>{returnInfographic(infographic_3, '/register')}
              <br/>
              <Button onClick={e => navigate(`/contact`)} variant="outlined" size="large">Learn more</Button></Grid>
              <Grid item sm={4}>
              </Grid>
            </Stack>
            <br/>

            <Typography>


            <Grid container spacing={3} direction='column'>
            <Grid item xs={12} sm={6}>
              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Evolving client demands:</b><br />
                  Similar to investment advisors, <a href="https://www.linkedin.com/pulse/what-high-net-worth-clients-looking-index-one-gayathiri-sri-rangan/">asset managers are expected to deliver a better experience to clients</a>, now more than ever. This involves demonstrating a capacity to provide a high level of assurance and the ability to handle turbulence in volatile markets.
                  <br/><br/>Custom indexing tools act as an asset management software to provide a tailored service to your clients while maintaining a close relationship that establishes trust and promotes value-add.
              </Paper>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Clear value propositions:</b><br />
                  A good asset manager realizes the importance of outlining a clear value proposition by choosing the type of approach that one should take to cater to different clients. As an asset manager, you should think about operating with the highest level of transparency to provide clear and meaningful investment insights to clients, but this often requires more up-to-date and advanced technology that does not burn your pockets.
                 <br/><br/> Custom indexing solves this issue by providing asset management professionals like yourself with modern technology that promotes efficiency in operation as well as cost, allowing you to audibly convey your compelling value proposition to your prospects and clients.
              </Paper>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Integrating ESG and impact investing within portfolios:</b><br />
                  Asset managers are increasingly working to demonstrate high competence in delivering investment strategies returns in a positive and impactful manner. While some investors solely focus on financial returns, many investors are now demanding that asset management companies should reflect investment strategies that highlight ESG issues.
                  With a high interest in these types of strategies, asset managers are rethinking their approach to ESG investing and impact investing, by utilizing tools and technology that allow asset managers to clearly communicate this commitment to clients, eliminating any speculation of “greenwashing.”
                 <br/><br/> Custom indexing tools facilitate this goal by <a href="https://www.linkedin.com/pulse/esg-veil-index-one-gayathiri-sri-rangan/">allowing asset managers to build index models that are accessible to investors with different ESG values and objectives</a>, further highlighting the dedication to encourage a high degree of impact investing within client portfolios. 
              </Paper>
            </Grid>
            </Grid>
            <br/><br/>
            <Grid Item xs={12} sm={8} sx={{textAlign:'center'}}>
            <Button onClick={e => navigate(`/login`)} variant="outlined" size="large">Custom indexing for asset managers</Button>
            </Grid>

            </Typography>

          </Container>
        </Container>




        <div style={{ clipPath: 'polygon(0 0, 100% 20%, 100% 80%, 0% 100%)', padding: '7em 0%', background: '#3d5a80', color: '#fafafa', overflow: 'hidden', paddingTop: '200px', paddingBottom: '200px' }}>
          <Container disableGutters maxWidth='lg'>
            <Typography variant='h2' style={{ textAlign: 'center', margin: '1rem' }}>Custom Indexing for Portfolio Managers</Typography>
          </Container>
        </div>
        <Container maxWidth='false'>
          <Container>
            <Stack direction='row' spacing={3}>
              <Stack direction='column'>
                <br />
                <Typography variant='h3'>What does a portfolio manager do?</Typography><br />
                A portfolio manager is responsible for a myriad of activities that relate to fund investing. The job of an investment portfolio manager includes “investing in a mutual, exchange-traded or close-end fund’s assets, implementing its investment strategy, and managing day-to-day portfolio trading.” (via Investopedia)

                <br />
                <br />
                An active portfolio manager creates an investment strategy to attempt to beat the average market returns, whereas a passive portfolio manager mirrors an already existing market index and uses it as a benchmark to see similar returns over time. 
                
                <br/>
                <br/>
                This is usually done by utlizing a portfolio construction platform that supports automation, rebalancing and reporting, and facilitates index construction methods. 
                <br />
                <br />

                <Typography variant='h3'>Common issues faced by portfolio managers</Typography><br />
            
                Whether you are an active or passive portfolio manager, you may come across obstacles in your journey. Here are some challenges portfolio managers face:
              </Stack>
              <Grid Item sx={{textAlign:'center'}}>{returnInfographic(infographic_4, '/register')}
              <br/>
              <Button onClick={e => navigate(`/contact`)} variant="outlined" size="large">Learn more</Button></Grid>
              <Grid item sm={4}></Grid>
            </Stack>

            <Typography>
              {returnImage(i3b, '/contact')}


            <Grid container spacing={3} direction='column'>
            <Grid item xs={12} sm={6}>
              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Automation:</b><br />
                  A portfolio manager always strives to provide a tailored service to their clients. In order to do so, it is necessary to keep updated with the latest tools and technology that allows for a high level of automation within portfolio management functions.
                  <br/><br/>
                  Custom indexing allows portfolio managers to focus less on maintenance and upkeep, and more on providing clients with a personalized service that tends to their specific needs and wants through a customized index portfolio construction technique.
              </Paper>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Rebalancing:</b><br />
                  Based on a few correlating analyses on the <a href="https://www.linkedin.com/pulse/rebalancing-problem-what-index-front-running-one-gayathiri-sri-rangan/">effects of index front-running on investors</a>, it has been revealed that 11 billion USD is being lost to index front-running every year.
                  <br/><br/>
                  In order to optimize investment processes, portfolio managers are increasingly adopting custom indexing to easily combat the effects of prescribed rebalancing dates from companies within a monopoly in the industry.
              </Paper>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Reporting and integration:</b><br />
                  When data is spread across multiple projects, portfolio managers find it rather challenging to get a comprehensive or accurate report of their operations. Reporting and integration is an important part of a portfolio manager’s job, and thus it is imperative to turn to portfolio management softwares that allows data and information to be fetched in real-time.
                  <br/><br/>
                  Custom indexing platforms play a part in this automation by allowing portfolio managers to view real-time data of index performance, and provide a clear overview of important information such as weightings, constituents and index methodology.
              </Paper>
            </Grid>
            </Grid>
            <br/><br/>
            <Grid Item xs={12} sm={8} sx={{textAlign:'center'}}>
            <Button onClick={e => navigate(`/login`)} variant="outlined" size="large">Custom indexing for portfolio managers</Button>
            </Grid>

              {returnImage(isignup4, '/resources', "Free Download: Index Front-Running. Find out why $11 billion is being lost to index front-running every year, and how you can avoid this by optimizing your investment strategies. Download Now button.")}
              <br />
              <br />
              <Typography variant='h3'>Example of portfolio manager: FACTS® 200</Typography><br />

              Index One was able to support Trust Across America-Trust Around the World (TAA-TAW) with the backtesting and a fully automated calculation of the TRUST index that measures high returns of trustworthy public companies, and this index performance is now being utilized in whitepapers and other market materials that cater to ETF/UCITS sponsors and investors.

              <br />
              <br />
              <b>Being the lowest cost index provider in the industry, Index One allows TAA-TAW to run backtests of FACTS® and create the TRUST index in a highly cost and time-efficient manner. </b>
              <br />

            </Typography>
            <br />
            <br />
            {returnVideo('lSkzSmn5W1w')}
            <br />
            <br />
            {returnImage(isignup6, '/resources', "Free Download: FACTS 200 Case Study. Find out how Index One was able to support the backtesting and a fully automated calculation of the TRUST index. Download Now button")}

            <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Building an index fund portfolio:</b><br />
              Building an index fund portfolio involves selecting a combination of index funds that align with your investment goals and risk tolerance.
                  <br/><br/>
                  Remember that building an index fund portfolio requires ongoing monitoring and periodic adjustments. <br/>Index One provides a flexible tool to build, calculate, maintain and integrate portfolios. <a href="https://indexone.io/indexbuilder">Find out how.</a>
              </Paper>
            <br/>
              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Personalisation in portfolio construction:</b><br />
              Personalization in portfolio construction refers to tailoring investment portfolios to align with an individual investor's unique financial goals, risk tolerance, time horizon, and preferences. It involves considering individual circumstances and preferences to create a customized investment strategy. 
              <br/><br/>
                  Index One facilitates portfolio managers with the ability to create and customize portfolios for clients looking for personalization in investment strategies. <a href="https://indexone.io/indexbuilder">Find out how.</a>
              </Paper>

          </Container>
        </Container>






        <div id='equity_firms' style={{ clipPath: 'polygon(0 0, 100% 20%, 100% 80%, 0% 100%)', padding: '7em 0%', background: '#3d5a80', color: '#fafafa', overflow: 'hidden', paddingTop: '200px', paddingBottom: '200px' }}>
          <Container disableGutters maxWidth='lg'>
            <Typography variant='h2' style={{ textAlign: 'center', margin: '1rem' }}>Custom Indexing for Equity Research Firms</Typography>
          </Container>
        </div>

        <Container maxWidth='false'>
          <Container>
            <Stack direction='row' spacing={3}>
              <Stack direction='column'>
                <br />
                <Typography>
                  <Typography variant='h3'>What does an equity research analyst do?</Typography><br />
                  An equity research analyst is an individual that works within a group or company that specializes in producing reports that highlight recommendations and projections of companies and stocks, and sell investment advice based on this information. Equity research reports often include research from market data and news reports.

                  <br />
                  <br />

                  <Typography variant='h3'>Common issues faced by equity research analysts</Typography><br />
                  The job of an equity analyst is significant and demanding. Here are some common challenges faced by equity research analysts.
                </Typography>
                <br/>
              </Stack>
              <Grid Item sx={{textAlign:'center'}}>{returnInfographic(infographic_5, '/register')}
              <br/>
              <Button onClick={e => navigate(`/contact`)} variant="outlined" size="large">Learn more</Button></Grid>
              <Grid item sm={4}></Grid>
            </Stack>
            <br/>
            <Typography>

            <Grid container spacing={3} direction='column'>
            <Grid item xs={12} sm={6}>
              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Unique insights:</b><br/>
                  Often equity research analysts are expected to cover many hundred stocks and consistently generate unique insights to stay ahead of their competition. This is a common issue within equity research analysts because most of them use outdated technology to keep up with manual processes that can be easily automated.
                  <br/><br/>
                  <a href="https://www.linkedin.com/pulse/third-party-index-provider-stock-analysis-company-one-sri-rangan/">Custom indexing allows equity research analysts to backtest, run and tweak their stock-picking strategy in minutes</a>, allowing them to emphasize on providing unique insights and personalized investment advice to clients.
              </Paper>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Conflicts of interest:</b><br/>
                  Equity research analysts prefer to calculate their performance through a third-party calculation agent in order to eliminate any perceived conflict of interest by their prospects and clients, which could deter them from demonstrating a sense of genuineness and integrity within their work.
                <br/><br/>
                  Many custom indexing platforms act as third-party calculation agents to equity research analysts in order to promote a high degree of transparency and accuracy, and help establish trust amongst clients.
              </Paper>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Failure to beat benchmarks:</b><br />
                  <a href="https://www.linkedin.com/pulse/ca100-benchmark-reveals-dissatisfying-progress-index-one-sri-rangan/">In order to beat a benchmark</a>, it is imperative that equity research analysts use advanced technology that provides a comprehensive overview of a stock-picking or rating strategy against a benchmark.
                <br/><br/>
                  Custom indexing tools provide equity research analysts with up-to-date, real time data that can be used to analyze market data, performance insights and any other metrics necessary to make objective and accurate investment decisions.
              </Paper>
            </Grid>
            </Grid>
            <br/><br/>
            <Grid Item xs={12} sm={8} sx={{textAlign:'center'}}>
            <Button onClick={e => navigate(`/login`)} variant="outlined" size="large">Custom indexing for equity researchers</Button>
            </Grid>
              <br />

              {returnImage(isignup5, '/resources', "Free Download: ValuEngine Case Study. Find out how Index One acts as a third-party indexing platform and fulfills indexing needs for a stock analysis company. Download Now button")}
              <br />
              <Typography variant='h3'>Example of equity research analyst: ValuEnginge</Typography><br />

              ValuEngine, an equity research rating company based in Florida, United States, was lacking a robust and transparent way to convey the value of their equity ratings. Index One, a custom indexing tool helped ValuEngine to build a series of indices weighted by their equity ratings.
              <br />
              <br />
              <b>By doing so, Index One helps ValuEngine display independent calculations of their performance, and license their indices for various products.</b>
              <br />
              <br />
            </Typography>
            <Grid Item>{returnVideo('DnsvF_wNwn8')}</Grid>
            <Grid item sm={4}></Grid>
          </Container>
        </Container>





        <div id='etf_issuer' style={{ clipPath: 'polygon(0 0, 100% 20%, 100% 80%, 0% 100%)', padding: '7em 0%', background: '#3d5a80', color: '#fafafa', overflow: 'hidden', paddingTop: '200px', paddingBottom: '200px' }}>
          <Container disableGutters maxWidth='lg'>
            <Typography variant='h2' style={{ textAlign: 'center', margin: '1rem' }}>Custom Indexing for ETF issuers</Typography>
          </Container>
        </div>

        <Container maxWidth='false'>
          <Container>
            <Stack direction='row' spacing={3}>
              <Stack direction='column'>
                <br />
                <Typography>
                  <Typography variant='h3'>What does an ETF issuer do?</Typography><br />
                  An ETF (Exchange-Traded Fund) issuer is a financial institution or company that creates and manages exchange-traded funds. <br/><br/>The role of an ETF issuer involves several key responsibilities, including: Fund Creation, Regulatory Compliance, Fund Management, Creation and Redemption of ETF Shares, Marketing and Distribution, Reporting and Transparency, Risk Management, and Fee Structure.

                  <br />
                  <br />

                  <Typography variant='h3'>Common issues faced by ETF issuers</Typography><br />
                  Challenges faced by ETF issuers highlight the dynamic and competitive nature of the ETF industry. 
                  <br/><br/>ETF issuers must navigate these issues effectively to succeed in offering attractive investment options to investors and maintaining a strong market position.

                </Typography>
                <br/>
              </Stack>
              <Grid Item sx={{textAlign:'center'}}>{returnInfographic(infographic_1, '/register')}
              <br/>
              <Button onClick={e => navigate(`/contact`)} variant="outlined" size="large">Learn more</Button></Grid>
              <Grid item sm={4}></Grid>
            </Stack>
            <Typography>

            <Grid container spacing={3} direction='column'>
            <Grid item xs={12} sm={6}>
              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Regulatory Compliance:</b><br/>
              ETF issuers must adhere to stringent regulatory requirements, including filing registration statements, maintaining compliance with disclosure obligations, and navigating evolving regulatory frameworks. 

<br/><br/>By using a third-party index calculation agent, ETF issuers can verify the accuracy of the ETF’s underlying index data.
              </Paper>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Index or Strategy Tracking Error:</b><br/>
              Tracking errors can occur due to factors such as transaction costs, sampling methods, or differences in timing. 

<br/><br/>Index providers aim to minimize tracking errors by constructing indices that accurately represent the targeted market segment or strategy. They consider factors such as liquidity, inclusion criteria, weighting methodologies, and rebalancing rules to reduce tracking errors. 

              </Paper>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Product Innovation:</b><br />
              Staying ahead in the industry often requires continuous product innovation. ETF issuers need to identify new investment themes, develop innovative strategies, and launch funds that meet the evolving demands of investors.

<br/><br/>Index providers like Index One offer customized index solutions to meet the specific needs of ETF issuers. They collaborate with issuers to create tailor-made indices that align with unique investment objectives or strategies. 
              </Paper>
            </Grid>
            </Grid>
            <br/><br/>
            <Grid Item xs={12} sm={8} sx={{textAlign:'center'}}>
            <Button onClick={e => navigate(`/login`)} variant="outlined" size="large">Custom indexing for ETF issuers</Button>
            </Grid>
              <br/>

              {returnImage(isignup5, '/resources', "Free Download: ValuEngine Case Study. Find out how Index One acts as a third-party indexing platform and fulfills indexing needs for a stock analysis company. Download Now button")}
              <br />
              
            </Typography>
            <Grid item sm={4}></Grid>
          </Container>
        </Container>





        <div id='esg_provider' style={{ clipPath: 'polygon(0 0, 100% 20%, 100% 80%, 0% 100%)', padding: '7em 0%', background: '#3d5a80', color: '#fafafa', overflow: 'hidden', paddingTop: '200px', paddingBottom: '200px' }}>
          <Container disableGutters maxWidth='lg'>
            <Typography variant='h2' style={{ textAlign: 'center', margin: '1rem' }}>Custom Indexing for ESG Data Providers</Typography>
          </Container>
        </div>

        <Container maxWidth='false'>
          <Container>
            <Stack direction='row' spacing={3}>
              <Stack direction='column'>
                <br />
                <Typography>
                  <Typography variant='h3'>What does an ESG Data Provider do?</Typography><br />
                  An ESG (Environmental, Social, and Governance) data provider is a specialized company or organization that collects, analyzes, and disseminates ESG-related data and information. 
                  <br/><br/>These providers play a crucial role in supporting sustainable and responsible investment decision-making by offering comprehensive ESG data and insights to investors, asset managers, and other stakeholders.

                  <br />
                  <br />

                  <Typography variant='h3'>Common issues faced by ESG Data Providers?</Typography><br />
                  ESG data providers need to address challenges to continually refine their methodologies, collaborate with stakeholders, invest in data collection and analysis capabilities, and adapt to the evolving ESG landscape. 

                </Typography>
                <br/>
              </Stack>
              <Grid Item sx={{textAlign:'center'}}>{returnInfographic(infographic_1, '/register')}
              <br/>
              <Button onClick={e => navigate(`/contact`)} variant="outlined" size="large">Learn more</Button></Grid>
              <Grid item sm={4}></Grid>
            </Stack>

            <br/>
            <Typography>

            <Grid container spacing={3} direction='column'>
            <Grid item xs={12} sm={6}>
              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Standardization and Comparability:</b><br/>
              ESG metrics and reporting frameworks are still evolving, leading to a lack of standardization across companies and industries. ESG data providers face the challenge of harmonizing data and metrics to ensure comparability and consistency.

<br/><br/>Index providers can contribute to standardizing ESG data by developing their own ESG frameworks or aligning with established industry standards. They can work with ESG data providers to define consistent methodologies and metrics for evaluating ESG performance. 

              </Paper>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Data Interpretation and Materiality:</b><br/>
              ESG data providers must interpret the collected data to derive meaningful insights and ratings. 

<br/><br/>By partnering with trusted index providers, ESG data providers can enhance the quality and depth of their ESG analysis. 
Such collaborations can lead to more comprehensive and robust ESG indices that incorporate high-quality ESG data.

              </Paper>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Scalability and Coverage:</b><br />
              As the demand for ESG data and analysis grows, ESG data providers face challenges in scaling their operations and expanding coverage across a wide range of companies and regions. Expanding coverage while maintaining data quality and accuracy can be resource-intensive and require robust data collection and processing infrastructure.

<br/><br/>Index providers can collaborate with ESG data providers to leverage their expertise and data analysis capabilities. By partnering with trusted ESG data providers, index providers can enhance the quality and depth of their ESG analysis. Such collaborations can lead to more comprehensive and robust ESG indices that incorporate high-quality ESG data.
              </Paper>
            </Grid>
            </Grid>
            <br/><br/>
            <Grid Item xs={12} sm={8} sx={{textAlign:'center'}}>
            <Button onClick={e => navigate(`/login`)} variant="outlined" size="large">Custom indexing for ESG data providers</Button>
            </Grid>
              <br/>

              {returnImage(isignup5, '/resources', "Free Download: ValuEngine Case Study. Find out how Index One acts as a third-party indexing platform and fulfills indexing needs for a stock analysis company. Download Now button")}
              <br />
              
            </Typography>
            <Grid item sm={4}></Grid>
          </Container>
        </Container>





        <div id='structured_products' style={{ clipPath: 'polygon(0 0, 100% 20%, 100% 80%, 0% 100%)', padding: '7em 0%', background: '#3d5a80', color: '#fafafa', overflow: 'hidden', paddingTop: '200px', paddingBottom: '200px' }}>
          <Container disableGutters maxWidth='lg'>
            <Typography variant='h2' style={{ textAlign: 'center', margin: '1rem' }}>Custom Indexing for Structured Products Provider</Typography>
          </Container>
        </div>

        <Container maxWidth='false'>
          <Container>
            <Stack direction='row' spacing={3}>
              <Stack direction='column'>
                <br />
                <Typography>
                  <Typography variant='h3'>What does a Structured Products Provider do?</Typography><br />
                  A structured products provider is a financial institution or company that specializes in creating, issuing, and distributing structured products to investors. 
                  <br/><br/>Structured products are complex investment instruments that combine traditional financial instruments, such as stocks, bonds, or derivatives, with customized features or investment strategies. 

                  <br />
                  <br />

                  <Typography variant='h3'>Common issues faced by Structured Products Providers?</Typography><br />
                  Structured products providers may face several common issues in their operations and the offering of structured products.
                </Typography>
                <br/>
              </Stack>
              <Grid Item sx={{textAlign:'center'}}>{returnInfographic(infographic_1, '/register')}
              <br/>
              <Button onClick={e => navigate(`/contact`)} variant="outlined" size="large">Learn more</Button></Grid>
              <Grid item sm={4}></Grid>
            </Stack>
            <br/>
            <Typography>

            <Grid container spacing={3} direction='column'>
            <Grid item xs={12} sm={6}>
              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Investor Confidence and Trust:</b><br/>
              Any negative events, product failures, or breaches of trust within the industry can erode investor confidence.
<br/><br/>Index providers offer transparent and reliable pricing data for the underlying assets included in their indices. Access to accurate and widely accepted pricing information helps structured products providers determine fair values, establish competitive pricing, and offer transparency to investors.
              </Paper>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Complexity and Understanding:</b><br/>
              Structured products are often complex financial instruments that may involve intricate payout structures, underlying assets, and embedded derivatives. Communicating these complexities to investors and ensuring that they fully understand the features and risks of the products can be challenging. 

<br/><br/>Index providers play a role in investor education by providing information and resources related to their indices. Structured products providers can leverage these educational materials to enhance investor understanding of the underlying indices and the structured products linked to them. 

              </Paper>
            </Grid>
            
            <br/><br/>
            <Grid Item xs={12} sm={8} sx={{textAlign:'center'}}>
            <Button onClick={e => navigate(`/login`)} variant="outlined" size="large">Custom indexing for Structured Products Providers</Button>
            </Grid>
              <br/>

              {returnImage(isignup5, '/resources', "Free Download: ValuEngine Case Study. Find out how Index One acts as a third-party indexing platform and fulfills indexing needs for a stock analysis company. Download Now button")}
              <br />
              
            <Grid item sm={4}></Grid>
            </Grid>
            </Typography>
          </Container>
        </Container>






        <div id='hedge_fund' style={{ clipPath: 'polygon(0 0, 100% 20%, 100% 80%, 0% 100%)', padding: '7em 0%', background: '#3d5a80', color: '#fafafa', overflow: 'hidden', paddingTop: '200px', paddingBottom: '200px' }}>
          <Container disableGutters maxWidth='lg'>
            <Typography variant='h2' style={{ textAlign: 'center', margin: '1rem' }}>Custom Indexing for Hedge Funds</Typography>
          </Container>
        </div>

        <Container maxWidth='false'>
          <Container>
            <Stack direction='row' spacing={3}>
              <Stack direction='column'>
                <br />
                <Typography>
                  <Typography variant='h3'>What does a Hedge Fund do?</Typography><br />
                  A hedge fund is an investment vehicle that pools capital from various investors and employs aggressive investment strategies to generate high returns. 
                  <br/><br/>Hedge funds are typically managed by professional fund managers who aim to achieve above-average performance in the financial markets. 

                  <br />
                  <br />

                  <Typography variant='h3'>Common issues faced by Hedge Funds?</Typography><br />
                  Hedge funds should aim to address challenges to have robust risk management practices, skilled investment professionals, effective compliance frameworks, transparent operations, and strong relationships with investors.
                </Typography>
                <br/>
              </Stack>
              <Grid Item sx={{textAlign:'center'}}>{returnInfographic(infographic_1, '/register')}
              <br/>
              <Button onClick={e => navigate(`/contact`)} variant="outlined" size="large">Learn more</Button></Grid>
              <Grid item sm={4}></Grid>
            </Stack>
            <br/>
            <Typography>

            <Grid container spacing={3} direction='column'>
            <Grid item xs={12} sm={6}>
              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Performance Volatility:</b><br/>
              Hedge funds aim to generate high returns, but their performance can be volatile due to the aggressive investment strategies they employ. Market fluctuations, unexpected events, or misjudgment of investment opportunities can result in periods of underperformance or losses.

<br/><br/>Hedge funds can leverage index data and methodologies provided by index providers to measure and report their performance accurately. Index providers offer performance calculation methodologies that are widely accepted and used across the industry. 
              </Paper>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Market Competition:</b><br/>
              The hedge fund industry is highly competitive, with numerous funds vying for investor capital and investment opportunities.

<br/><br/>Index providers can offer customized indices or tailored solutions to meet specific requirements of hedge funds. Hedge funds may have unique investment strategies or niche market exposures that can be addressed through specialized indices created by index providers.

              </Paper>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Risk Management:</b><br />
              Hedge funds typically employ complex investment strategies that involve higher levels of risk compared to traditional investment vehicles.

<br/><br/>Hedge funds can benefit from the research and insights provided by index providers to gain a deeper understanding of market trends, industry dynamics, and investment opportunities. This information can support hedge funds in developing and refining their investment strategies.
        </Paper>
            </Grid>
            
            <br/><br/>
            <Grid Item xs={12} sm={6} sx={{textAlign:'center'}}>
            <Button onClick={e => navigate(`/login`)} variant="outlined" size="large">Custom indexing for Hedge Funds</Button>
            </Grid>
              <br/>

              {returnImage(isignup5, '/resources', "Free Download: ValuEngine Case Study. Find out how Index One acts as a third-party indexing platform and fulfills indexing needs for a stock analysis company. Download Now button")}
              <br />
  
            <Grid item sm={4}></Grid>
            </Grid>
            </Typography>
          </Container>
        </Container>





        <div id='robo advisor' style={{ clipPath: 'polygon(0 0, 100% 20%, 100% 80%, 0% 100%)', padding: '7em 0%', background: '#3d5a80', color: '#fafafa', overflow: 'hidden', paddingTop: '200px', paddingBottom: '200px' }}>
          <Container disableGutters maxWidth='lg'>
            <Typography variant='h2' style={{ textAlign: 'center', margin: '1rem' }}>Custom Indexing for Robo Advisor</Typography>
          </Container>
        </div>

        <Container maxWidth='false'>
          <Container>
            <Stack direction='row' spacing={3}>
              <Stack direction='column'>
                <br />
                <Typography>
                  <Typography variant='h3'>What does a Robo Advisor do?</Typography><br />
                  A robo advisor is an automated online platform or service that provides algorithm-based financial advice and investment management to clients. 
                  <br/><br/>It utilizes technology and mathematical algorithms to offer personalized investment recommendations and portfolio management, typically at a lower cost compared to traditional human financial advisors. 

                  <br />
                  <br />

                  <Typography variant='h3'>Common issues faced by Robo Advisors?</Typography><br />
                  While robo advisors offer several benefits, they may also face some common issues. Here are a few challenges that robo advisors commonly encounter.
                </Typography>
                <br/>
              </Stack>
              <Grid Item sx={{textAlign:'center'}}>{returnInfographic(infographic_1, '/register')}
              <br/>
              <Button onClick={e => navigate(`/contact`)} variant="outlined" size="large">Learn more</Button></Grid>
              <Grid item sm={4}></Grid>
            </Stack>
            <br/>
            <Typography>

            <Grid container spacing={3} direction='column'>
            <Grid item xs={12} sm={6}>
              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Limited Customization:</b><br/>
              Robo advisors typically offer a range of pre-determined investment portfolios based on a client's risk tolerance and financial goals. While these portfolios can be tailored to some extent, they may not accommodate more complex or specific investment preferences. 

<br/><br/>Index providers like Index One offer a wide range of indices that cover various asset classes, sectors, and investment themes. Robo advisors can leverage these indices as building blocks for constructing diversified portfolios that align with different risk profiles and investment goals.
              </Paper>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Lack of Flexibility:</b><br/>
              Robo advisors typically provide standardized investment strategies and asset allocation models. This approach may not accommodate investors with specific preferences or those seeking more dynamic investment strategies.

<br/><br/>Index providers continuously develop and launch new indices that capture emerging trends and investment opportunities. Robo advisors can incorporate these new indices into their investment offerings, allowing clients to access innovative and niche market segments. 

              </Paper>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Limited Financial Planning Scope:</b><br />
              Robo advisors primarily focus on investment management and may not offer comprehensive financial planning services. 
<br/><br/>Index providers often calculate risk metrics, such as volatility and correlation, for their indices. Robo advisors can utilize these risk metrics to assess the risk characteristics of their portfolios and make informed investment decisions. 
        </Paper>
            </Grid>
            
            <br/><br/>
            <Grid Item xs={12} sm={8} sx={{textAlign:'center'}}>
            <Button onClick={e => navigate(`/login`)} variant="outlined" size="large">Custom indexing for Robo Advisors</Button>
            </Grid>
              <br/>

              {returnImage(isignup5, '/resources', "Free Download: ValuEngine Case Study. Find out how Index One acts as a third-party indexing platform and fulfills indexing needs for a stock analysis company. Download Now button")}
              <br />
              
            <Grid item sm={4}></Grid>
            </Grid>
            </Typography>
          </Container>
        </Container>



        <div style={{ clipPath: 'polygon(0 0, 100% 20%, 100% 80%, 0% 100%)', padding: '7em 0%', background: '#3d5a80', color: '#fafafa', overflow: 'hidden', paddingTop: '200px', paddingBottom: '200px' }}>
          <Container disableGutters maxWidth='lg'>
            <Typography variant='h2' style={{ textAlign: 'center', margin: '1rem' }}>Custom Indexing for Financial Media Organization</Typography>
          </Container>
        </div>

        <Container maxWidth='false'>
          <Container>
            <Stack direction='row' spacing={3}>
              <Stack direction='column'>
                <br />
                <Typography>
                  <Typography variant='h3'>What does a Financial Media Organization do?</Typography><br />
                  A financial media organization is a company or entity that provides news, analysis, and information related to the financial industry and financial markets. 
                  <br/><br/>These organizations serve as platforms for disseminating financial news, insights, and commentary to individuals, investors, businesses, and other stakeholders.
                  <br />
                  <br />

                  <Typography variant='h3'>Common issues faced by Financial Media Organizations?</Typography><br />
                  Financial media organizations may face several common issues in their operations.
                </Typography>
                <br/>
              </Stack>
              <Grid Item sx={{textAlign:'center'}}>{returnInfographic(infographic_1, '/register')}
              <br/>
              <Button onClick={e => navigate(`/contact`)} variant="outlined" size="large">Learn more</Button></Grid>
              <Grid item sm={4}></Grid>
            </Stack>
            <br/>
            <Typography>

            <Grid container spacing={3} direction='column'>
            <Grid item xs={12} sm={6}>
              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Limited Customization:</b><br/>
              Robo advisors typically offer a range of pre-determined investment portfolios based on a client's risk tolerance and financial goals. While these portfolios can be tailored to some extent, they may not accommodate more complex or specific investment preferences. 

<br/><br/>Index providers like Index One offer a wide range of indices that cover various asset classes, sectors, and investment themes. Robo advisors can leverage these indices as building blocks for constructing diversified portfolios that align with different risk profiles and investment goals.
              </Paper>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Monetization and Revenue Generation:</b><br/>
              Financial media organizations often face challenges in generating sufficient revenue to sustain their operations.

<br/><br/>Index providers can work with financial media organizations to develop custom indices that align with specific market segments, investment themes, or strategies of interest to the media organization's audience.

              </Paper>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Audience Engagement and Retention:</b><br />
              Engaging and retaining an audience is a critical challenge for financial media organizations. With an abundance of information available, attracting and keeping the attention of readers, viewers, and listeners requires compelling and valuable content.
<br/><br/>Index providers can collaborate with media organizations to supply them with comprehensive and trustworthy financial data, including index values, constituents, and performance metrics. 
        </Paper>
            </Grid>
            
            <br/><br/>
            <Grid Item xs={12} sm={8} sx={{textAlign:'center'}}>
            <Button onClick={e => navigate(`/login`)} variant="outlined" size="large">Custom indexing for Financial Media Organizations</Button>
            </Grid>
              <br/>

              {returnImage(isignup5, '/resources', "Free Download: ValuEngine Case Study. Find out how Index One acts as a third-party indexing platform and fulfills indexing needs for a stock analysis company. Download Now button")}
              <br />
              
            <Grid item sm={4}></Grid>
            </Grid>
            </Typography>
          </Container>
        </Container>








        <div id='index_backtesting' style={{ clipPath: 'polygon(0 0, 100% 0, 100% 80%, 0% 100%)', padding: '7em 0%', background: '#3d5a80', color: '#fafafa', overflow: 'hidden', paddingTop: '200px', paddingBottom: '200px' }}>
          <Container disableGutters maxWidth='lg'>
            <Typography variant='h2' style={{ textAlign: 'center', margin: '1rem' }}>Index Backtesting</Typography>
          </Container>
        </div>
        <Container maxWidth='false'>
          <Container>
            <Grid container direction='row' spacing={3}>
              <Grid item xs={12} sm={4}>
                <Typography>
                  <Typography variant='h3'>What is index backtesting?</Typography><br />Backtesting is the process of observing how an investment strategy would have performed before earning actual returns. Backtesting, or portfolio backtesting uses historical data to assess the viability of a trading strategy.
                </Typography>
              </Grid>
              <Grid Item xs={12} sm={8} sx={{textAlign:'center'}}>{returnVideo('leOu-r090Gc')}
              <br/>
              <Button onClick={e => navigate(`/login`)} variant="outlined" size="large">Create a custom index backtest</Button></Grid>
            </Grid>
            <br/><br/>
            <Typography variant='h3'>How to backtest an index?</Typography><br />
            <Typography>
              Using a custom indexing tool like Index One, a financial analyst or advisor can backtest a portfolio or any given strategy to discover how it performs, by using historical data. Here’s how you can backtest your strategy in minutes.
            </Typography>
            <br/>
            <Typography>



              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Step 1:</b><br/>
              Navigate to “Index Builder”
              </Paper>
            <br/>


          <Grid container spacing={2}>


            <Grid item xs={6}>
            <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Step 2:</b><br/>
              Click on "Add Filter"
              </Paper>
              </Grid>

              <Grid item xs={6}>
              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>If you have a file you would like to upload instead...
            <br/></b><br/>
            Click on "Upload Universe" and select the file type that you would like to upload.
              </Paper>

              </Grid>

          </Grid>
  

            
            <br/>

              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Step 3:</b><br/>
              Select your filters
              </Paper>

            <br/>


              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Step 4:</b><br/>
              Click "Create Backtest"
              </Paper>
 
            <br/>

              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Step 5:</b><br/>
              Launch your new index
              </Paper>
            <br/>

 
              <Grid Item xs={12} sm={8} sx={{textAlign:'center'}}>
              <a href="https://indexone.gitbook.io/docs/product-guides/index-construction/how-to-build-an-index"><Button variant="outlined" size="large">Backtest your strategy</Button></a>
              </Grid>
              <br/>
              <br/>
              {returnImage(isignup3, '/resources', "Free Download: MoveUp Financial Case Study. Find out how Index One supports RIAs such as MoveUp Financial with custom indexing capabilities. Download Now button.")}
            </Typography>
          </Container>
        </Container>



        <div id='custom_index' style={{ clipPath: 'polygon(0 0, 100% 20%, 100% 80%, 0% 100%)', padding: '7em 0%', background: '#3d5a80', color: '#fafafa', overflow: 'hidden', paddingTop: '200px', paddingBottom: '200px' }}>
          <Container disableGutters maxWidth='lg'>
            <Typography variant='h2' style={{ textAlign: 'center', margin: '1rem' }}>Custom index calculation</Typography>
          </Container>
        </div>
        <Container maxWidth='false'>
          <Container>
          <Grid container direction='row' spacing={3}>
              <Grid item xs={12} sm={4}>
                <Typography>
                  <Typography variant='h3'>What is custom index calculation?</Typography><br />Indexing is a broad term used to describe the use of a “statistical measure for tracking economic data such as inflation, unemployment, gross domestic product (GDP) growth, productivity, and market returns.” (via investopedia)
                  <br /><br />Custom indexing allows investment and financial advisors to recommend investment decisions that are easily adjusted based on a client’s investment preferences and biases.
                </Typography>
              </Grid>
              <Grid Item xs={12} sm={8} sx={{textAlign:'center'}}>{returnVideo('gmPtL-rdc_A')}
              <br/>
              <Button onClick={e => navigate(`/login`)} variant="outlined" size="large">Create a custom index</Button></Grid>

              </Grid>
            
            <br/><br/>
            <Typography variant='h3'>How to build a custom index?</Typography><br />
            <Typography>
              Custom indexing allows advisors to build personalized investment solutions to suit the specific needs and requirements of their clients or investment strategies. With a top custom and direct indexing provider like Index One, advisors can easily create realtime indices to match a clients’ investment needs.
            </Typography>
            <br/>
            <Typography>

              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Step 1:</b><br/>
              Click on "Index Builder"
              </Paper>
            <br/>

              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Step 2:</b><br/>
              Click on "Use Custom Universe"
              </Paper>
            <br/>

              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Step 3:</b><br/>
              Click on "Custom Code"
              </Paper>
            <br/>

            <Grid item xs={12} sm={6}>
              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Step 4:</b><br/>
              Test your code
              </Paper>
            </Grid>
            <br/>

              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Step 5:</b><br/>
              Click "Create Backtest"
              </Paper>
            <br/>

     
              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Step 6:</b><br/>
              Launch your new index
              </Paper>
            <br/>

            <Grid Item xs={12} sm={8} sx={{textAlign:'center'}}>
              <a href="https://indexone.gitbook.io/docs/product-guides/index-construction/how-to-use-custom-code-to-build-an-index"><Button variant="outlined" size="large">How to create a custom index</Button></a>
              </Grid>

              <br />
              {returnImage(i3a, '/resources')}
            </Typography>

          </Container>
        </Container>



        <div id='benchmarking' style={{ clipPath: 'polygon(0 0, 100% 20%, 100% 80%, 0% 100%)', padding: '7em 0%', background: '#3d5a80', color: '#fafafa', overflow: 'hidden', paddingTop: '200px', paddingBottom: '200px' }}>
          <Container disableGutters maxWidth='lg'>
            <Typography variant='h2' style={{ textAlign: 'center', margin: '1rem' }}>Index Benchmarking</Typography>
          </Container>
        </div>
        <Container maxWidth='false'>
          <Container>
          <Grid container direction='row' spacing={3}>
              <Grid item xs={12} sm={4}>
                <Typography>
                  <Typography variant='h3'>What is Index Benchmarking?</Typography><br />A benchmark index is a standard against which the performance of an investment strategy is measured. A benchmark index is reconstituted when the index list has changed.
                </Typography>
              </Grid>
              <Grid Item xs={12} sm={8} sx={{textAlign:'center'}}>{returnVideo('9692Is0W8gg')}
              <br/>
              <Button onClick={e => navigate(`/login`)} variant="outlined" size="large">Create a custom benchmark</Button></Grid>
            </Grid>
            <br/><br/>
            <Typography variant='h3'>How to build a benchmark index?</Typography><br />
            <Typography>
              A benchmark index can cover several securities across a wide range of asset classes, and choosing or creating an appropriate benchmark index is imperative to avoid any benchmark error that stems from a wrong index.
              <br /><br />
              Using custom indexing tools like Index One, you can build any benchmark of your choice, in minutes.
            </Typography>
            <br/>
            <Typography>


           
            <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Step 1:</b><br/>
              Navigate to “Index Builder”
              </Paper>
            
            <br/>

              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Step 2:</b><br/>
              Select your filters
              </Paper>

            <br/>


              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Step 3:</b><br/>
              Click "Create Backtest"
              </Paper>
 
            <br/>

              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Step 4:</b><br/>
              View your new benchmark
              </Paper>
            <br/>

              <br />
              {returnImage(i1a, '/resources', "Tracking a generic index is costing you more than you think. Find out how custom indexing can optimize your investment process. Learn more.")}
            </Typography>

          </Container>
        </Container>



        <div id='rebalancing' style={{ clipPath: 'polygon(0 0, 100% 20%, 100% 80%, 0% 100%)', padding: '7em 0%', background: '#3d5a80', color: '#fafafa', overflow: 'hidden', paddingTop: '200px', paddingBottom: '200px' }}>
          <Container disableGutters maxWidth='lg'>
            <Typography variant='h2' style={{ textAlign: 'center', margin: '1rem' }}>Index Rebalancing</Typography>
          </Container>
        </div>
        <Container maxWidth='false'>
          <Container>
          <Grid container direction='row' spacing={3}>
              <Grid item xs={12} sm={4}>
                <Typography>
                  <Typography variant='h3'>What is Index Rebalancing?</Typography><br />Index rebalancing includes realigning the weighting in a portfolio. A rebalancing strategy involves buying and selling assets within a portfolio in order to maintain a desired level of asset allocation, and mitigates any undesirable risks for an investor. This process can include an equally weighted index rebalancing or price weighted.

                  <br /><br />Rebalancing is done periodically by investors and/or advisors, and Index rebalancing dates are usually recommended by index providers.
                </Typography>
              </Grid>
              <Grid Item xs={12} sm={8} sx={{textAlign:'center'}}>{returnVideo('vpqik_fJJSE')}
              <br/>
              <Button onClick={e => navigate(`/login`)} variant="outlined" size="large">Optimize index rebalancing with custom indexing</Button></Grid>
            </Grid>
            <br/><br/>
            <Typography variant='h3'>How to rebalance an index?</Typography><br />
            <Typography>
                  Index fund rebalancing can be easily configured depending on use case and rebalancing frequency. Whatever your requirements are, stock index rebalancing has never been easier. With custom indexing tools like Index One, you can perform any index rebalancing strategy on indices, with no complications.
                  </Typography>
            <Typography>
              {returnImage(i1b, '/resources', "$11 billion is being lost to index front-running every year. Find out how custom indexing can optimize your investment process. Learn more.")}
            </Typography>

            <br/>

            <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Index rebalance strategy:</b><br />
              Index rebalancing strategies refer to the approach used to adjust the composition and weights of securities within an index during the rebalancing process.
                  <br/><br/>
                <a href="https://indexone.io/indexbuilder">Rebalance your strategy here.</a>
              </Paper>

          </Container>
        </Container>


        <div id='equity_research' style={{ clipPath: 'polygon(0 0, 100% 20%, 100% 80%, 0% 100%)', padding: '7em 0%', background: '#3d5a80', color: '#fafafa', overflow: 'hidden', paddingTop: '200px', paddingBottom: '200px' }}>
          <Container disableGutters maxWidth='lg'>
            <Typography variant='h2' style={{ textAlign: 'center', margin: '1rem' }}>Equity Research Ratings</Typography>
          </Container>
        </div>
        <Container maxWidth='false'>
          <Container>
          <Grid container direction='row' spacing={3}>
              <Grid item xs={12} sm={4}>
                <Typography>
                  <Typography variant='h3'>What are Equity Research ratings?</Typography><br />Equity research reports often include research from market data and news reports to produce reports that highlight recommendations and projections of companies and stocks, and ultimately sell investment advice based on this information.
                </Typography>
              </Grid>
              <Grid Item sx={12} sm={8} xs={{textAlign:'center'}}>{returnVideo('0RzVfhQ6HZs')}
              <br/>
              <Button onClick={e => navigate(`/login`)} variant="outlined" size="large">Leverage custom indexing for research</Button></Grid>
            </Grid>
            <br/>
            <Typography>
              <br />
              {returnImage(i3a, '/resources')}
            </Typography>

          </Container>
        </Container>


        <div id='data_display' style={{ clipPath: 'polygon(0 0, 100% 20%, 100% 80%, 0% 100%)', padding: '7em 0%', background: '#3d5a80', color: '#fafafa', overflow: 'hidden', paddingTop: '200px', paddingBottom: '200px' }}>
          <Container disableGutters maxWidth='lg'>
            <Typography variant='h2' style={{ textAlign: 'center', margin: '1rem' }}>Embed index data</Typography>
          </Container>
        </div>
        <Container maxWidth='false'>
          <Container>
          <Grid container direction='row' spacing={3}>
              <Grid item xs={12} sm={4}>
                <Typography>
                  <Typography variant='h3'>What is displaying an Index on your website?</Typography><br />Index data can be displayed on your website to show clients investment strategies, benchmarking indices against other indices, historical and current metrics, realtime data fetched with ease and more.


                </Typography>
              </Grid>
              <Grid Item xs={12} sm={8} sx={{textAlign:'center'}}>{returnVideo('UeJEcEW3nsY')} 
              <br/>
              <Button onClick={e => navigate(`/contact`)} variant="outlined" size="large">Embed index data on your website</Button></Grid>
            </Grid>
            <br/><br/>
            <Typography variant='h3'>How to display an index on your website?</Typography><br />
                <Typography>  
                  With custom indexing tools like Index One, you can easily embed index data on your website and social media platforms, in three easy steps.
                  </Typography>
                  <br/>
            <Typography>

            <Grid item xs={12} sm={6}>
              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Step 1:</b><br/>
              Choose an index
              </Paper>
            </Grid>
            <br/>

            <Grid item xs={12} sm={6}>
              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Step 2:</b><br/>
              Press “Share”
              </Paper>
            </Grid>
            <br/>

            <Grid item xs={12} sm={6}>
              <Paper sx={{padding:'2em',height:'100%'}}>
              <b>Step 3:</b><br/>
              Copy and paste the codes you want to embed on your website
              </Paper>
            </Grid>
            <br/>
              <br />
              {returnImage(i3c, '/resources')}
            </Typography>

          </Container>
        </Container>


        {/* ACCORDIONS */}

        <div style={{ clipPath: 'polygon(0 0, 100% 20%, 100% 80%, 0% 100%)', padding: '7em 0%', background: '#3d5a80', color: '#fafafa', overflow: 'hidden', paddingTop: '200px', paddingBottom: '200px' }}>
          <Container disableGutters maxWidth='lg'>
            <Typography variant='h2' style={{ textAlign: 'center', margin: '1rem' }}>Frequently Asked Questions</Typography>
          </Container>
        </div>

        <Container maxWidth='false'>
          <Container>
          {Object.entries(faqs).map(([k, v]) => {
            return (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id={k}
                >
                  <Typography>{k}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    {v}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            )
          })}
        </Container>
        </Container>



        <div style={{ clipPath: 'polygon(0 0, 100% 20%, 100% 80%, 0% 100%)', padding: '7em 0%', background: '#3d5a80', color: '#fafafa', overflow: 'hidden', paddingTop: '200px', paddingBottom: '200px' }}>
          <Container disableGutters maxWidth='lg'>
            <Typography variant='h2' style={{ textAlign: 'center', margin: '1rem' }}>Commonly Used Terms</Typography>
          </Container>
        </div>

        <Container maxWidth='false'>
          <Container>
          {Object.entries(commonTerms).map(([k, v]) => {
            return (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id={k}
                >
                  <Typography>{k}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    {v}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            )
          })}
                </Container>
        </Container>

      </Stack>
    </Container>

  );
}