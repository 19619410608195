import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useStore, useSelector } from 'react-redux'
import { useNavigate, useParams } from "react-router-dom"
import ComparablesDialog from './ComparablesDialog';

//external components
import { CSVLink, CSVDownload } from "react-csv";

import Container from '@mui/material/Container';
import config from '../../../config'

import timezones from '../../helpers/timezones'
import { backtestAllowed,goLiveAllowed } from '../../helpers/authHelper'

import Chip from '@mui/material/Chip';
import { DataGrid,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { LoadingButton } from '@mui/lab'
import { Typography, Stack, Grid, Tooltip, IconButton, Button, Paper } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';

import { TextField, MenuItem } from '@mui/material';

import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import CodeInterface from '../CodeInterface';
import SecurityDialog from '../SecurityDialog'
import IndexSpecifications from '../indexwizard/IndexSpecifications'
import FilterList from '../indexwizard/FilterList'
import SecuritiesList from '../indexwizard/SecuritiesList'
import Weighting from '../indexwizard/Weighting'
import SimpleChart from '../indexwizard/SimpleChart'
import VolatilityChart from '../indexwizard/VolatilityChart'
import DrawdownChart from '../indexwizard/DrawdownChart'
import ContributionChart from '../indexwizard/ContributionChart'
import TotalTurnoverChart from '../indexwizard/TotalTurnoverChart'
import TurnoverChart from '../indexwizard/TurnoverChart'
import WeightingPie from '../indexwizard/WeightingPie'
import WeightingDataGrid from '../indexwizard/WeightingDataGrid'
import IntroDialog from '../indexwizard/IntroDialog'
import BasketUpload from '../indexwizard/BasketUpload'

//table
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

//accordion
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

//tabs
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

//icons
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

//backdrop
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


//dialog
import { Dialog, DialogTitle, DialogContent } from '@mui/material';

//snackbar
import Snackbar from '@mui/material/Snackbar';

//new charts
import LineChart from '../charts/LineChart'

/*
async function getResult(payload){
  console.log("GETTING RES")
  try {
    const response = await api.post(`backtest`,payload)
    const data = response.data
    //alert("RUNDONE")
    return response
  } catch (error) {
    if(error.response){
      return error.response
    }else{
      return {status:504,message:'Initial request timed out, you can still proceed to backtest'}
    }
  }
}
*/



export default function DataViewer(props) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const teamId = useSelector(state => state.activeTeamId)
  //const teamName = useSelector(state => state.activeTeamName)
  //const teamSubscription = useSelector(state => state.activeTeam.subscription)
  const teamData = useSelector(state => state.activeTeam)

  const [backtestData,setBacktestData] = useState({})
  const [idMapping,setIdMapping] = useState({})
  const [primaryId,setPrimaryId] = useState(null)
  const [secondaryIds,setSecondaryIds] = useState([])
  const [loadingIds,setLoadingIds] = useState([])
  const [comparablesDialogOpen,setComparablesDialogOpen] = useState(false)

  useEffect(() => {(async()=>{
    //runs on first mount
  })()},[])

  useEffect(() => {(async()=>{
    //runs when a backtest is completed
    if(props.completedBacktest?.url ?? false){
      loadData(props.completedBacktest.id,props.completedBacktest.url,true,"backtest",props.completedBacktest.name)
    }
  })()},[props.completedBacktest])

  const loadData = async (thisId,url,setPrimary,dataType,name) => {
    setBacktestData(prevState => ({ ...prevState, [thisId]: {status:"loading"} }));
    //3 types, backtest from s3, security data, and live index
    var data = {}
    if(dataType === "backtest"){
      data =  await returnBacktestData(url)
    } else if (dataType === "security") {
      data = {}
    } else if (dataType === "index"){
      data = {}
    } else {
      data = {}
    }
    data.status = 'completed'
    setIdMapping(p => ({ ...p, [thisId]:name }))
    setBacktestData(prevState => ({ ...prevState, [thisId]: data }));
    if(!primaryId){
      //default first backtest goes to primary
      setPrimaryId(thisId)
    } else if (setPrimary){
      //move over prev primary
      setSecondaryIds(oldArray => [...oldArray, primaryId])
      setPrimaryId(thisId)
    } else {
      //is secondary benchmark
      setSecondaryIds(oldArray => [...oldArray, thisId]);
    }
  }

  const removeData = async (id) => {
    let newState = {...backtestData}
    delete newState[id]
    setBacktestData(newState)
  }

  const setPrimary = async (id) => {
    if(!secondaryIds.includes(primaryId)){
      setSecondaryIds(oldArray => [...oldArray, primaryId])
    }
    setPrimaryId(id)
  }

  const returnBacktestData = async (url) => {
    const result = await fetch(url,{method:"GET"})
    const data =  await result.json()
    return data
  }

  return (
    <Container maxWidth={false} style={{ padding: '1em', margin:"0em",overflow:'hidden' }} disableGutters>
      <ComparablesDialog
        dialogOpen={comparablesDialogOpen}
        setDialogOpen={setComparablesDialogOpen}
        loadData={loadData}
        removeData={removeData}
        selected={Object.keys(backtestData)}
      />
      <Stack direction='row'>
      <Button onClick={e=>setComparablesDialogOpen(true)}>Comps</Button>
      {Object.keys(backtestData).map(id=>(
        <Chip
          icon={backtestData.status === "loading" ? <CircularProgress size='15px' /> : ""}
          variant={id === primaryId ? "filled":"outlined"}
          label={idMapping[id]}
          onClick={e=>setPrimary(id)}
          onDelete={e=>removeData(id)}
        />
      ))}
      </Stack>
      <br/>
      <Box sx={{height:"600px",width:"100%"}}>
        <LineChart data={backtestData}idMapping={idMapping} primaryId={primaryId}/>
      </Box>
      <br/>
      <br/>
      <div style={{width:'80vw'}}>
      {JSON.stringify(Object.keys(backtestData))}
      <br/>
      {JSON.stringify(primaryId)}
      <br/>
      {JSON.stringify(secondaryIds)}
      <br/>
      {JSON.stringify(backtestData)}
      </div>
      results
    </Container>
  );
}