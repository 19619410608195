import React, { useState } from "react";
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Box, Grid, Typography, Fab, Stack, Divider } from '@mui/material'

import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

//icons
import MyLocationIcon from '@mui/icons-material/MyLocation';
import RuleOutlinedIcon from '@mui/icons-material/RuleOutlined';
import CodeOutlinedIcon from '@mui/icons-material/CodeOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';


const boxData = [
  {
    "id":"rules",
    "title":"Rules Engine",
    "icon":RuleOutlinedIcon,
    "text":"Standard rules based index using our rules engine."
  },
  {
    "id":"systematic",
    "title":"Systematic Strategy",
    "icon":CodeOutlinedIcon,
    "text":"Use our python interface to code custom strategies."
  },
  {
    "id":"basket",
    "title":"Basket Calculation",
    "icon": FileUploadOutlinedIcon,
    "text":"User defined constituents and/or weights."
  }
]


export default function IntroDialog(props) {

  const setRulesBased = () => {
    props.setDialogOpen(false)
  }

  const setSystematic = () => {
    props.setDialogContentName('custom_code')
    props.setWeightingObjective(p => ({...p,type:"custom_code"}))
    props.setActiveTab("2")
  }

  const setBasketUpload = () => {
    props.setCustomUniverse(true)
    props.setActiveTab("2")
    props.setWeightingObjective(p => ({...p,type:"basket",value:{}}))
    props.setDialogContentName('basket_upload')
  }

  const handleClick = (id) => {
    if (id == "rules") {
      setRulesBased()
    } else if (id == "systematic") {
      setSystematic()
    } else if (id == 'basket') {
      setBasketUpload()
    }
  }

  return (
      <Grid container spacing={3} alignItems='stretch'>
        {boxData.map((data)=>{
          return(
            <Grid item xs={4} onClick={e=>handleClick(data.id)}>
              <Paper elevation={3} sx={{height:'100%',backgroundColor:'#fafafa',padding:'1em',cursor:'pointer'}}>
                <Stack sx={{height:'100%',textAlign:'center'}} direction='column' spacing={2} alignItems='center' justifyContent='center'>
                  <data.icon fontSize='large'/>
                  <Typography variant='h6'>{data.title}</Typography>
                  <div style={{flexGrow:1}}/>
                  <Typography variant='body2'>{data.text}</Typography>
                </Stack>
              </Paper>
            </Grid>
          )
        })}
        <Button sx={{marginTop:'1em'}} onClick={e=>props.setDialogOpen(false)} fullWidth variant='filled'>Continue using defaults</Button>
      </Grid>
  )
}