import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTeamQuery } from '../../store/indexOneApi'
import { uploadFile } from '../helpers/fileHelper'



import { Container, TextField, Stack, Snackbar, IconButton, Tooltip ,
Dialog, DialogContent, DialogTitle,TableContainer, Paper, Avatar, Table, TableHead, TableBody, TableCell,
TableRow, Button, Typography, InputAdornment,Grid
} from '@mui/material'

import { DataGrid,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';

import { LoadingButton } from '@mui/lab'

import DownloadIcon from '@mui/icons-material/Download';
import CloseIcon from '@mui/icons-material/Close';

import CodeMirror from '@uiw/react-codemirror';
import { sublime } from '@uiw/codemirror-theme-sublime';
import { python } from '@codemirror/lang-python';
import '../helpers/monokai.css'

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const weightingColumns = [
  { field: 'id', headerName: 'id',width:120},
  { field: 'weight', headerName: 'weight', flex:2}
];

const defaultCode = 
`import statistics
import random

def rebalance(event):
    """
      Main entry point for the rebalance function must be named "rebalance"
      The event passed to the function has the following structure: {
        "universe":[figis...],
        "data_helper":(instance of internal Index One data_helper),
        "time": string in ISO format, e.g. "2020-01-01" or None representing present time
      }
    """
    #get variables
    securities = event['universe']
    time = event['time']

    #randomize weightings
    weights = [random.random() for i in securities]
    weights_scaled = [i/sum(weights) for i in weights]

    #return weighting dictionary
    weightings = {v:weights_scaled[i] for i,v in enumerate(securities)}
    return weightings`

    
function CodeInterfaceMemo(props) {
  const teamId = useSelector(state => state.main.teamData.id)
  const {data,isLoading} = useTeamQuery({id:teamId})
  const [code,setCode] = useState(defaultCode)
  const [snackbarOpen,setSnackbarOpen] = useState(false)
  const [snackbarMessage,setSnackbarMessage] = useState('')
  const [files,setFiles] = useState([])
  const [fileName,setFileName] = useState('test')
  const [time,setTime] = useState('2021-01-01 20:00:00')
  const [fileDialogShow,setFileDialogShow] = useState(false)
  const [loading, setLoading] = useState(false)
  const [testResult,setTestResult] = useState(false)

  useEffect(() => {(async()=>{
    try{
      selectFile(data.files[props.weightingObjective.key])
    }catch(e){
      console.log(e)
    }
  })()},[data])

  //useEffect(() => {(async()=>{
  //  const timeOutId = setTimeout(() => setCode(query), 500);
  //  return () => clearTimeout(timeOutId);
  //})()},[code])

  const selectFile = async (e) => {
    if(!e){return}
    const result = await fetch(e.url,{
      method:"GET"
    })
    const response =  await result.text()
    console.log(response)
    setCode(response)
    setFileName(e.file_name.substring(0, e.file_name.length - 3))
    console.log(e.file_name)
    props.setWeightingObjective(p => ({...p,key:e.file_name}))
  }

  const testCode = (e) => {
    handleSubmit(e)
    setLoading(true)
    props.setWeightingObjective(p => ({...p,key:`${fileName}.py`}))
    /*
    var body = {
      events:['rebalancing'],
      time:time,
      index_params:{
        team_id:teamId
      },
      universe:['security1','security2'],
      weighting_parameters: {
        objectives: [
          {
            type: "custom",
            key: `${fileName}.py`
          }
        ],
        constraints: []
      }
    }
    api.post(`index/events/coordinator`,body).then(response => {
      console.log(response.data[0])
      console.log(response.data[0].weighting_unadjusted)
      const weights = response.data[0].weighting_unadjusted
      let wArray = Object.keys(weights).map(key => ({id:key,weight:weights[key]*100}))
      setTestResult(wArray)
      //dispatch({type:'SNACKBAR',payload:{open:true,message:'Upload Successful'}})
      setLoading(false)
    }, error => {
      console.log(error)
      //dispatch({type:'SNACKBAR',payload:{open:true,message:'Upload Failed'}})
      setLoading(false)
      setSnackbarMessage('Test Run Failed')
      setSnackbarOpen(true)
    })
    */
   setLoading(false)
  }

  const testCode2 = (e) => {
    handleSubmit(e)
    setLoading(true)

    setLoading(false)
  }

  const handleSubmit = (e) => {
    setLoading(true)
    e.preventDefault()
    const r = uploadFile(
      code,
      fileName,
      "py",
      "",
      teamId,
      {},
      'team',
      'code_bucket'
    )
    console.log(r)
    /*
    var fileType = 'application/x-python-code'
    var finalName = `${fileName}.py`
    var filePath = `${teamId}/${fileName}.py`
    var ownerId = teamId
    var body = {
      bucket:'code_bucket',
      file_name:filePath,
      file_type:fileType,
      owner_id:ownerId,
      table:'team'
    }
    const options = {
      headers: {
        'Content-Type': fileType
      }
    }
    var textFileAsBlob = new Blob([code], {type: fileType})
    var file = new File([textFileAsBlob], finalName)
      api.post(`file/import`,body).then(response => {
        console.log(response)
        //dispatch({type:'SNACKBAR',payload:{open:true,message:'Upload Successful'}})
        axios.put(response.data.url, file,options).then((response) => {
          console.log(response)
        });
        setLoading(false)
        setSnackbarMessage('Upload Successful')
        setSnackbarOpen(true)
      }, error => {
        console.log(error)
        //dispatch({type:'SNACKBAR',payload:{open:true,message:'Upload Failed'}})
        setLoading(false)
        setSnackbarMessage('Upload Failed')
        setSnackbarOpen(true)
      })
    */
    props.setWeightingObjective(p => ({...p,key:`${fileName}.py`}))
    setLoading(false)
  }

  const returnFileDialog = () => {
    return (
      <Dialog open={fileDialogShow} onClose={e => setFileDialogShow(false)} maxWidth='md' fullWidth>
        <DialogTitle onClose={e => setFileDialogShow(false)}>
          Files
          <IconButton aria-label="close" sx={{position:'absolute', right: 8,top: 12}} size="small" onClick={e => setFileDialogShow(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers={true}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>File Name</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(data?.files ?? {}).map(([key, value]) =>
                <TableRow key={key} scope="row">
                  <TableCell>{value.file_name}</TableCell>
                  <TableCell align="right"><Button onClick={e => { selectFile(value); setFileDialogShow(false) }}>+</Button></TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </DialogContent>
      </Dialog>
    )
  }

  return (
    <Container maxWidth={false} disableGutters>
      <Snackbar open={snackbarOpen} anchorOrigin={{vertical:'top', horizontal:'right'}} autoHideDuration={6000} onClose={e=>setSnackbarOpen(false)} message={snackbarMessage}/>
      {returnFileDialog()}
      <Stack direction='column' spacing={2}>
        <Stack sx={{marginTop:'5px'}} direction='row' justifyContent='space-between' spacing={2}>
          <Tooltip title="Import"><IconButton onClick={e=>setFileDialogShow(true)}><DownloadIcon fontSize='regular'/></IconButton></Tooltip>
            <TextField
              value={fileName}
              label="File Name"
              variant="outlined"
              onChange={e=>setFileName(e.target.value)}
              InputProps={{
                endAdornment: <InputAdornment position="end">.py</InputAdornment>,
              }}/>
              <div style={{flexGrow:1}}/>
              <TextField
                id='time'
                label='Test Time'
                type="datetime-local"
                format="yyyy/MM/dd hh:mm"
                value={time.replace(' ', 'T')}
                onChange={e => setTime(e.target.value.replace('T', ' ').concat(':00'))}
              />
            
            <LoadingButton variant='outlined' loading={loading} onClick={e=>handleSubmit(e)}>Save</LoadingButton>
            <LoadingButton variant='contained' loading={loading} onClick={e=>testCode(e)}>Test</LoadingButton>
        </Stack>
        <Grid container>
          <Grid item xs>
        <CodeMirror
          value={code}
          height="60vh"
          placeholder="Please start typing..."
          indentWithTab={true}
          width='100%'
          theme={sublime}
          extensions={[python()]}
          options={{
            indentUnit:4,
            smartIndent:false,
            tabSize:10
          }}
          onChange={(value, viewUpdate) => {
            setCode(value)
          }}
          basicSetup={{
            lineNumbers:false,
            tabSize:4
          }}
        />
        </Grid>
        {testResult &&
        <Grid item xs={2}>
          <div style={{ height: '60vh', width: '100%', marginBottom:'1em' }}>
          <DataGrid
            rowHeight={25}
            rows={testResult}
            columns={weightingColumns}
            autoPageSize
            disableMultipleSelection={true}
            components={{
              Toolbar: CustomToolbar,
            }}
          />
        </div>
        </Grid>
        }
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <TableContainer component={Paper} sx={{ maxHeight: '60vh' }}>
              <Table aria-label="a dense table" size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{width:30}}></TableCell>
                    <TableCell>Security</TableCell>
                    <TableCell align="right">Weight (%)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {Object.entries(props.weighting).map(([key, value]) =>
                  <TableRow
                    key={key}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell sx={{width:30}}>
                      <Avatar sx={{ width: 24, height: 24 }} alt={key} src={`https://storage.googleapis.com/iex/api/logos/${key}.png`} />
                    </TableCell>
                    <TableCell component="th" scope="row" align='left'>
                      {key}
                    </TableCell>
                    <TableCell align="right">{(value*100).toFixed(2)}</TableCell>
                  </TableRow>
                )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          {props.weightingLogs.length > 0 &&
          <Grid item xs={9}>
            <Alert severity="warning" sx={{whiteSpace:'pre-wrap'}}>
              <AlertTitle>Warning</AlertTitle>
              {props.weightingLogs}
            </Alert>
          </Grid>
          }
        </Grid>
      </Stack>
    </Container>
  )
}

const CodeInterface = React.memo(CodeInterfaceMemo);
export default CodeInterface