import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import SparkLine from './SparkLine'

const columns = [
  {
    field: 'name',
    headerName: 'Name',
    flex: 3,
  },
  {
    field: 'description',
    headerName: 'Description',
    flex: 1.5,
  },
  {
    field: 'nofieldname',
    headerName: '7d Return',
    width:90,
    renderCell: (params) => (
        <SparkLine data={params.row.data}/>
    )
  },
  //{
  //  field: 'value',
  //  headerName: 'Value',
  //  description: 'Latest value',
  //  width:100,
  //  type:'number'
  //},
  {
    field: 'inception_date',
    headerName: 'Inception',
    description: 'Date the index was created',
    sortable: true,
    flex:1,
    valueGetter: (params) =>{
      try{
        return(params.row.start_time.slice(0,10) || '')
      } catch {
        return("")
      }
    }
  },
  {
    field: 'returns_ytd',
    headerName: 'YTD Return',
    description: 'YTD index return (%)',
    sortable: true,
    type:'number',
    flex:1,
    valueGetter: (params) =>{
      try{
        return((params.row.stats.returns["ytd"]*100).toFixed(2) || '')
      } catch {
        return("")
      }
    }
  },
  {
    field: 'returns_1y',
    headerName: '1y Return',
    description: '1 year index return (%)',
    sortable: true,
    type:'number',
    flex:1,
    valueGetter: (params) =>{
      try{
        return((params.row.stats.returns["1y"]*100).toFixed(2) || '')
      } catch {
        return("")
      }
    }
  },
  {
    field: 'returns_annualized_itd',
    headerName: 'ITD Return',
    description: 'ITD annualized index return (%)',
    sortable: true,
    type:'number',
    flex:1,
    valueGetter: (params) =>{
      try{
        return((params.row.stats.returns_annualized["itd"]*100).toFixed(2) || '')
      } catch {
        return("")
      }
    }
  },
  {
    field: 'sharpe_itd',
    headerName: 'Sharpe',
    description: 'ITD sharpe ratio',
    sortable: true,
    type:'number',
    flex:1,
    valueGetter: (params) =>{
      try{
        return(params.row.stats.sharpe["itd"] || '')
      } catch {
        return("")
      }
    }
  },
  {
    field: 'volatility_itd',
    headerName: 'Volatility',
    description: 'ITD annualized volatility',
    sortable: true,
    type:'number',
    flex:1,
    valueGetter: (params) =>{
      try{
        return((params.row.stats.volatility["itd"]*100).toFixed(2) || '')
      } catch {
        return("")
      }
    }
  },
];


export default function DataGridDemo(props) {
  return (
    <Box sx={{ height: '100%', width: '100%' }}>
      <DataGrid
        loading={props.loading}
        rows={Object.values(props.data)}
        getRowId={(row) =>  row.id}
        columns={columns}
        //pageSize={5}
        rowsPerPageOptions={[5]}
        checkboxSelection={false}
        selectableRowsOnClick
        onRowClick={e=>props.toggleIndexOverviewPanel(e.id)}
        autoPageSize
        rowHeight={40}
        headerHeight={40}
        //experimentalFeatures={{ newEditingApi: true }}
        disableColumnMenu
        sx={{
          "& .MuiDataGrid-row:hover": {
            cursor: "pointer"
          },
          '& .MuiDataGrid-cell': {
            padding: 1,
          },
          '& .MuiDataGrid-columnHeader': {
            padding: 0.5,
          }
        }}
      />
    </Box>
  );
}