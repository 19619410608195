import React from 'react';
import { useNavigate, useParams } from "react-router-dom";

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import ButtonBase from '@mui/material/ButtonBase';
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles';


//accordion
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import ContactForm from '../../elements/ContactForm'

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary
}));



export default function Integrations(props) {
  const navigate = useNavigate()
  return (
    <Container component="main" disableGutters maxWidth={false}>
      <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' style={{ backgroundColor: '#343a40', minHeight: '50vh', clipPath: 'polygon(0 0, 100% 0%, 100% 80%, 0% 100%)', marginTop: 0 }}>
        <Typography style={{ color: '#fafafa', margin: '1rem' }} variant="h2">API Integrations</Typography>
        <Typography style={{ color: '#fafafa', margin: '1rem' }} variant="h5">Index One provides financial professionals with easy and flexible API integration capabilities. <br/>Find out how you can integrate index data on websites, apps and third-party trading platforms.</Typography>
      </Box>
      <Container maxWidth='md' style={{ padding: '50px 1rem' }}>
        <Typography variant='h4' style={{ textAlign: 'center', margin: '1rem' }}>Key Features</Typography>

        <br/>
        <Stack direction="row" maxWidth='md' spacing={2}>

          <Box sx={{ width: '33%', height: '50%' }}><Item><Typography variant='h4'>Transparency</Typography><br/><br/>
          <Typography variant='h10'>We aim to bring better performance, transparency, and cost savings to the index industry through use of cutting edge technology, and a dedication to automation.</Typography></Item></Box>
          <Box sx={{ width: '33%' }}><Item><Typography variant='h4'>Low latency</Typography><br/><br/>
          <Typography variant='h10'>Our cloud based indexing infrastructure enables all levels of customization while ensuring complete accuracy and reliability.</Typography></Item></Box>
          <Box sx={{ width: '33%' }}><Item><Typography variant='h4'>Comprehensive data</Typography><br/><br/>
          <Typography variant='h10'>Whether clients have specific requirements, or just an initial idea, our extremely flexible platform allows us to deliver any type of indexing.</Typography></Item></Box>
        </Stack>
      </Container>

      <br />

    <Container maxWidth='md' style={{ padding: '50px 1rem' }}>
<Grid container spacing={10}>
  <Grid item xs={6}>
        <Typography variant='h4'>Different types of API requests</Typography>
        <br/>
        <Typography variant='h10'>These are the various API requests supported by Index One.</Typography>
        <br/><br/>

        <div>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>GET requests</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <li>Index quote (real time index value)</li>
                <li>Index historical values (for different time periods)</li>
                <li> Index weights</li>
                <li>Historical index weights</li>
                <li>Categorized index weights (sector/country/industry/etc weight)</li>
              </Typography>
            </AccordionDetails>
          </Accordion>

          
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >

              <Typography>Streaming / Websocket API</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <li>Realtime index feed</li>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"

            >

              <Typography>Webhook / push requests</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <li> Tracker files</li>
                <li>Index weightings</li>
                <li>Email / SFTP delivery</li>

              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography>Widgets</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <li>Chart</li>
                <li>Weighting</li>
                <li>Stats table</li>

              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header">

              <Typography>POST requests</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <li>Create index content through the API</li>

              </Typography>
            </AccordionDetails>
          </Accordion>

        </div>
      </Grid>

  <Grid item xs={6}> 
        <Typography variant='h4'>Use cases for API integrations</Typography>
        <br/>
        <Typography variant='h10'>Financial professionals can leverage Index One’s seamless integration capabilities for the following use cases.</Typography>
<br/><br/>
<Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header">

              <Typography>Display real-time index data</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
              Integrating index data has never been easier. <br/><br/>With Index One’s simple API systems, advisors can now display real-time index data on a website or apps, allowing clients to easily access vital index data such as index charts, constituents and weightings, return and risks, factsheet and methodology. <br/><br/>Find out how you can leverage Index One to flexibility display your index on your website or app. 
              </Typography>
              <br/><br/>
              <Button sx={{ width: '150px' }} onClick={e => navigate(`/contact`)} variant="outlined">Contact Us</Button>
            </AccordionDetails>
          </Accordion>
          
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header">

              <Typography>Custom index building capabilities</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
              Differentiate yourself from your competitors by providing real value-add to your clients. 
<br/><br/>With Index One, you can allow your clients to create their own custom indices using your website or app. 
<br/><br/>This provides advisors with a competitive advantage amongst competitors, and offers clients with more autonomy and control over their IP, index design and methodology. 
              </Typography>
              <br/><br/>
              <Button sx={{ width: '150px' }} onClick={e => navigate(`/contact`)} variant="outlined">Learn more</Button>
            </AccordionDetails>
          </Accordion>


          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header">

              <Typography>Streamline applications and workflows</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
              Create and access indices directly through an API. 
<br/><br/>With Index One’s seamless API integrations, advisors can now integrate index data with applications and workflows, providing high automation and efficiency within processes and offering clients with a high level of trust and transparency. 
<br/><br/>Find out how you can embed index data on your website or app.
              </Typography>
              <br/><br/>
              <Button sx={{ width: '150px' }} onClick={e => navigate(`/contact`)} variant="outlined">Contact Us</Button>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header">

              <Typography>Third-party brokerage and trading platforms integrations</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
              Easily integrate your index on any brokerage platform. 
<br/><br/>Index One allows financial professionals to track and integrate their index on any third-party trading platforms by using PMS and OMS functionality. This allows clients to view index data and invest in custom strategies, with ease. 
<br/><br/>Find out how you can embed index data on third-party trading and brokerage platforms. 

              </Typography>
              <br/><br/>
              <Button sx={{ width: '150px' }} onClick={e => navigate(`/contact`)} variant="outlined">Contact Us</Button>
            </AccordionDetails>
          </Accordion>

      </Grid>
</Grid>
 </Container>      

      <br/>
 <Container maxWidth='md' style={{ padding: '50px 1rem' }}>
 <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
      <Typography>Read more on our API Documentation <a href="https://indexone.io/docs/api">here</a>.</Typography>
  </Box>
</Container>
    
      <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' style={{ padding: '100px 0px', marginBottom: '-15vh', backgroundColor: '#4ecdc4', clipPath: 'polygon(0 0, 100% 100px, 100% 100%, 0% calc(100% - 100px))' }}>
        <ContactForm />
      </Box>

    </Container>
  );
}