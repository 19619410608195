import React, {useState, useEffect} from 'react';
import { useNavigate, useParams } from "react-router-dom";

import { makeStyles } from '@mui/styles';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'

import { Typography } from '@mui/material';

//images
import backtest from '../../../images/backtest.png'
import custom_indexing from '../../../images/custom_indexing.png'
import delivery from '../../../images/delivery.png'
import integrations from '../../../images/integrations.png'
import market_indices from '../../../images/market_indices.png'
import rebalance from '../../../images/rebalance.png'

//accordion
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


import CodeIcon from '@mui/icons-material/Code';

//dummy chart
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
const dummyData = [
  {
    key: 7,
    value: 1000
  }
];
//realtime calculation
//automated index management
//index design
//index backtesting
//index monitoring
//api connectivity
//direct indexing / your index

const useStyles = makeStyles({
  sideImage:{
    width:'100%',
    textAlign:'center'
  }
})

export default function Solutions(props) {
  document.title = `Index One | Solutions`
  const classes = useStyles()
  const navigate = useNavigate()
  const [chartData,setChartData] = useState(dummyData)
  useEffect(() => {
    const interval = setInterval(() => {
      setChartData(oldArray => [...oldArray, {key:8,value:1000 + Math.random()*500}])
    }, 500)
    return () => clearInterval(interval)
  }, [])
  return (
    <Container component="main" disableGutters maxWidth={false}>
    <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' style={{backgroundColor:'#343a40', minHeight:'40vh', clipPath: 'polygon(0 0, 100% 0%, 100% 80%, 0% 100%)', marginTop:0}}>
        <Typography style={{color:'#fafafa', margin:'1rem'}} variant="h3">Our Solutions</Typography>
        <br/>
        <Typography align='center' style={{color:'#fafafa', margin:'1rem', marginBottom:'2rem'}} variant="h5"><b style={{fontWeight:300}}>index</b>one is your one-stop destination for tailored indexing, precise backtesting, and seamless rebalancing, <br/>all in a single, convenient platform.</Typography>
    </Box>
    <Container maxWidth='md' style={{padding:'50px 1rem'}}>

    {/* REALTIME CALC */}
    <Typography variant='h4' textAlign='center'>Index Backtesting.</Typography>
    <br/>
    <Grid container>
      <Grid item sm={6}>
      Index One allows users to generate backtests on customized baskets and index strategies in a matter of seconds, with the possibility to share backtest results externally and within teams, including the ability to view detailed stats on the backtest.
      <br/><br/>
      <Stack direction= 'row' spacing={2} alignItems='center' justifyContent='left'>
    <Button sx={{ width: '150px' }} onClick={e => navigate(`/indexbuilder`)} variant="outlined">Try it out</Button>
    <Button sx={{ width: '150px' }} onClick={e => navigate(`/contact`)} variant="outlined">Learn more</Button>
    </Stack>
    <br/>

      </Grid>
      
      <Grid item sm={6}>
    
       <ResponsiveContainer width="100%" height="100%">
            <LineChart
              data={chartData}
              margin={{
                top: 1,
                right: 1,
                left: 1,
                bottom: 1,
              }}
            >
              <Line type="monotone" dataKey="value" stroke="#8884d8" isAnimationActive={false}/>
            </LineChart>
          </ResponsiveContainer>
          

        </Grid>
      </Grid>

<br/>


 {/* INDEX DESIGN */}
 <br/>
    <br/>
        <Typography variant='h4' textAlign='center'>Custom Index Calculation.</Typography>
    <br/>
    <Grid container spacing={3}>
    <Grid item sm={6}>
        <img className={classes.sideImage} src={custom_indexing}/>
      </Grid>
      <Grid item sm={6}>
      With Index One, turn any strategy into a live index, with flexibility on the parameters, including Backtest Duration, Reconstitution Frequency and Corporate Action Management.
        <br/><br/>
          <Stack direction= 'row' spacing={2} alignItems='center' justifyContent='left'>
    <Button sx={{ width: '150px' }} onClick={e => navigate(`/indexbuilder`)} variant="outlined">Get started</Button>
    <Button sx={{ width: '150px' }} onClick={e => navigate(`/contact`)} variant="outlined">Learn more</Button>
    </Stack>
    <br/>
      </Grid>
    </Grid>

    <br/>

    {/* INDEX DESIGN */}
    <br/>
    <br/>
        <Typography variant='h4' textAlign='center'>Automated rebalancing and corporate actions.</Typography>
    <br/>
    <Grid container spacing={3}>
      <Grid item sm={6}>
      Index One simplifies index adjustments by offering automation in rebalancing and corporate actions. 
        <br/><br/>
        In addition to platform capabilities, Index One has a dedicated team to handle index adjustments.
        <br/><br/>
          <Stack direction= 'row' spacing={2} alignItems='center' justifyContent='left'>
    <Button sx={{ width: '150px' }} onClick={e => navigate(`/indexbuilder`)} variant="outlined">Try it out</Button>
    <Button sx={{ width: '150px' }} onClick={e => navigate(`/contact`)} variant="outlined">Learn more</Button>
    </Stack>
    <br/>
  
      </Grid>
      <Grid item sm={6}>
        <img className={classes.sideImage} src={rebalance}/>
      </Grid>
    </Grid>
    <br/>

    {/* BACKTESTING */}
    <br/>
    <br/>
    <Typography variant='h4' textAlign='center'>Index data delivery.</Typography>
    <br/>
    <Grid container spacing={3}>
    <Grid item sm={6}>
      <img className={classes.sideImage} src={delivery}/>
      </Grid>
      <Grid item sm={6}>
      Index One’s platform enables uncomplicated index data delivery through API, Webhook, SFTP and email options.
        <br/><br/>

    <br/>
    <Stack direction= 'row' spacing={2} alignItems='center' justifyContent='left'>
    <Button sx={{ width: '300px' }} variant="outlined" textAlign='center' a href= "https://indexone.gitbook.io/docs/product-guides/integrations/how-to-integrate-index-data-on-alphathena">Example: How to integrate index data on Alphathena</Button>
    </Stack>
      </Grid>
    </Grid>
    <br/>

    {/* INDEX MONITORING */}
    <br/>
    <br/>
        <Typography variant='h4' textAlign='center'>Index integrations.</Typography>
    <br/>
    <Grid container spacing={3}>
      <Grid item sm={6}>
      Embed or integrate your index on any platform with Index One’s simplified integration options, including API, SFTP and iframes.
        <br/><br/>
          <Stack direction= 'row' spacing={2} alignItems='center' justifyContent='left'>
    <Button sx={{ width: '150px' }} onClick={e => navigate(`/indexbuilder`)} variant="outlined">Try it out</Button>
    <Button sx={{ width: '150px' }} onClick={e => navigate(`/contact`)} variant="outlined">Learn more</Button>
    </Stack>
    <br/>

      </Grid>
      <Grid item sm={6}>
      <img className={classes.sideImage} src={integrations}/>
      </Grid>
    </Grid>
    <br/>

    {/* API CONNECTIVITY */}
    <br/>
    <br/>
    <Typography variant='h4' textAlign='center'>Market Indices.</Typography>
    <br/>
    <Grid container spacing={3}>
    <Grid item sm={6}>
    <img className={classes.sideImage} src={market_indices}/>
      </Grid>
      <Grid item sm={6}>
      Index One offers a suite of generic indices for use as a benchmark, base universe, display purposes, and other use cases.
        <br/><br/>
          <Stack direction= 'row' spacing={2} alignItems='center' justifyContent='left'>
    <Button sx={{ width: '150px' }} onClick={e => navigate(`/indexbuilder`)} variant="outlined">Get started</Button>
    <Button sx={{ width: '150px' }} onClick={e => navigate(`/contact`)} variant="outlined">Learn more</Button>
    </Stack>
    <br/>
  
      </Grid>
    </Grid>

<br/><br/><br/>  
  <Stack direction= 'row' spacing={2} alignItems='center' justifyContent='center'>
    <Button sx={{ width: '150px' }} onClick={e => navigate(`/contact`)} variant="outlined">Contact Us</Button>
    <Button sx={{ width: '150px' }} color='secondary' onClick={e => navigate(`/register`)} variant="outlined">Register</Button>
    </Stack>
    

    {/*
    <Typography variant='h4' textAlign='center'>BLANK</Typography>
    <br/>
    <Grid container spacing={3}>
      <Grid item sm={6}>
        blank
      </Grid>
      <Grid item sm={6}>
        blank
      </Grid>
    </Grid>
    */}



    </Container>
  </Container>



)}