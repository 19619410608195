import React, { useState, useEffect } from 'react';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack'
import Container from '@mui/material/Container';
import ContactForm from '../../elements/ContactForm';

import CheckIcon from '@mui/icons-material/Check';
import screen from '../../../images/monitor-filled-small.png'

const texts = [
  "Get advice from an expert who understands your business needs.",
  "Learn about how our index calculation system works.",
  "Find out how we are powering other businesses in the industry."
]
export default function Contact(props) {
  document.title = `Index One | Contact`
  return (
      <Container disableGutters sx={{padding:'2em'}} maxWidth={false}>
        <Grid container justifyContent="space-evenly" alignItems="center">
          <Grid item xs={12} sm={5}>
            <Stack alignItems="center" justifyContent="center" spacing={3}>
            <Typography  align="center"  variant='h4'>
              Contact us or book a demo to learn more about how <b style={{fontWeight:300}}>index</b>one can help you.
            </Typography>
            <img src={screen} style={{maxWidth:'50%', margin:'2em'}}/>
            <Stack alignItems="left" spacing={1}>
            {texts.map((t) => (
              <Stack direction="row" spacing={2}>
                <CheckIcon color="primary"/>
                <Typography variant="body1">{t}</Typography>
              </Stack>
            ))}
            </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={5}>
              <ContactForm/>
          </Grid>
        </Grid>
      </Container>
  );
}