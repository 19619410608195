import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { useLazyBacktestPreviewQuery } from '../../../store/indexOneApi';
import { Container,Box,LinearProgress,Button,Grid,Alert,Link,AlertTitle,Stack } from '@mui/material';
import WeightingTable from "./WeightingTable"
import {uploadFile,downloadFile, parseUniverseFile} from "../../helpers/fileHelper"
import FileUploadDialog from "./FileUploadDialog"
import FileDownloadTable from './FileDownloadTable';
import UniverseDataGrid from './UniverseDataGrid';

import FileUploadIcon from '@mui/icons-material/FileUpload';

export default function CustomUniverse(props) {

    const [uploadDialogOpen,setUploadDialogOpen] = useState(false)
    const [downloadDialogOpen,setDownloadDialogOpen] = useState(false)
    const [loading,setLoading] = useState(false)
    const [parsedFileData,setParsedFileData] = useState(undefined)
    //const [missing,setMissing] = useState([])
    const [fetchTrigger, { data = {"missing":[]}, error, isLoading, isFetching } , lastPromiseInfo] = useLazyBacktestPreviewQuery()

    const handleDownload = async (fileRef) => {
      const res = await downloadFile(fileRef.url)
      handleFile(res,fileRef)
    }

    const updateSpec = async (metaData) => {

      const newState = {
        file_name : metaData.file_name,
        reconstitution_mode : metaData.has_security ? "file" : props.indexSpecifications.reconstitution_mode == 'file' ? 'default': props.indexSpecifications.reconstitution_mode,
        rebalancing_mode : metaData.has_weight ? "file" : props.indexSpecifications.rebalancing_mode == 'file' ? 'default': props.indexSpecifications.rebalancing_mode,
        schedule_mode : metaData.has_time ? "file" : props.indexSpecifications.schedule_mode == 'file' ? 'default': props.indexSpecifications.schedule_mode,
        file_config : metaData
      }
      props.setIndexSpecifications((prevState) => ({
        ...prevState,
        ...newState
      }))
    }

    const handleFile = async (fileData,metaData) => {
      const parsed = parseUniverseFile(fileData,metaData)
      fetchTrigger({"return_missing":true,"securities":parsed.securities,"identifier":parsed.identifier},true)
      console.log(parsed.data)
      setParsedFileData(parsed)
      updateSpec(metaData)
    }

  return (
    <Container maxWidth={false} sx={{height:'100%',padding:'2em'}}>
      <Grid container spacing={3} sx={{height:'100%',padding:'0px'}}>
        <FileUploadDialog
            uploadDialogOpen={uploadDialogOpen}
            setUploadDialogOpen={setUploadDialogOpen}
            handleFile={handleFile}
        />
        <Grid item xs={8} sx={{overflow:'hidden',height:'100%'}}>
          <Stack direction='column' spacing={3} sx={{height:'100%'}}>
          <FileDownloadTable
            handleDownload={handleDownload}
            filter={"universe"}
          />
          <Button size='large' fullWidth onClick={e=>setUploadDialogOpen(true)} variant="outlined" startIcon={<FileUploadIcon />}>Import Universe File</Button>
          {JSON.stringify(data)}
          {data.missing.length > 0 &&
            <Alert severity="warning"  action={
              <Button size="small" target="_blank" href="https://indexone.gitbook.io/docs/product-guides/reference-data/symbol-mapping">Mapping</Button>
            }>
              <AlertTitle>Missing Symbols</AlertTitle>
              Some symbols are not recognized by our platform, please check your <Link target="_blank" href="https://indexone.gitbook.io/docs/product-guides/reference-data/symbol-mapping">symbol mapping</Link>
              <br/><br/>
              {data.missing.join(', ')}</Alert>
          }
          {props.indexSpecifications.universe_mode}
          <br/>
          {props.indexSpecifications.weighting_mode}
          <br/>
          {props.indexSpecifications.schedule_mode}
          </Stack>
          </Grid>
          <Grid item xs={4}>
          <UniverseDataGrid parsedFileData={parsedFileData} setUploadDialogOpen={setUploadDialogOpen}/>
          </Grid>
      </Grid>
    </Container>
  );
}