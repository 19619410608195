import React, { useEffect , useState } from 'react';
import { useStore, useDispatch, useSelector } from 'react-redux'
import { useFileDeleteMutation } from '../../../store/indexOneApi';
//button
import { Container, IconButton, Tooltip, Button } from '@mui/material'


import { DataGrid,  GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';

//icons
import DeleteIcon from '@mui/icons-material/Delete';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export default function Files(props) {
  const dispatch = useDispatch()
  const teamData = useSelector((state) => state.main.teamData)
  const [loading, setLoading] = useState(true)
  const [selected,setSelected] = useState([])
  const [files, setFiles] = useState([])

  const [deleteTrigger] = useFileDeleteMutation()

  const multiDelete = () => {
    for (let i = 0; i < selected.length; i++) {
      handleDelete({file_name:selected[i]})
    }
  }
  const handleDelete = (file) => {
    deleteTrigger({id:teamData.id,file_name:file.file_name}).unwrap()
    /*
    api.delete(`file/teams/${state.activeTeamId}/${file.file_name}`).then(response => {
        console.log(response)
        setFiles(Object.values(response.data))
      }, error => {
        console.log(error)
      })
      */   
    }

  useEffect(() => {(async()=>{
    dispatch({type:'SET_PAGE_TITLE',payload:'files'})
    document.title = `Index One | Files`
    if('files' in teamData){
      setFiles(Object.values(teamData.files))
    }
    setLoading(false)
  })()},[teamData])

  const columns = [
    { field: 'id', headerName: 'ID',width:100 },
    { field: 'version', headerName: 'Version', type: 'number',width:100},
    { field: 'created_at', headerName: 'Created At',width:200 },
    { field: 'name', headerName:'Index Name',flex:1 },
    {
      field: 'action',
      headerName: 'Action',
      width:200,
      renderCell: (params) => (
          <div>
            <Tooltip title="Edit"><IconButton onClick={e=>alert('edit')}><EditIcon fontSize='small'/></IconButton></Tooltip>
            <Tooltip title="Delete"><IconButton onClick={e=>handleDelete(params.row)}><DeleteIcon fontSize='small'/></IconButton></Tooltip>
          </div>
      )
    }
  ]

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
        <div style={{flex:1}}/>
        <Button color='secondary' size='small' startIcon={<DeleteIcon />} onClick={e=>multiDelete()} disabled={selected.length == 0}>Delete Selected</Button>
      </GridToolbarContainer>
    );
  }


  return (
      <Container maxWidth={false} disableGutters sx={{padding:'2em', backgroundColor:'#fafafa'}}>
        <div style={{ height: '80vh', width: '100%', marginBottom:'1em' }}>
        <DataGrid
          loading={loading}
          components={{
            Toolbar: CustomToolbar,
          }}
          rows={files}
          columns={columns}
          autoPageSize
          checkboxSelection
          onSelectionModelChange={(e) => {
            console.log(e)
            const selectedIDs = new Set(e);
            setSelected(selectedIDs)
          }}
        />
      </div>
      </Container>
  )
}