import React, {useState, useEffect} from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from 'react-redux'
import { useAdminGetUsersQuery,useTeamPatchMutation,useLazyTeamQuery } from '../../../store/indexOneApi';

import { Container, TextField, Button, Grid, Typography, Snackbar,
Select, MenuItem, Stack, Switch, FormControlLabel
} from '@mui/material'

import { DataGrid } from '@mui/x-data-grid';


/*
async function changeSubscriptionApi(teamId,targetSubscription){
  try {
    const body = {
      "update_subscription":true,
      "target_subscription":targetSubscription,
      "id":teamId
    }
    console.log(response)
    const userData = response.data
    return {
      "status":response.status,
      "data":userData
    }
  } catch (error) {
    console.log(error)
    return {
      "status":error.response.status,
      "data":error.response.data
    }
  }
}
*/

export default function Users(props) {
  let navigate = useNavigate()
  const dispatch = useDispatch()
  const [users,setUsers] = useState([])
  const {data=[],isLoading} = useAdminGetUsersQuery()
  const [trigger,{}] = useTeamPatchMutation()
  const [teamGetTrigger,{}] = useLazyTeamQuery()

  const handleTeam = (id) => {
    const args = {id:id}
    const vals = teamGetTrigger(args).unwrap()
    navigate("/teamindices")
    /*
    getTeam(id).then(teamData => {
      let userData = JSON.parse(localStorage.getItem('userData'))
      console.log(userData)
      userData.primary_team = id
      dispatch({ type: 'STORE_USER', payload: userData })
      dispatch({ type: 'SET_ACTIVE_TEAM_ID', payload: { id: userData.primary_team } })
      dispatch({ type: 'SET_ACTIVE_TEAM_DATA', payload: { data: teamData } })
      //dispatch({ type: 'SET_ACTIVE_TEAM_NAME', payload: { name: teamData.name } })
      localStorage.setItem("activeTeamId", userData.primary_team)
      localStorage.setItem("activeTeam", JSON.stringify(teamData))
      localStorage.setItem("userData", JSON.stringify(userData))
      navigate("/teamindices")
    }).catch(err => {
      alert("error")
      console.log(err)
    })
    //console.log(teamData)
    //dispatch({ type: 'SET_ACTIVE_TEAM_ID', payload: { id: id } })
    //navigate("/teamindices")
    */
  }

  const editSubscription = (id,target) => {
    const body = {
      "update_subscription":true,
      "target_subscription":target,
      "id":id
    }
    const response = trigger(body).unwrap()
    console.log(response)
    /*
    changeSubscriptionApi(id,target).then(teamData => {
      console.log(teamData)
      window.location.reload()
    }).catch(err => {
      alert("error")
      console.log(err)
    })
    */
  }

  const columns = [
    { field: 'id', headerName: 'ID', flex: 1 },
    { field: 'email', headerName: 'Email', flex:1 },
    { field: 'primary_team', headerName: "primary team", flex:1},
    { field: 'created_at', headerName: "created", flex:1},
    { field: 'last_active', headerName: "last_active", flex:1},
    {
      field: 'subscription',
      headerName: 'subscription',
      sortable: true,
      flex: 1,
      valueGetter: (params) =>{
        try{
          return(params.row.team_data.subscription || '')
        } catch {
          return("")
        }
      }
    },
    {
      field: 'indices',
      headerName: 'indices',
      sortable: true,
      flex: 1,
      valueGetter: (params) =>{
        try{
          return(Object.keys(params.row.team_data.indices).length || 0)
        } catch {
          return(0)
        }
      }
    },
    {
      field: 'action',
      headerName: 'Action',
      width:200,
      renderCell: (params) => (
          <Stack direction='row' spacing={0.5}>
            <Button variant='outlined' onClick={e=>handleTeam(params.row.primary_team)}>team</Button>
            {params.row.team_data.subscription === 'free' && <Button variant='outlined' onClick={e=>editSubscription(params.row.primary_team,"trial")}>enable trial</Button>}
            {params.row.team_data.subscription === 'trial' && <Button variant='outlined' color='secondary' onClick={e=>editSubscription(params.row.primary_team,"free")}>end trial</Button>}
          </Stack>
      )
    }
  ]

  useEffect(() => {(async()=>{
    dispatch({type:'SET_PAGE_TITLE',payload:'Admin Users'})
    /*
    api.post(`/admin/users`,{"type":"return_all_users"}).then(response => {
      console.log(response)
      setUsers(response.data)
      setIsLoading(false)
    }, error => {
      console.log(error)
      setIsLoading(false)
    })
    */
  })()},[])

  return (
    <Container style={{padding:'2rem', backgroundColor:'#fafafa'}} maxWidth='false'>
      <div style={{ height: '80vh', width: '100%' }}>
        <DataGrid
          getRowId={(r) => r.id}
          rows={data}
          rowHeight={25}
          columns={columns}
          loading={isLoading}
          onCellClick={e=>console.log(navigator.clipboard.writeText(e.value))}
        />
      </div>
    </Container>
  );
}