import React, {useState, useEffect} from 'react';
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from "react-router-dom";
import { useIndexValuesImportMutation } from '../../../store/indexOneApi';
import FileUpload from '../../elements/FileUpload'
import LoadingButton from '@mui/lab/LoadingButton'
import { makeStyles } from '@mui/styles';

import { Container, TextField, Button, Grid, Typography, Snackbar,
Select, MenuItem, Stack, Switch, FormControlLabel
} from '@mui/material'

import { DataGrid } from '@mui/x-data-grid';



const columns = [
  { field: 'time', headerName: 'Time', flex: 1 },
  { field: 'value', headerName: 'Index Value', flex:1 },
]

export default function ImportValues(props) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const [data, setData] = useState([])

  const [importTrigger,{isLoading}] = useIndexValuesImportMutation()

  const handleSubmit = async () => {
    let body = {values:data,id:params.id}
    try{
      const importResponse = await importTrigger(body)
      console.log(importResponse)
      dispatch({type:'SNACKBAR',payload:{open:true,message:'Values uploaded successfully!'}})
      navigate(`/teamindices`)
    } catch(error){
      console.log(error)
      dispatch({type:'SNACKBAR',payload:{open:true,message:'Upload failed, please contact support.'}})
    }
  }

  useEffect(() => {(async()=>{
    dispatch({type:'SET_PAGE_TITLE',payload:"Import Values"})
    document.title = `Index One | Import Values`
  })()},[])

  return (
    <Container style={{padding:'2rem', backgroundColor:'#fafafa'}} maxWidth='false'>
      <Stack direction='row' spacing={3} justifyContent='space-between'>
        <FileUpload returnData={setData}/>
        <Stack direction='row' spacing={3}>
          <div><Button size='large' variant='outlined' color='secondary'onClick={e=>navigate(`/teamindices`)}>Discard</Button></div>
          <div><LoadingButton size='large' loading={isLoading} variant='outlined' color='primary'onClick={e=>handleSubmit()}>Save</LoadingButton></div>
        </Stack>
      </Stack>
      <br/>
        <DataGrid
          getRowId={(r) => r.time}
          rows={data}
          rowHeight={25}
          columns={columns}
          autoHeight={true}
        />
    </Container>
  );
}