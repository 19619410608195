import React, {useState, useEffect} from 'react';
import { useSearchParams,useParams } from "react-router-dom"
import { useIndexStatsQuery } from '../../store/indexOneApi';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import getParamsJoined from '../helpers/paramHelper'
import { getFormControlUnstyledUtilityClasses } from '@material-ui/core';

export default function WeightingChart(props) {
    const [searchParams, setSearchParams] = useSearchParams();
    const params = getParamsJoined(['indexId','volatility','returns','sharpe'],props,searchParams,useParams())
    const statsResponse = useIndexStatsQuery({...params,...{id:params.indexId}})
    const [data, setData] = useState({})
    const [cols, setCols] = useState([])
    const [rows, setRows] = useState([])
    useEffect(() => {(async()=>{
        console.log(statsResponse)
        if(!statsResponse.data || statsResponse.isLoading || statsResponse.isError ){
            return
        }
        console.log(statsResponse)
        var c = []
        var r = []
        for (var row in statsResponse.data){
            r.push(row)
            for (var key in statsResponse.data[row])
                c.push(key)
        }
        c = [...new Set(c)]
        setData(statsResponse.data)
        setCols(c)
        setRows(r)
    })()},[statsResponse])


    return(
        <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell align="right"></TableCell>
                        {cols.map((col) => (
                            <TableCell align="right">{col}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                {rows.map((row) => (
                    <TableRow key={row}>
                        <TableCell align="right" sx={{fontStyle:'italic'}}>{row}</TableCell>
                        {cols.map((col) => 
                            data[row][col] ?
                                <>
                                    { row == 'sharpe'?
                                        <TableCell align="right">{data[row][col].toFixed(2)}</TableCell>
                                        :
                                        <TableCell align="right">{(data[row][col]*100).toFixed(2).toString()+'%'}</TableCell>
                                    }
                                </>
                                    :
                                <TableCell align="right">{data[row][col]}</TableCell>
                            
                        )}
                    </TableRow>
                ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
  }