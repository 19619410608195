import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import ContactForm from '../../elements/ContactForm'

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'

//accirdion
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

//fab
import Fab from '@mui/material/Fab';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';


//card
import Paper from '@mui/material/Paper' 
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import StarIcon from '@mui/icons-material/StarBorder';


//images
import isignup1 from '../../../images/pillar/signup1.jpg'
import isignup2 from '../../../images/pillar/signup2.jpg'
import isignup3 from '../../../images/pillar/signup3.jpg'
import isignup4 from '../../../images/pillar/signup4.jpg'
import isignup5 from '../../../images/pillar/signup5.jpg'
import isignup6 from '../../../images/pillar/signup6.jpg'

import i1a from '../../../images/pillar/1a.jpg'
import i1b from '../../../images/pillar/1b.jpg'
import i1c from '../../../images/pillar/1c.jpg'
import i2a from '../../../images/pillar/2a.jpg'
import i3a from '../../../images/pillar/3a.jpg'
import i3b from '../../../images/pillar/3b.jpg'
import i3c from '../../../images/pillar/3c.jpg'
import i3d from '../../../images/pillar/3d.jpg'

import infographic_1 from '../../../images/pillar/infographic_1.jpg'
import infographic_2 from '../../../images/pillar/infographic_2.jpg'
import infographic_3 from '../../../images/pillar/infographic_3.jpg'
import infographic_4 from '../../../images/pillar/infographic_4.jpg'
import infographic_5 from '../../../images/pillar/infographic_5.jpg'
import scr1 from '../../../images/pillar/scr1.png'
import scr2 from '../../../images/pillar/scr2.png'
import scr3 from '../../../images/pillar/scr3.png'





//comment


//tab
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Vrpano } from '@mui/icons-material';




const commonTerms = {
  "Custom indexing": "custom indexing is the process of setting specific parameters on the stocks you’d like to invest money in, allowing you to personalize your investments based on your individual values, goals, preferences, risk tolerance and tax positioning.",
  "Direct indexing": "direct indexing is an investing strategy that involves purchasing the individual stocks within an index, maintaining the same weights in the index.",
  "Active management": "active management involves an investment manager making investment decisions by tracking the performance of an investment portfolio.",
  "Passive management": "passive management involves a fund’s portfolio mirroring a market index, by selecting stocks to be included in a portfolio, unlike active management. ",
  "Market index": "A market index is a hypothetical portfolio that contains investment holdings. The value of a market index is based on the prices of the underlying holdings. ",
  "Efficient market hypothesis": "EHM, or efficient market hypothesis is a theory coined by Eugene Farma, which states that active managers can beat the market only for a given period of time, as their success is simply a matter of chance. EHM suggests that long-term passive management delivers better results than asset management. ",
  "Active investing": "active investing involves the ongoing buying and selling of securities by monitoring market index.",
  "Passive investing": "passive investing is a long-term strategy that involves buying securities that mirrors a market index.",
  "Thematic investing": "thematic investing focuses on investing in long-term or macro-level trends. Examples of thematic investment themes include water, robotics & AI, gaming & e-sports, and space exploration.",
  "ESG investing": "ESG investing emphasizes on investments that prioritizes optimal environmental, social and governance outcomes.",
  "Factor investing": "factor investing is a type of portfolio management strategy that targets quantifiable metrics or factors that can explain differences in stock returns. These factors often include value, size, volatility, momentum, and quality. ",
  "ETF": "exchange traded funds, or ETFs refer to a type of investment fund that is traded on a stock exchange. An ETF usually tracks a generic market index and allows an investor to potentially lower risks and exposure, while diversifying their portfolio.",
  "RIA": "A registered investment advisor, or RIA, is an individual or firm that advises clients on investment decisions and manages their investment portfolios.",
  "Backtesting": "backtesting allows an investor to test an investment strategy using historical data to assess how it would have performed before earning actual returns.  ",
  "Rebalancing": "rebalancing involves the process of realigning the weightings of assets within a portfolio, by buying or selling the assets to maintain the original or desired level of asset allocation or risk. ",
  "Systematic portfolio": "a systematic portfolio contains securities that maintains a price higher than the predetermined level by a systematic manager. A systematic portfolio strategy invovvles trading decisions based on market price trends. ",
  "Rules-based strategies": "a rules-based investment strategy follows smart investment rules and aims to deliver active returns in a cost-efficient manner.",
  "Active returns": "an active return is the percentage difference between a benchmark and the actual return.",
  "Index provider": "an index provider is a firm that creates, calculates and maintains market indices based on any given investment strategy. ",
  "Sustainable investing": "sustainable investing is a type of investment strategy that prioritizes environmental, social and corporate governance impacts before investing in a particular company, venture or  fund. It is also called ESG investing or SRI.",
  "Investment strategy": "an investment strategy is a set of principles that guide an investor to make sound investment decisions based on their financial goals, values, risk tolerance and preferences. ",
  "Alpha investment strategies": "alpha strategies are active investment strategies that choose investments that have the potential to beat the market. Alpha is also known as “excess returns” or “abnormal rate of return.”",
  "Benchmarking": "benchmarking is the process of setting a standard against which the performance of an investment strategy can be measured. ",
  "Reconstitution": "reconstitution is the re-evaluation of a market index to ensure that an index reflects up-to-date market cap and is balanced. ",
  "Bonds": "a bond is a type of security where the issuer of a bond owes the holder of the bond a debt, and the issuer is obligated to repay the principal of the bond at the maturity date, as well as interest on the bond. ",
  "Asset allocation": "asset allocation is the process of dividing an investment among different types of assets, such as stocks, bonds and cash. ",
  "Quant": "quant, or quantitative analysis, is the process of using mathematical and statistical methods to make investment decisions.",
  "Index Funds": "index funds are a type of mutual fund or exchange-traded fund (ETF) that aim to replicate the performance of a specific market index.",
  "Mutual Funds": "a mutual fund is a type of investment fund that pools money from several investors to purchase securities. ",
  "Derivatives Structured Products": "derivative structured products are financial instruments that combine derivatives with other underlying assets to create investment products with unique risk and return characteristics.",
  "Hedge Funds": "similar to mutual funds, a hedge fund is a type of pooled investment fund that trades in relatively liquid assets. Hedge funds primarily use portfolio construction, complex trading and risk management techniques in an attempt to improve performance. ",
  "Index Front-running": "traders who watch market prices know when an index fund will update its components, allowing them to front-run the trade by buying or selling the shares to get ahead of the market and gain an edge. This is not considered illegal because it rewards individuals who pay close attention to information that already exists in the market. However, SEC Rule 17(j)-1 prohibits insiders from taking advantage of their knowledge of client trades for personal gain."
}

const faqs = {
  "What is index construction?": "Index construction refers to the process of creating and maintaining a market index, which is a hypothetical portfolio of securities that represents a specific segment of the overall market.",
  "What is the difference between custom indexing vs direct indexing?": "While direct indexing requires you to choose amongst pre-packaged solutions, custom indexing allows for unlimited customization within different factors, allowing investors to build a portfolio that truly reflects their unique investment goals and preferences. ",
  "What is the difference between active investing vs passive investing?": "While active investing strategies focus on individual securities and a more hands-on approach, passive investing strategies tend to focus on purchasing shares of index funds or ETFs in an attempt to mirror or beat the performance of market indexes.",
  "What is the difference between securities vs stocks?": "A security is the ownership or debt with value. A stock is a type of security that gives the holder of the stock ownership or equity of the publicly-traded company.",
  "What is the difference between stocks vs shares?": "A share is a unit of ownership measured by the number of shares you own, whereas a stock is a unit of equity, measured by the percentage of ownership of the company.",
  "What is the difference between portfolio manager vs asset manager?": "A portfolio manager handles investments and other financial products that make up a portfolio. An asset manager may also manage portfolios, but they mainly handle cash and assets, which a portfolio manager does not. ",
  "What is the difference between Index vs Benchmark?": "While a benchmark only serves as a standard to measure index performance against, an index is created for a variety of reasons, and one of its purposes is to act as a benchmark. In other words, a benchmark is usually always an index, but an index doesn’t necessarily have to be a benchmark.",
  "What is the difference between reconstitution vs rebalancing?": "Rebalancing is a more automated process where price and market-cap weighted indices are rebalanced automatically. Reconstitution, on the other hand, requires the manual adding and removal of securities from an index, based on whether or not these securities are meeting index criteria.",
  "What is the difference between an index fund vs ETF": "An index fund is a mutual fund which tracks an index, while an ETF is an exchange traded asset tracking the performance of an index.",
  "Can you invest directly in an index?": "An index is a hypothetical basket of stocks. In order to invest in an index, it would need to be an investable product that tracks an index. A few examples of an investable product are mutual funds and ETFs. ",
  "How does index rebalancing work?": "Index rebalancing refers to the process of adjusting the composition and weights of securities within an index. It is typically done periodically to maintain the index's target representation and desired characteristics. Index rebalancing helps maintain the integrity of the index and ensures that it continues to accurately reflect the targeted market segment. It allows for adjustments to account for changes in market conditions, company fundamentals, and other factors that may affect the composition and weights of the index components. ",
  "How to create a stock index?": "Creating a stock index involves several steps and considerations, including defining the index objective, selecting the index components, determining the weighting methodology, setting the initial index values, establishing the index calculation methodology, regular maintenance and rebalancing, index calculation and dissemination and index governance and oversight. ",
  "How to construct an index for research?": "Constructing an index for research purposes involves a tailored approach to meet specific research objectives. This includes defining research objectives, selecting the relevant securities, determining inclusion and exclusion criteria, determining weighting methodology, setting the index universe, establishing index calculation methodology, data collection and management, performing backtesting and validation, documenting index construction methodology and analyzing and interpreting results. ",
  "How to create your own index?": "Creating your own index requires careful consideration of various factors, including your investment objectives, the availability of data, and the resources needed to maintain and calculate the index. It may be beneficial to seek professional advice or consult with experts in index construction to ensure the integrity and accuracy of your self-created index.",
  "How to make your own index fund?": "Creating your own index fund involves several steps and considerations: define the investment objective, select the index components, determine the weighting methodology, set the initial fund composition, establish a rebalancing strategy, implement the fund's portfolio, track and monitor performance, consider legal and regulatory requirements, consider administration and custody, develop a distribution strategy, and comply with reporting and governance. "
}

document.querySelectorAll('a[href^="#"]').forEach(anchor => {
  anchor.addEventListener('click', function (e) {
    e.preventDefault();

    document.querySelector(this.getAttribute('href')).scrollIntoView({
      behavior: 'smooth'
    });
  });
});



export default function Pillar(props) {
  
  const navigate = useNavigate()
  document.title = `Rebalancing and Corporate Actions | Index One`
  const [selected, setSelected] = useState('fa')

  const returnImage = (img, link, altText="index one") => {
    return (
      <Stack direction='row' alignItems='center' justifyContent='center' sx={{ marginTop: '5em', marginBottom: '5em' }}>
        <a href={link}><img alt={altText} style={{ display: 'block', maxHeight: '500px', maxWidth: '700px', height: 'auto', width: 'auto' }} src={img} /></a>
      </Stack>
    )
  }

  const returnInfographic = (img, link, altText="index one") => {
    return (
      <Stack direction='row' alignItems='center' justifyContent='center'>
        <a href={link}><img alt={altText} style={{ display: 'block', maxHeight: '500px', maxWidth: '600px', height: 'auto', width: 'auto' }} src={img} /></a>
      </Stack>
    )
  }

  const returnVideo = (embedId) => {
    return (
      <Stack direction='row' alignItems='center' justifyContent='center'>
        <iframe
          width="853"
          height="480"
          src={`https://www.youtube.com/embed/${embedId}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      </Stack>
    )
  }



  return (

    <Container component="main" disableGutters maxWidth={false}>
      <a id="top" />
      <Fab sx={{ position: 'fixed', bottom: 20, right: 20 }} color="primary" aria-label="scroll-up" size='small' href='#top'>
        <ArrowUpwardIcon />
      </Fab>

      <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' style={{ backgroundColor: '#343a40', minHeight: '40vh', marginTop: 0, padding: '5em' }}>
        <Typography style={{ color: '#fafafa', margin: '1rem', textAlign: 'center' }} variant="h3">Rebalancing and Corporate Actions</Typography>
        <br />
        <Container style={{ textAlign: 'center' }}>
          <Typography style={{ color: '#fafafa', margin: '1rem', marginBottom: '2rem' }} variant="h5">We explore rebalancing an index and the importance of corporate action treatments.</Typography><br />
        </Container>
      </Box>


      <div style={{ clipPath: 'polygon(0 0, 100% 0, 100% 80%, 0% 100%)', padding: '7em 0%', background: '#3d5a80', color: '#fafafa', overflow: 'hidden', paddingTop: '200px', paddingBottom: '200px' }}>
        <Container disableGutters maxWidth='lg'>
          <Typography variant='h2' style={{ textAlign: 'center', margin: '1rem' }}>What is rebalancing?</Typography>
        </Container>
      </div>
      <Stack direction='column'>
        <Container style={{ textAlign: 'center' }}>
            <Grid item xs={12} sm={6}>
              <Paper sx={{padding:'2em'}}>
              <b>Rebalancing is a strategic process used in financial management to adjust the asset allocation of a portfolio or an index.</b> 

<br/><br/>The goal is to bring the portfolio back to its target or desired asset allocation, ensuring that it aligns with the investor's risk tolerance, investment objectives, and market conditions. This involves buying or selling assets within the portfolio to maintain the intended balance, especially after market fluctuations or changes in the financial landscape.

        </Paper>
          </Grid>
        </Container>



        <Stack direction='column'>
          <div style={{ clipPath: 'polygon(0 0, 100% 20%, 100% 80%, 0% 100%)', padding: '7em 0%', background: '#3d5a80', color: '#fafafa', overflow: 'hidden', paddingTop: '200px', paddingBottom: '200px' }}>
            <Container disableGutters maxWidth='lg'>
              <Typography variant='h2' style={{ textAlign: 'center', margin: '1rem' }}>What are corporate actions?</Typography>
            </Container>
          </div>
        </Stack>

        <Stack direction='column'>
          <Container style={{ textAlign: 'center' }}>
            <Typography>
            <Grid item xs={12} sm={6}>
              <Paper sx={{padding:'2em',height:'100%'}}>
              Corporate actions refer to events initiated by a publicly-traded company that can impact its shareholders and the overall market. <b>These actions include events such as stock splits, mergers, acquisitions, dividends, and rights issues.</b> 

<br/><br/>Corporate actions can significantly influence the valuation and composition of an index or portfolio, requiring careful consideration and adjustment to maintain the desired investment strategy.

              </Paper>
            </Grid>
            </Typography>
          </Container>
        </Stack>
        <br/>
        <Grid Item xs={12} sm={8} sx={{textAlign:'center'}}>
            <Button onClick={e => navigate(`/login`)} variant="outlined" size="large">Automate your corporatee action treatments</Button>
            </Grid>





        <Stack direction='column'>
          <div style={{ clipPath: 'polygon(0 0, 100% 20%, 100% 80%, 0% 100%)', padding: '7em 0%', background: '#3d5a80', color: '#fafafa', overflow: 'hidden', paddingTop: '200px', paddingBottom: '200px' }}>
            <Container disableGutters maxWidth='lg'>
              <Typography variant='h2' style={{ textAlign: 'center', margin: '1rem' }}>Why should I rebalance my index?</Typography>
            </Container>
          </div>
        </Stack>

        <Container maxWidth='false'>
          <Container>
           
              

          <Stack direction='column' spacing={5}>
          <Container style={{ textAlign: 'center' }}>
            <Typography>
            <Grid container spacing={3} sx={{paddingTop:'2em',paddingBottom:'2em'}}>
            <Grid item xs={12} sm={6}>
              <Paper sx={{padding:'2em',height:'100%'}}>
              Rebalancing your index is crucial for maintaining the alignment of your investment portfolio with your financial goals and risk tolerance. Over time, market fluctuations can lead to shifts in the relative weights of different assets within the index, potentially exposing you to unintended risks.
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper sx={{padding:'2em',height:'100%'}}>
              Regular rebalancing allows you to realign the portfolio, selling relatively high-performing assets and buying those that may be undervalued. This disciplined approach helps control risk, prevents overconcentration in specific sectors or stocks, and ensures that your investment strategy remains consistent with your original objectives. 
              
              <br/><br/>By periodically rebalancing, you actively manage your portfolio's asset allocation, <b>promoting a more stable and resilient investment strategy in the face of changing market conditions.</b>
              </Paper>
            </Grid>
          </Grid>
            </Typography>
          </Container>
        </Stack>
            


          </Container>
        </Container>
        

        <div style={{ clipPath: 'polygon(0 0, 100% 20%, 100% 80%, 0% 100%)', padding: '7em 0%', background: '#3d5a80', color: '#fafafa', overflow: 'hidden', paddingTop: '200px', paddingBottom: '200px' }}>
          <Container disableGutters maxWidth='lg'>
          <Stack direction='column' spacing={5}>
              <Container style={{ textAlign: 'center' }}>
                <Typography variant='h2' style={{ textAlign: 'center', margin: '1rem' }}>How to rebalance an index?</Typography>
              </Container>
            </Stack>
          </Container>
        </div>

        <Container maxWidth='false'>
          <Container>
            <Stack direction='column' spacing={5}>
              <Container style={{ textAlign: 'center' }}>
              <Stack direction='column' spacing={5}>
          <Container style={{ textAlign: 'center' }}>
            <Typography>
            <Grid container spacing={3} sx={{paddingTop:'2em',paddingBottom:'2em'}}>
            
              <Paper sx={{padding:'2em',height:'100%'}}>
              Rebalancing an index involves adjusting the weightings of its constituent securities to ensure that it accurately reflects the intended market representation or investment strategy. 
              </Paper>
            <br/><br/>
            </Grid>
            </Typography>
          </Container>
        </Stack>
        
            {returnVideo('h71H-wENVfM')}
          
              </Container>
            </Stack>
            


            {returnImage(isignup1, '/resources', "Free Download: Index One Overview. Find out how Index One is relevant to your business by discovering our DIY custom indexing capabilities. Download Now button.")}
          </Container>
        </Container>




        <div style={{ clipPath: 'polygon(0 0, 100% 20%, 100% 80%, 0% 100%)', padding: '7em 0%', background: '#3d5a80', color: '#fafafa', overflow: 'hidden', paddingTop: '200px', paddingBottom: '200px' }}>
          <Container disableGutters maxWidth='lg'>

            <Stack direction='column' spacing={5}>
              <Container style={{ textAlign: 'center' }}>
                <Typography variant='h2' style={{ textAlign: 'center', margin: '1rem' }}>How to deploy corporate actions on an index?</Typography>
              </Container>
            </Stack>
          </Container>
        </div>

        <Container maxWidth='false'>
          <Container>
        
          <Stack direction='column' spacing={5}>
              <Container style={{ textAlign: 'center' }}>
              <Stack direction='column' spacing={5}>
          <Container style={{ textAlign: 'center' }}>
            <Typography>

            <Grid container spacing={3} sx={{paddingTop:'2em',paddingBottom:'2em'}}>

            <Grid item xs={12} sm={6}>
              <Paper sx={{padding:'2em',height:'100%'}}>
            Deploying corporate actions on an index entails incorporating the effects of events like stock splits, mergers, or dividends into the index calculation. 
            
            <br/><br/>This ensures that the index remains an <b>accurate representation of the market or investment strategy</b>, accounting for any changes in the value or structure of the constituent securities. 
              </Paper>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Paper sx={{padding:'2em',height:'100%'}}>
              Managing corporate actions in an index requires coordination and diligence to maintain the index's integrity and provide investors with an accurate reflection of the market's dynamics.
              </Paper>
            </Grid>
          </Grid>
          <Grid Item xs={12} sm={8} sx={{textAlign:'center'}}>
            <Button onClick={e => navigate(`/contact`)} variant="outlined" size="large">Learn more</Button>
            </Grid>
            </Typography>
          </Container>
        </Stack>
        <br/><br/>
        {returnVideo('vpqik_fJJSE')}
              </Container>
            </Stack>
   


            {returnImage(isignup1, '/resources', "Free Download: Index One Overview. Find out how Index One is relevant to your business by discovering our DIY custom indexing capabilities. Download Now button.")}
            

          </Container>
        </Container>
        

        {/* ACCORDIONS */}

        <div style={{ clipPath: 'polygon(0 0, 100% 20%, 100% 80%, 0% 100%)', padding: '7em 0%', background: '#3d5a80', color: '#fafafa', overflow: 'hidden', paddingTop: '200px', paddingBottom: '200px' }}>
          <Container disableGutters maxWidth='lg'>
            <Typography variant='h2' style={{ textAlign: 'center', margin: '1rem' }}>Frequently Asked Questions</Typography>
          </Container>
        </div>

        <Container maxWidth='false'>
          <Container>
          {Object.entries(faqs).map(([k, v]) => {
            return (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id={k}
                >
                  <Typography>{k}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    {v}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            )
          })}
        </Container>
        </Container>



        <div style={{ clipPath: 'polygon(0 0, 100% 20%, 100% 80%, 0% 100%)', padding: '7em 0%', background: '#3d5a80', color: '#fafafa', overflow: 'hidden', paddingTop: '200px', paddingBottom: '200px' }}>
          <Container disableGutters maxWidth='lg'>
            <Typography variant='h2' style={{ textAlign: 'center', margin: '1rem' }}>Commonly Used Terms</Typography>
          </Container>
        </div>

        <Container maxWidth='false'>
          <Container>
          {Object.entries(commonTerms).map(([k, v]) => {
            return (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id={k}
                >
                  <Typography>{k}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    {v}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            )
          })}
                </Container>
        </Container>

      </Stack>
    </Container>

  );
}