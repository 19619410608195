import React, { useState } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Box, Grid, Typography, Fab, Stack, Divider } from '@mui/material'

import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import attributeConfig from '../indexwizard/attributeConfig.json'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const attributeCategories = {
  'common':['security_type','sector','industry','market_cap_currency_usd','domicile_country','exchange_country']
}

const attributeDefinitions = {
  'issue_type':{
    'name':'Issue Type'
  }
}

export default function AttributeDialog(props) {

  const updateAttribute = (a) => {
    props.updateFilter(props.currentIndex,'attribute',a)
    props.setOpen(false)
  }

  const returnButton = (i) => {
    return(
      <Button color='neutral' variant='outlined' size='small' onClick={e=>updateAttribute(i)}>{attributeConfig.hasOwnProperty(i)? attributeConfig[i].name : i}</Button>
    )
  }

  return (
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        onClose={e=>props.setOpen(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <Stack direction='column' alignItems='center' justifyContent='center' spacing={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>Commonly Used</Grid>
              {attributeCategories.common.map((item, index) => (
                <Grid item>
                  {returnButton(item)}
              </Grid>
              ))}
            </Grid>
            <Divider sx={{width:'100%'}}/>
            <Grid container spacing={2}>
            <Grid item xs={12}>All Attributes</Grid>
            {props.attributes.map((item, index) => (
                <Grid item>{returnButton(item)}</Grid>
            ))}
            </Grid>
          </Stack>
        </DialogContent>
      </Dialog>
  );
}