import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer} from 'recharts';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'

const data = [
  {
    name: 'Page A',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Page B',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'Page C',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: 'Page D',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'Page E',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'Page F',
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: 'Page G',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

function fillValues(data){
  var firstData = {}
  var prevValue = {}
  for (let date in data){
    for (let symbol in data[date]){
      if (data[date][symbol] !== undefined) {
        // if the current symbol has a value, update the previous value variable
        prevValue[symbol] = data[date][symbol];
        if (firstData[symbol] === undefined){
          firstData[symbol] = data[date][symbol]
        }
      } else {
        // if the current symbol does not have a value, fill it with the previous value
        data[date][symbol] = prevValue[symbol];
      }
    }
    for (let symbol in prevValue) {
      if (data[date][symbol] === undefined) {
        // if the current symbol is missing a value, back fill it with the previous value
        data[date][symbol] = prevValue[symbol];
      }
    }
  }
  for (let date in data){
    if (Object.keys(firstData).length == Object.keys(firstData).length){break}
    for (let symbol in firstData){
      if (data[date][symbol] === undefined){
        data[date][symbol] = firstData[symbol]
      }
    }
  }
  return data
}

function convertToPct(values,refValues=[]){
  var newValues = []
  var maxRefIdx = refValues.length-1
  for (let i = 0; i < values.length; i++) {
    if(refValues.length > 0){
      newValues.push({
        time:values[i].time,
        value:values[i].value/values[0].value-1-refValues[Math.min(maxRefIdx,i)].value})
    } else{
      newValues.push({time:values[i].time,value:values[i].value/values[0].value-1})
    }
  }
  return newValues
}

function toPct(f){
  return((f*100).toFixed(2))
}

export default function MultiLine(props){
  const [data,setData] = useState([])
  const [data2,setData2] = useState([])
  const [botTop,setBotTop] = useState(['',''])
  const [showLines,setShowLines] = useState([])
  const [loading,setLoading] = useState(true)
  

  useEffect(() => {(async()=>{
    if (props.refIndex === null){return}
    var dataNew = {}
    var refNorm = []
    var thisShowLines = []
    //convert all values to pct change
    if(props.data[props.refIndex].data){
      refNorm = convertToPct(props.data[props.refIndex].data)
    }

    for (const [key, value] of Object.entries(props.data)) {
      //var valuesNormalized = convertToPct(value.data,refNorm)
      var valuesNormalized = convertToPct(value.data,[])
      if (valuesNormalized.length > 0){thisShowLines.push(key)}
      for (let i = 0; i < valuesNormalized.length; i++) {
        if(dataNew.hasOwnProperty(valuesNormalized[i].time)){
          dataNew[valuesNormalized[i].time][key] = valuesNormalized[i].value
        } else {
          dataNew[valuesNormalized[i].time] = {key:valuesNormalized[i].value}
        }
      }
    }
    dataNew = Object.fromEntries(Object.entries(dataNew).sort())
    dataNew = fillValues(dataNew)
    var dataFinal = []
    for (const [key, value] of Object.entries(dataNew)) {
      dataFinal.push({...value, ...{"time":key}})
    }

    //set top and bot performers
    if(dataFinal.length > 0){
      var bot = ["",""]
      var top = ["",""]
      var obj = Object.entries(dataFinal[dataFinal.length-1])
      for (let i = 0; i < obj.length; i++) {
          var id = obj[i][0]
          var value = obj[i][1]
          if (id === 'time'){continue}
          if (value < bot[1]) bot = [id,value];
          if (value > top[1]) top = [id,value];
      }
      setBotTop([bot[0],top[0]])
    }
    setData(dataFinal)
    setShowLines(thisShowLines)
    setLoading(false)
  })()},[props])

  
  const CustomTooltip = ({ active, payload, label }) => {
    var object = payload.reduce((obj, item) => (obj[item.name] = item, obj) ,{})
    if (active && payload && payload.length) {
      return (
        <Paper sx={{padding:'5px'}}>
          <Stack direction='column'>
            {botTop && <>
              {object[botTop[1]] && <Typography variant='body1Heavy' color={object[botTop[1]].color || 'gray'}>{props.data[botTop[1]].name}: {toPct(object[botTop[1]].value)}%</Typography>}
              {object[botTop[0]] && <Typography variant='body1Heavy' color={object[botTop[0]].color || 'gray'}>{props.data[botTop[0]].name}: {toPct(object[botTop[0]].value)}%</Typography>}
            </>}
          </Stack>
        </Paper>
      );
    }
    return null;
  };

  if(loading){return <Skeleton variant='rectangular' height='200px'/>}
  return (
    <ResponsiveContainer width="100%" height={200}>
      <LineChart
        width={500}
        height={200}
        data={data}
        margin={{
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
        }}
        style={{fontSize:'12px'}}
      >
        <XAxis dataKey="time" hide={true} />
        <CartesianGrid strokeDasharray="3 3" vertical={false}/>

        <YAxis axisLine={false} type="number" tickCount={5} unit=" %" scale='linear' mirror tickFormatter={toPct} domain={['auto', 'auto']} padding={{ top: 10, bottom: 10 }} />
        {/*<Tooltip />*/}
        {/*<Legend />*/}
        {data.length > 0 && showLines.map((k)=>{
          if(k === 'time'){
            return(<></>)
          }else if(k === botTop[0]){
            return(<Line connectNulls key="top" type="monotone" dataKey={k} dot={false} stroke='#fc4798' strokeWidth={2}/>)
          }else if(k === botTop[1]){
            return(<Line connectNulls key="bot" type="monotone" dataKey={k} dot={false} stroke='#4ecdc4' strokeWidth={2}/>)
          }else{
            return(<Line connectNulls type="monotone" dataKey={k} dot={false} stroke='lightgray'/>)
          }
        })}
        <Tooltip cursor={{ strokeDasharray: '3 3' }} content={<CustomTooltip />} />
      </LineChart>
    </ResponsiveContainer>
  );
}
