import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { iconButtonClasses, Stack, Step, Grid } from '@mui/material';
import { Container } from '@mui/system';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { pink } from '@mui/material/colors';
import SpaOutlinedIcon from '@mui/icons-material/SpaOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import DoNotDisturbOnOutlinedIcon from '@mui/icons-material/DoNotDisturbOnOutlined';
import SsidChartOutlinedIcon from '@mui/icons-material/SsidChartOutlined';
import DonutSmallOutlinedIcon from '@mui/icons-material/DonutSmallOutlined';



const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const whyUs = [
  {
    title: 'Zero licensing fees',
    text: 'Index One only charges users for calculation, without any AUM-based licensing fees. Users own the indices they build.',
    icon:DoNotDisturbOnOutlinedIcon
  },
  {
    title: 'Custom indexing and benchmarks',
    text: ' A generic index does not fit every investment objective. We provide a fully flexible and customizable indexing platform, with the ability to define universes, weightings, and more.',
    icon:SsidChartOutlinedIcon
  },
  {
    title: 'Fully flexible rebalancing',
    text: 'Index One offers fully automated rebalancing based on predefined rules at any frequency and schedule, supporting a wide range of methodologies.',
    icon:DonutSmallOutlinedIcon
  },
  {
    title: 'ESG investing',
    text: 'There is a lack of standards in the ESG investing space as most index providers prescribe their own ESG ratings. We offer the ability to import external ratings on demand.',
    icon:SpaOutlinedIcon
  },
  {
    title: 'Self- service',
    text: 'The Index One platform is a very simple and straightforward platform to use- this allows advisors to build indices on their own, with minimal manual help from our team, although we are always ready to help you if any questions arise.',
    icon:PersonOutlineOutlinedIcon
  },
  {
    title: 'Easy accessibility',
    text: 'Index One supports multiple integration and dissemination options. Users retain full control over internal or external index distribution.',
    icon:ThumbUpAltOutlinedIcon
  },
  {
    title: 'Lowest cost in the industry',
    text: 'We offer the most cost-effective calculation solution in the industry, in order to drive higher accessibility and index ownership.',
    icon:PaidOutlinedIcon
  }
]
function WhyIndexOneStepper() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = whyUs.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Container maxWidth= "lg">
    
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        interval={6000}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {whyUs.map((step, index) => {
          if(index > 0){var PrevIcon = whyUs[index-1].icon}
          if(index < whyUs.length -1){var NextIcon = whyUs[index+1].icon}
          return(
          <div key={step.title}>
            {Math.abs(activeStep - index) <= 2 ? (
             <Grid container spacing={6} justifyContent="center">

  { index >0 && 
  <Grid item xs={3} sx={{WebkitMaskImage: "linear-gradient(90deg, rgba(0,0,0,0) 40%, rgba(0,0,0,1) 95%)"}}>
             <Stack direction= "column" spacing={1}>
             <Stack direction="row" spacing={1} sx={{maxHeight:"50px", overflow:"hidden"}}>
               <PrevIcon sx={{ fontSize: 50, color:'#4ECDC4' }}/>
               <Typography variant= "h4">{whyUs[index-1].title.split(' ').slice(0, 3).join(' ')}</Typography>
             </Stack>
             <Typography>{whyUs[index-1].text.slice(0,70)}</Typography>
            </Stack>
            </Grid>
            }

           
<Grid item xs={6}>
             <Stack direction= "column" spacing={1}>
              <Stack direction="row" spacing={1} alignItems="center">
                <step.icon sx={{ fontSize: 50, color:'#4ECDC4' }}/>
                <Typography variant= "h4">{step.title}</Typography>
              </Stack>
              <Typography>{step.text}</Typography>
             </Stack>
             </Grid>


  { index < whyUs.length -1 &&
             <Grid item xs={3} sx={{WebkitMaskImage: "linear-gradient(-90deg, rgba(0,0,0,0) 40%, rgba(0,0,0,1) 95%)"}}>
              <Stack direction= "column" spacing={1}>
              <Stack direction="row" spacing={1} sx={{maxHeight:"50px", overflow:"hidden"}}>
                <NextIcon sx={{ fontSize: 50, color:'#4ECDC4' }}/>
                <Typography variant= "h4">{whyUs[index+1].title.split(' ').slice(0, 2).join(' ')}</Typography>
              </Stack>
              <Typography>{whyUs[index+1].text.slice(0,70)}</Typography>
             </Stack>
             </Grid>
            }

             </Grid>

            ) : null}
          </div>
        )})}
      </AutoPlaySwipeableViews>
      <br/>
      <Container maxWidth="xs">
      <MobileStepper
      sx={{backgroundColor:"transparent"}}
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
      </Container>
    </Container>
  );
}

export default WhyIndexOneStepper;
