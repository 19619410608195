import React, {useState, useEffect, useContext} from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from 'react-redux'
import { makeStyles } from '@mui/styles';
import { useIndexPatchMutation,useIndexQuery,useLazySecurityQuery } from '../../../store/indexOneApi';

import { Container, TextField, Button, Grid, Typography, Snackbar,
Select, MenuItem, Stack, Switch, FormControlLabel, Divider, List, Grow, ListItem, IconButton, ListItemAvatar, Avatar, ListItemText
} from '@mui/material'

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import snakeToTitleCase from '../../helpers/snakeToTitleCase';
import LoadingScreen from '../../elements/LoadingScreen'
import Tooltip from '@mui/material/Tooltip';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import AddIcon from '@mui/icons-material/Add'

import SecuritySearch from '../../elements/indexwizard/SecuritySearch'

const visibilityOptions = [
  {
    value: 'team',
    label: 'Team Only',
  },
  {
    value: 'public',
    label: 'Publicly Visible',
  },
  {
    value: 'featured',
    label: 'Featured Index',
  }
]

const tagOptions = {
  region:["africa","global","us","europe","middle_east","north_america","apac","latin_america","nordics"],
  asset_class:["equity","fixed_income","preferred_shares"],
  issuer:[],
  sector:["energy", "materials", "industrials", "consumer_discretionary", "consumer_staples", "health_care", "financials", "information_technology", "communication_services", "utilities", "real_estate"],
  factor:["size","sentiment","momentum","volatility"],
  esg:[true,false],
  style:["core","alpha","yield"]
}

const additional_fields = [
  {
    "label":"Name",
    "attr":"name",
    "helper_text":"Display name for the index."
  },
  {
    "label":"Ticker",
    "attr":"ticker",
    "helper_text":"Internal ticker symbol for the index.",
  },
  {
    "label":"Description",
    "attr":"description",
    "helper_text":"General description of the index objective.",
    "multiline":true,
    "rows":4
  },
  {
    "label":"Universe Description",
    "attr":"universe_description",
    "helper_text":"Description of the index universe.",
  },
  {
    "label":"Target # Holdings",
    "attr":"target_holdings",
    "helper_text":"Target number of holdings.",
  },
  {
    "label":"Rebalance Frequency",
    "attr":"rebalance_frequency",
    "helper_text":"The rebalancing frequency of the index, e.g. Quarterly",
  },
  {
    "label":"Methodology Description",
    "attr":"methodology_description",
    "helper_text":"Brief description of the index methodology.",
    "multiline":true,
    "rows":4
  },
  {
    "label":"Specifications URL",
    "attr":"specifications_url",
    "helper_text":"URL where the methodology document is hosted.",
  },
  {
    "label":"Disclaimer",
    "attr":"disclaimer",
    "helper_text":"Index disclaimer.",
    "multiline":true,
    "rows":4
  },
]


export default function IndexEdit(props) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const params = useParams()
  const [securitySearchShow, setSecuritySearchShow] = useState(false)
  const [securityData,setSecurityData] = useState({})
  const [alertOpen, setAlert] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState({
    name:'',
    description:'',
    visibility:'',
    calculation_frequency:'',
    issuer_name:'',
    license:'',
    clone_allow:false,
    specification_url:'',
    benchmark:[]
  })
  const [changes, setChanges] = useState({})
  const [tags,setTags] = useState({})
  const [confirmationDialogOpen,setConfirmationDialogOpen] = useState(false)

  const [patchTrigger] = useIndexPatchMutation()
  const [securityTrigger] = useLazySecurityQuery()
  const {data:indexResponse,isLoading:indexResponseIsLoading} = useIndexQuery({"id":params.id,no_cache:true})

  useEffect(() => {(async()=>{
    document.title = `Index One | Index Edit`
    //const ip = await getIndexParams(params.id)
    if(indexResponse){
      var ip = JSON.parse(JSON.stringify(indexResponse))
    }else{
      return
    }
    dispatch({type:'SET_PAGE_TITLE',payload:ip.name})
    console.log(JSON.parse(JSON.stringify(ip)))
    console.log(ip)
    if(ip.benchmark){getSecurityData(ip.benchmark)}
    setData(prevData => ip)
    if(ip.tags){setTags(ip.tags)}
    setIsLoading(false)
  })()},[indexResponse])

  const getSecurityData = (security_ids) => {
    try {
      const promises = security_ids.map(security_id => {
        return securityTrigger({"id":security_id},true)
      });
  
      Promise.all(promises)
        .then(responses => {
          const values = responses.map(response => response.data);
          const objectByKey = values.reduce((acc, obj) => ({ ...acc, [obj.id]: obj }), {});
          console.log(values)
          setSecurityData(objectByKey);
        })
        .catch(err => {
          console.error(err);
          console.log(err.body);
        });
    } catch (err) {
      console.error(err);
    }
  };

  const addBenchmark = (item) => {
    let newBenchmarks = Array.from(data.benchmark)
    newBenchmarks.push(item[2])
    setSecurityData(prevData => ({...prevData,[item[2]]:{"id":item[2],"name":item[0],"symbol":item[1]}}))
    handleChange({target:{name:"benchmark",value:newBenchmarks}})
    setSecuritySearchShow(false)
  }

  const deleteBenchmark = (idx) => {
    let newFilters = Array.from(data.benchmark)
    newFilters.splice(idx, 1)
    handleChange({target:{name:"benchmark",value:newFilters}})
  }

  const handleChange = (e) => {
    try{e.persist()}catch(e){}
    console.log(e)
    setData(prevData => ({...prevData,[e.target.name]:e.target.value}))
    setChanges(prevData => ({...prevData,[e.target.name]:e.target.value}))
  }

  const handleChangeTags = (e) => {
    try{e.persist()}catch(e){}
    console.log(e)
    setTags(prevData => ({...prevData,[e.target.name]:e.target.value}))
  }


  const handleSwitch = (name,value) => {
    setData(prevData => ({...prevData,[name]:value}))
    setChanges(prevData => ({...prevData,[name]:value}))
  }

  const handleSubmit = () => {
    setIsLoading(true)
    var thisChanges = JSON.parse(JSON.stringify(changes))
    thisChanges.id = params.id
    thisChanges.tags = tags
    const response = patchTrigger(thisChanges).unwrap()
    setAlert(true)
    console.log(response)
    setIsLoading(false)
  }

  function confirmationDialog(){return(
    <Dialog
      open={confirmationDialogOpen}
      onClose={e=>setConfirmationDialogOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Stop this index?</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          This action will halt the calculation of the index. It will not be possible to restart the calculation without generating backtested values.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={e=>setConfirmationDialogOpen(false)} color="primary">
          Go Back
        </Button>
        <Button color='secondary' variant='contained' onClick={e=>{setConfirmationDialogOpen(false); stopCalculation()}} autoFocus>
          Proceed
        </Button>
      </DialogActions>
    </Dialog>
  )}

  function searchDialog(){return(
    <Dialog sx={{padding:'1em'}} open={securitySearchShow} onClose={e=>setSecuritySearchShow(false)}>
        <SecuritySearch
          setShow={setSecuritySearchShow}
          show={securitySearchShow}
          selected={[]}
          selectFunction={e => addBenchmark(e)}
        />
      </Dialog>
  )}

  function benchmarkList(){return(
    <>
    <Tooltip title='Compare index performance to a list of benchmarks'><Typography>Benchmarks</Typography></Tooltip>
          <List sx={{ width: '100%', maxHeight: '80vh', overflow: 'auto' }}>
            {data.benchmark.map((item, idx) =>
              <Grow
                in={true}
                timeout={Math.min(idx * 1000, 10000)}
              >
                <ListItem
                  secondaryAction={
                    <IconButton edge="end" aria-label="delete" onClick={e => deleteBenchmark(idx)}>
                      <DeleteOutlineIcon />
                    </IconButton>
                  }
                  alignItems="flex-start"
                >
                  <ListItemAvatar>
                    <Avatar alt={item} src={`https://storage.googleapis.com/iex/api/logos/${securityData?.[item]?.symbol}.png`} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={securityData?.[item]?.symbol ?? item}
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: 'inline' }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          {securityData?.[item]?.name ?? item}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </Grow>
            )}
            <IconButton onClick={e => setSecuritySearchShow(true)} aria-label="select">
              <AddIcon color='inherit' />
            </IconButton>
          </List>
    </>
  )}

  const stopCalculation = () => {
    patchTrigger({id:params.id,stage:"stopped"}).unwrap()
  }

  return (
    <Container style={{padding:'2rem', backgroundColor:'#fafafa'}} maxWidth='false'>
      {isLoading && <LoadingScreen/>}
      {confirmationDialog()}
      {securitySearchShow && searchDialog()}
      <Container maxWidth='md'>
        <Typography variant='h5'>Index Actions</Typography>
        <Stack direction='row' spacing={3}>
          <Button size='large' variant='outlined' color='primary' onClick={e => navigate(`../importvalues`)}>Upload Historical Data</Button>
          <Button size='large' variant='outlined' disabled color='primary' onClick={e => navigate(`../importvalues`)}>New Index Version</Button>
          <Button size='large' variant='outlined' color='secondary' onClick={e => setConfirmationDialogOpen(true)}>Stop Calculation</Button>
        </Stack>
        <Divider variant='middle' sx={{margin:"2em"}}/>
      <form>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Stack direction='column' spacing={1}>
                <Typography variant='h5'>Index Configuration</Typography>
                <TextField size='small' variant="outlined" label="Visibility" name="visibility" value={data.visibility} onChange={handleChange} select>
                  {visibilityOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                  ))}
                </TextField>
                <TextField size="small" variant="outlined" label="Calculation Frequency" name="calculation_frequency" value={data.calculation_frequency} onChange={handleChange}></TextField>
                <TextField size="small" variant="outlined" label="Issuer Name" name="issuer_name" value={data.issuer_name} onChange={handleChange}></TextField>
                <TextField size="small" variant="outlined" label="License" name="license" value={data.license} onChange={handleChange}></TextField>
                <TextField size="small" variant="outlined" label="Factsheet Module" name="factsheet_module" value={data.factsheet_module || "default"} onChange={handleChange}></TextField>
                <FormControlLabel control={<Switch checked={data.clone_allow} onChange={e=>handleSwitch('clone_allow',e.target.checked)}/>} label='Allow Cloning'/>
                <FormControlLabel control={<Switch defaultChecked checked={data.display_constituent_logos} onChange={e=>handleSwitch('display_constituent_logos',e.target.checked)}/>} label='Display Constituent Logos'/>
                <Stack spacing={1} direction='row' justifyContent='space-between'>
                  <FormControlLabel sx={{marginLeft:'0px'}} control={<Switch checked={data.display_limited_weightings} onChange={e=>handleSwitch('display_limited_weightings',e.target.checked)}/>} label='Display Limited Weightings'/>
                  <TextField sx={{width:'50px'}} size='small' disabled={!data.display_limited_weightings} variant="outlined" type='number' label="Top N Constituents" name="display_weightings_count" value={data.display_weightings_count} onChange={handleChange}></TextField>
                </Stack>
                <FormControlLabel control={<Switch checked={data.display_benchmarks} onChange={e=>handleSwitch('display_benchmarks',e.target.checked)}/>} label='Display Benchmarks'/>
                {benchmarkList()}
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack direction='column' spacing={1}>
              <Typography variant='h5'>Index Details</Typography>
              {additional_fields.map(field=>(
              <TextField
                variant="outlined"
                label={field.label}
                name={field.attr}
                value={data[field.attr] || ""}
                multiline={field.multiline}
                rows={field.rows}
                helperText={field.helper_text}
                size="small"
                onChange={handleChange}
              ></TextField>
            ))}
            </Stack>
            </Grid>
          </Grid>
          
          
      <Divider variant='middle' sx={{margin:"2em"}}/>
      <Typography variant='h4'>Index Tags</Typography>
      <Typography>Index tags determine how the index can be searched on the public indices page.</Typography>
      <Stack direction='column' spacing={1} alignItems='stretch' maxWidth='300px'>
      {Object.entries(tagOptions).map(([k,v])=>(
        <Stack direction='row' spacing={1} alignItems='center' justifyContent="space-between">
        <Typography>{snakeToTitleCase(k)}</Typography>
        {
          k === "issuer" ?
          <TextField size='small' name={k} onChange={handleChangeTags} value={tags[k]}></TextField>
          : k === "esg" ?
          <Switch checked={tags[k]} onChange={e=>handleChangeTags({"target":{"name":"esg","value":e.target.checked}})}/>
          :
          <TextField size='small' name={k} select onChange={handleChangeTags} value={tags[k]}>
            {v.map((option)=>
              <MenuItem key={option} value={option}>{snakeToTitleCase(option)}</MenuItem>
            )}
          </TextField>
        }
        </Stack>
      ))}
      </Stack>
      <Grid container spacing={3} justifyContent='center' alignItems='center'>
        <Grid item><Button size='large' variant='contained' color='primary' onClick={e => handleSubmit()}>Save</Button></Grid>
        <Grid item><Button size='large' variant='contained' color='secondary' onClick={e => navigate(`/teamindices`)}>Cancel</Button></Grid>
      </Grid>

      </form>
      <Snackbar open={alertOpen} anchorOrigin={{vertical:'top', horizontal:'right'}} autoHideDuration={6000} onClose={e=>setAlert(false)} message="Success"/>
      </Container>
    </Container>
  );
}