import React, { useState, useContext } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { NotificationContext } from '../../elements/NotificationContext';
import { useResetPasswordMutation, useResetPasswordConfirmMutation } from '../../../store/indexOneApi';

import { LoadingButton } from '@mui/lab'
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import Container from '@mui/material/Container';

export default function ResetPassword(props) {
  const { createNotification } = useContext(NotificationContext)
  const [code, setCode] = useState('')
  const [password, setPassword] = useState('')
  const [username, setUsername] = useState('')
  const [loading,setLoading] = useState(false)
  const [errors, setErrors] = useState({})
  const [stage, setStage] = useState('1')
  const navigate = useNavigate()

  const [trigger,{isLoading:resetIsLoading}] = useResetPasswordMutation()
  const [confirmTrigger,{isLoading:confirmIsLoading}] = useResetPasswordConfirmMutation()


  async function submitRequest() {
    setLoading(true)
    try{
    const resetResponse = await trigger({username:username})
    console.log(resetResponse)
      if(resetResponse.error){
        setLoading(false)
        createNotification({message:"User does not exist",status:"error"})
      }else{
        setLoading(false)
        setStage('2')
      }
    } catch(e){
      setLoading(false)
      createNotification({message:"User does not exist",status:"error"})
      console.log(e)
    }
  }


  async function submitPassword() {
    var e = false
    if(!password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,50}$/)){
      setErrors(p=>({...p,password:"Password must be at least 8 characters long, and must contain uppercase letters, lowercase letters, special characters, and numbers"}))
      e=true
    } else {
      setErrors(p=>({...p,password:undefined}))
    }
    if(code.length == 0){
      setErrors(p=>({...p,code:"Required"}))
      e=true
    } else {
      setErrors(p=>({...p,code:undefined}))
    }

    if(!e){
      setLoading(true)
      let body = {username:username,confirmation_code:code,password:password}
      try{
        await confirmTrigger(body).then(r=>{
          navigate('/login')
          setLoading(false)
        })
      } catch(e){
        console.log(e)
        setLoading(false)
      }
    }
  }


  return (
    <Container maxWidth='xs'>
            <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={1}
        style={{height:'70vh'}}
      >
      {stage === '1' ?
      <>
            <Typography variant='h4'>Reset your password.</Typography>
              <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Email Address"
            name="username"
            onChange={(e) => setUsername(e.target.value)}
            value={username}
          />
          <LoadingButton loading={resetIsLoading} size='large' fullWidth variant="contained" color="primary" onClick={e=>submitRequest()}>Reset Password</LoadingButton>
      </>
    : stage == '2' ?
    <>
    <Typography variant='h4'>Please enter the confirmation code sent to your email address.</Typography>
      <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="code"
            label="Confirmation Code"
            name="code"
            onChange={(e) => setCode(e.target.value)}
            value={code}
          />
                <TextField
            error={errors.password}
            helperText={errors.password}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            type="password"
            id="password"
            label="New Password"
            name="password"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          />
        <LoadingButton loading={confirmIsLoading} size='large' fullWidth variant="contained" color="primary" onClick={e=>submitPassword()}>Confirm Password</LoadingButton>
      </>
      :''
  }
    </Stack>
    </Container>
  )
}