import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux'
import { useRegisterConfirmMutation } from '../../../store/indexOneApi';
import { useNavigate, useLocation } from 'react-router-dom'

import Avatar from '@mui/material/Avatar';
import { LoadingButton } from '@mui/lab'
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import Container from '@mui/material/Container';

export default function RegisterConfirm(props) {
  const [code, setCode] = useState('')
  const [registerConfirmTrigger, { isLoading }] = useRegisterConfirmMutation()
  const navigate = useNavigate()
  const location = useLocation()

  console.log(location)
  
  async function handleSubmit(value) {
    let body = {username:location.state.username,confirmation_code:code}
    try{
      await registerConfirmTrigger(body)
      navigate('/login')
    } catch(e){
      alert(JSON.stringify(e))
      console.log(e)
    }
  }


  return (
    <Grid container justifyContent='center' alignItems='center' style={{minHeight:'90vh'}}>
      <Grid item xs={6} md={3}>
      <Typography variant='h6'>A six digit confirmation code was sent to your email.</Typography>
      <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="code"
            label="Confirmation Code"
            name="code"
            onChange={(e) => setCode(e.target.value)}
            value={code}
          />
        <LoadingButton loading={isLoading} size='large' fullWidth variant="contained" color="primary" onClick={e=>handleSubmit()}>Confirm Code</LoadingButton>
      </Grid>
    </Grid>
  )
}