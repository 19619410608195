import React from 'react'
import Chip from '@mui/material/Chip'
import clsx from 'clsx'

import CircularProgress from '@mui/material/CircularProgress'
import CheckIcon from '@mui/icons-material/Check'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

export default function stageChip(stage){
    if(typeof stage === 'string'){
        if (stage.includes('*')){stage = 'EOD'}
        return(
            <Chip
                size='small'
                label={stage}
                color={
                    stage === 'live' ? 'primary'
                    : stage === 'failed' ? 'secondary'
                    : stage === 'deleting' ? 'secondary'
                    : 'default'
                }
                style={{backgroundColor:clsx(
                    stage ==='pending' ? '#fff3cd'
                    : stage === 'live' ? '#d4edda'
                    : stage ==='processing' ? '#cce5ff'
                    : stage ==='failed' ? '#f8d7da'
                    : stage ==='deleting' ? '#f8d7da'
                    : stage ==='completed' ? '#4ecdc4'
                    :'#d4edda'
                )}}
                icon={
                    stage ==='processing' ? <CircularProgress size='1em' thickness={5} color="secondary"/>
                    : stage === 'live' ? <FiberManualRecordIcon style={{ color: '#4ecdc4' }} />
                    : stage === 'realtime' ? <FiberManualRecordIcon style={{ color: '#4ecdc4' }} />
                    : stage ==='deleting' ? <CircularProgress size='1em' thickness={5} color="secondary"/>
                    : stage === 'completed' ? <CheckIcon/>
                    : ''
                }
            
            
            />
        )
    } else {
        return(<></>)
    }
}