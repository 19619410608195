import React, {useState, useEffect, useContext} from 'react';
import { useStore, useDispatch, useSelector } from 'react-redux'
import { useIndexQuery,useTeamPatchMutation } from '../../../store/indexOneApi';
import { useNavigate, useParams } from "react-router-dom";
import { NotificationContext } from '../../elements/NotificationContext';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import StageChip from '../../elements/StageChip'
import IconButton from '@mui/material/IconButton';

import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';

//dialog
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

//icons
import EqualizerIcon from '@mui/icons-material/Equalizer';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import GetAppIcon from '@mui/icons-material/GetApp';
import DescriptionIcon from '@mui/icons-material/Description';
import ShareIcon from '@mui/icons-material/Share';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloseIcon from '@mui/icons-material/Close';
import EventIcon from '@mui/icons-material/Event';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import BookmarkRemoveIcon from '@mui/icons-material/BookmarkRemove';
import CircularProgress from '@mui/material/CircularProgress';

import ShareDialog from '../../elements/ShareDialog'

import IndexValuesExport from '../../elements/IndexValuesExport';
import IndexCompareInterface from '../../elements/IndexCompareInterface'
import PriceWidget from '../../elements/PriceWidget'
import WeightingChart from '../../elements/WeightingChart'
import WeightingLogos from '../../elements/WeightingLogos'
import StatsTable from '../../elements/StatsTable'

import IndexChart from '../../elements/IndexChart'
import FactsheetExport from '../../elements/FactsheetExport';

const IndexOverview = React.memo(props => {
  const { createNotification } = useContext(NotificationContext)
  const state = useSelector((state) => state.main)
  console.log(state)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const [indices, setIndices] = useState([params.indexId])
  const [securities, setSecurities] = useState([])
  const [teamPatchTrigger,{isLoading:teamPatchIsLoading}] = useTeamPatchMutation()
  const { data:indexParams = {}, isLoading:indexParamsIsLoading, isFetching:indexParamsisFetching, isError:indexParamsIsError } = useIndexQuery({id:params.indexId})
  const [shareDialogShow, setShareDialogShow] = useState(false)
  const [valueExportDialogOpen,setValueExportDialogOpen] = useState(false)
  const [factsheetDialogOpen, setFactsheetDialogOpen] = useState(false)
  const [indexCompareDialogOpen, setIndexCompareDialogOpen] = useState(false)

  useEffect(() => {(async()=>{
    //index probably doesnt exist anymore
    if(!indexParamsIsLoading && (Object.keys(indexParams).length == 0)){
      navigate(`/indices`)
    }
    if(indexParams.display_benchmarks && indexParams.benchmark){
      setSecurities(indexParams.benchmark)
    }
    dispatch({type:'SET_PAGE_TITLE',payload:indexParams.name})
    document.title = `${indexParams.name} | Index One`
  })()},[indexParams])

  const addCompare = (e) => {
    setIndices(indices => [...indices,e] )
  }

  const toggleWatchlist = async (id) => {
    if (!state.teamData){
      createNotification({status: 'error',message: "You must be logged in to use this feature."})
      return
    }
    if(state.teamData.watchlist.hasOwnProperty(id)){
      try{
        const r = await teamPatchTrigger({"id":state.teamData.id,"watchlist_remove":true,"index_id":id})
        dispatch({ type: 'WATCHLIST_REMOVE', payload:id })
        createNotification({status: 'success',message: "Index removed from watchlist."})
      } catch (error) {
        createNotification({status: 'error',message: "Failed to remove index from watchlist."})
      }
    } else {
      try{
        const r = await teamPatchTrigger({"id":state.teamData.id,"watchlist_append":true,"index_id":id})
        dispatch({ type: 'WATCHLIST_ADD', payload:id })
        createNotification({status: 'success',message: "Index added to watchlist."})
      } catch (error) {
        createNotification({status: 'error',message: "Failed to add index to watchlist."})
      }
    }
  }

  const removeCompare = (e) => {
    var index = indices.indexOf(e)
    setIndices([
      ...indices.slice(0, index),
      ...indices.slice(index + 1)
    ]);
  }

  const addSecurityCompare = (e) => {
    setSecurities(securities => [...securities,e] )
  }

  const removeSecurityCompare = (e) => {
    var security = securities.indexOf(e)
    setSecurities([
      ...securities.slice(0, security),
      ...securities.slice(security + 1)
    ]);
  }

  const returnFactsheetDialog = () => {return(
    <Dialog
        onClose={e=>setFactsheetDialogOpen(false)}
        aria-labelledby="customized-dialog-title"
        open={factsheetDialogOpen}
      >
        <DialogTitle>
          Export Factsheet
          <IconButton
            aria-label="close"
            onClick={e=>setFactsheetDialogOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8
            }}>
              <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <FactsheetExport indexId={params.indexId}/>
        </DialogContent>
      </Dialog>
  )}

  const returnValueExportDialog = () => {return(
    <Dialog
        onClose={e=>setValueExportDialogOpen(false)}
        aria-labelledby="customized-dialog-title"
        open={valueExportDialogOpen}
      >
        <DialogTitle>
          Export Index Values
          <IconButton
            aria-label="close"
            onClick={e=>setValueExportDialogOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8
            }}>
              <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <IndexValuesExport indexId={params.indexId}/>
        </DialogContent>
      </Dialog>
  )}

  const returnIndexCompareDialog = () => {return(
    <Dialog
        onClose={e=>setIndexCompareDialogOpen(false)}
        aria-labelledby="customized-dialog-title"
        open={indexCompareDialogOpen}
      >
        <DialogTitle>
          Compare Performance
          <IconButton
            aria-label="close"
            onClick={e=>setIndexCompareDialogOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 12
            }}>
              <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <IndexCompareInterface
            selected={indices}
            addIndex={addCompare}
            removeIndex={removeCompare}
            selectedSecurities={securities}
            addSecurity={addSecurityCompare}
            removeSecurity={removeSecurityCompare}
          />
        </DialogContent>
      </Dialog>
  )}

  return (
    <Container maxWidth={false} disableGutters={true} style={{padding:'1em', backgroundColor:'#fafafa'}}>
      <ShareDialog show={shareDialogShow} setShow={setShareDialogShow} indexId={params.indexId} />
      {returnFactsheetDialog()}
      {returnValueExportDialog()}
      {returnIndexCompareDialog()}
      {!state.authenticated &&
        <Typography variant='h4' sx={{marginTop:'-8px',marginBottom:'10px'}}>{indexParams.name}</Typography>
      }
      <Grid container spacing={3} alignItems='flex-start' justifyContent='space-evenly'>
        <Grid item xs={12} md={9}>
          <Paper style={{height:'100%'}}>
            {!indexParamsIsLoading ?
              <IndexChart
                indices={indices}
                securities={securities}
                createdAt={indexParams.created_at}
              />
            :
              ''
            }
          </Paper>
        </Grid>
        <Grid item xs={12} md={3}>
          <Stack spacing={1}>
          <PriceWidget indexId={params.indexId}/>
          <Paper>
            <Typography style={{padding:'10px'}}>{indexParams.description}</Typography>
            <Table size="small">
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>{indexParams.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Stage</TableCell>
                <TableCell>{StageChip(indexParams.stage)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Calculation Frequency</TableCell>
                <TableCell>{StageChip(indexParams.calculation_frequency)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Bloomberg Ticker</TableCell>
                <TableCell>{indexParams.bloomberg_ticker}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>FIGI</TableCell>
                <TableCell>{indexParams.figi}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Start Time</TableCell>
                <TableCell>{indexParams.start_time}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>End Time</TableCell>
                <TableCell>{indexParams.end_time}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Created At</TableCell>
                <TableCell>{indexParams.created_at}</TableCell>
              </TableRow>
            </Table>
          </Paper>
          <Grid container justifyContent='flex-start' alignItems='flex-start'>
            <Grid item><Tooltip title="Share"><IconButton onClick={e=>setShareDialogShow(true)}><ShareIcon fontSize='large'/></IconButton></Tooltip></Grid>
            {/*<Grid item><Tooltip title="Index Events"><IconButton onClick={e=>navigate(`/index/${params.indexId}/events`)}><EventIcon fontSize='large'/></IconButton></Tooltip></Grid>*/}
            <Grid item><Tooltip title="Create Stream"><IconButton onClick={e=>state.authenticated ? navigate(`/streams?indexId=${params.indexId}`) : navigate(`/login`)}><SettingsEthernetIcon fontSize='large'/></IconButton></Tooltip></Grid>
            <Grid item><Tooltip title="Export"><IconButton onClick={e=> state.authenticated ? setValueExportDialogOpen(true) : navigate(`/login`)}><GetAppIcon fontSize='large'/></IconButton></Tooltip></Grid>
            <Grid item><Tooltip title="Methodology"><IconButton disabled={!indexParams.specification_url} onClick={e=>window.open(indexParams.specification_url,'_blank')}><DescriptionIcon fontSize='large'/></IconButton></Tooltip></Grid>
            <Grid item><Tooltip title="Factsheet"><IconButton onClick={e=>state.authenticated ? setFactsheetDialogOpen(true) : navigate(`/login`)}><DescriptionIcon fontSize='large'/></IconButton></Tooltip></Grid>
            <Grid item><Tooltip title="Clone"><IconButton disabled={!indexParams.clone_allow} onClick={e=>navigate({pathname:'/indexbuilder',props:{indexCloneId:params.indexId}})}><ContentCopyIcon fontSize='large'/></IconButton></Tooltip></Grid>
            <Grid item><Tooltip title="Compare"><IconButton onClick={e=>setIndexCompareDialogOpen(true)}><StackedLineChartIcon fontSize='large'/></IconButton></Tooltip></Grid>
            <Grid item><Tooltip title="Toggle Watchlist">
              <IconButton disabled={teamPatchIsLoading} onClick={e=>toggleWatchlist(params.indexId)}>
                {teamPatchIsLoading ? (
                  <CircularProgress size={24} />
                ) : state.teamData?.watchlist?.hasOwnProperty(params.indexId) ? (
                  <BookmarkRemoveIcon fontSize="large" />
                ) : (
                  <BookmarkAddIcon fontSize="large" />
                )}
              </IconButton>
            </Tooltip></Grid>
          </Grid>
          </Stack>
        </Grid>
        {/*index turnover */}

      </Grid>
      <div style={{height:'2em'}}/>
      {!(indexParams.hasOwnProperty("display_constituent_logos") && indexParams.display_constituent_logos == false) &&
        <WeightingLogos limit={10} indexId={params.indexId} />
      }
      <div style={{height:'1em'}}/>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
        {!indexParams.primary_weighting_chart && <Typography variant='h4'>Top 10 Constituents</Typography> }
          <Paper>
            {indexParams.id && <>
              {
                indexParams.primary_weighting_chart ?
                  <WeightingChart indexId={params.indexId} category={indexParams.primary_weighting_chart.attribute} limit={10}/>
                : (indexParams.display_limited_weightings && indexParams.display_weightings_count) ?
                  <WeightingChart indexId={params.indexId} category="symbol" limit={indexParams.display_weightings_count}/>
                :
                  <WeightingChart indexId={params.indexId} category="symbol" limit={10}/>
              }
            </>}
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          {!indexParams.secondary_weighting_chart && <Typography variant='h4'>Top 10 Industries</Typography> }
          <Paper>
          {indexParams.id && <>
            {indexParams.secondary_weighting_chart ?
              <WeightingChart indexId={params.indexId} category={indexParams.secondary_weighting_chart.attribute} limit={10}/>
              :
              <WeightingChart indexId={params.indexId} category="iex_industry" limit={10}/>
            }
          </>}
          </Paper>
        </Grid>
      </Grid>
      <div style={{height:'2em'}}/>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography variant='h4'>Returns</Typography>
              <StatsTable indexId={params.indexId} returns="7d,30d,ytd,1y"/>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant='h4'>Risk</Typography>
              <StatsTable indexId={params.indexId} volatility="7d,30d,ytd,1y" sharpe="7d,30d,ytd,1y"/>
        </Grid>
      </Grid>
    </Container>
  );
})

export default IndexOverview