import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';

import FileUploadIcon from '@mui/icons-material/FileUpload';


/*
const columns = [
  { field: 'id', headerName: 'ID', width: 90 },
  {
    field: 'firstName',
    headerName: 'First name',
    width: 150,
    editable: true,
  },
  {
    field: 'lastName',
    headerName: 'Last name',
    width: 150,
    editable: true,
  },
  {
    field: 'age',
    headerName: 'Age',
    type: 'number',
    width: 110,
    editable: true,
  },
  {
    field: 'fullName',
    headerName: 'Full name',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 160,
    valueGetter: (params) =>
      `${params.row.firstName || ''} ${params.row.lastName || ''}`,
  },
];

const rows = [
  { id: 1, lastName: 'Snow', firstName: 'Jon', age: 14 },
  { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 31 },
  { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 31 },
  { id: 4, lastName: 'Stark', firstName: 'Arya', age: 11 },
  { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
  { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
  { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
  { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
  { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
];

*/

export default function UniverseDataGrid(props) {
  const [tableData,setTableData] = useState({"rows":[],"columns":[]})

  useEffect(() => {
    if(!props.parsedFileData){return}
    setTableData({
      rows:props.parsedFileData.rows,
      columns:props.parsedFileData.columns.map(item => ({ field: item,flex:1,headerName:item[0].toUpperCase() + item.substring(1) }))
    })
  },[props]);

  const noRows = () => {
    return(
      <Box  display="flex" justifyContent="center" alignItems="center" minHeight='100%'>
        <Button size='large' onClick={e=>props.setUploadDialogOpen(true)} variant="outlined" startIcon={<FileUploadIcon />}>Import Universe File</Button>
      </Box>
    )
  }

  return (
    <Box sx={{ height: '100%', width: '100%' }}>
      <DataGrid
        components={{
          NoRowsOverlay: noRows,
        }}
        rowHeight={25}
        rows={tableData.rows}
        columns={tableData.columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        checkboxSelection={false}
        disableRowSelectionOnClick={true}
      />
    </Box>
  );
}