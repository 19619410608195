import React, {useCallback,useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '@mui/material/Button'
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Typography, Stack,Paper, IconButton,Dialog,DialogContent,DialogTitle,Grid } from '@mui/material';
import { uploadFile } from '../../helpers/fileHelper';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

const identifiers = [
  {
    "value":"symbol",
    "label":"Symbol"
  },
  {
    "value":'id',
    "label":"FIGI (Exchange Level)"
  }
]

const securityMappingSample = {
  "id":["BBG000BPHFS9","BBG000B9Y5X2"],
  "symbol":["MSFT","AAPL"]
}

const weightFormatSample = {
  "decimal":[0.25,0.75],
  "percent":[25,75]
}

const universeFileTypes = [
  {
    "format":"time_security_weight",
    "has_time":true,
    "has_security":true,
    "has_weight":true
  },
  {
    "format":"time_security",
    "has_time":true,
    "has_security":true,
    "has_weight":false
  },
  {
    "format":"security_weight",
    "has_time":false,
    "has_security":true,
    "has_weight":true
  },
  {
    "format":"security",
    "has_time":false,
    "has_security":true,
    "has_weight":false
  }
]

export default function FileUploadDialog(props) {
  const teamId = useSelector(state => state.main.teamData.id)
  const [hasHeaders,setHasHeaders] = useState(false)
  const [securityIdentifier,setSecurityIdentifier] = useState("symbol")
  const [weightFormat,setWeightFormat] = useState("decimal")

  
  const fileTypes = [
    'panel',
    'basket',
    'basket_unweighted',
    'fixed',
    'fixed_unweighted'
  ]

  const returnSampleCsv = (type) => {
    if(type == 'time_security_weight'){
      return(
        <Table size='small' sx={{"& .MuiTableCell-root": {
          border: "1px solid rgba(81, 81, 81, 1)"
        }}}>
        {hasHeaders &&
        <TableRow>
          <TableCell>time</TableCell>
          <TableCell>security</TableCell>
          <TableCell>weight</TableCell>
        </TableRow>
        }
        <TableRow>
          <TableCell>2015-01-01</TableCell>
          <TableCell>{securityMappingSample[securityIdentifier][0]}</TableCell>
          <TableCell>{weightFormatSample[weightFormat][0]}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell><b>...</b></TableCell>
          <TableCell><b>...</b></TableCell>
          <TableCell><b>...</b></TableCell>
        </TableRow>
        <TableRow>
          <TableCell>2016-01-01</TableCell>
          <TableCell>{securityMappingSample[securityIdentifier][1]}</TableCell>
          <TableCell>{weightFormatSample[weightFormat][1]}</TableCell>
        </TableRow>
      </Table>
      )
    }else if(type == 'time_security'){
      return(
        <Table size='small' sx={{"& .MuiTableCell-root": {
          border: "1px solid rgba(81, 81, 81, 1)"
        }}}>
        {hasHeaders &&
          <TableRow>
            <TableCell><b>time</b></TableCell>
            <TableCell><b>security</b></TableCell>
          </TableRow>
        }
        <TableRow>
          <TableCell>2015-01-01</TableCell>
          <TableCell>{securityMappingSample[securityIdentifier][0]}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell><b>...</b></TableCell>
          <TableCell><b>...</b></TableCell>
        </TableRow>
        <TableRow>
          <TableCell>2016-01-01</TableCell>
          <TableCell>{securityMappingSample[securityIdentifier][1]}</TableCell>
        </TableRow>
      </Table>
      )
    } else if(type == 'security_weight'){
      return(
        <Table size='small' sx={{"& .MuiTableCell-root": {
          border: "1px solid rgba(81, 81, 81, 1)"
        }}}>
        {hasHeaders &&
          <TableRow>
            <TableCell><b>security</b></TableCell>
            <TableCell><b>weight</b></TableCell>
          </TableRow>
        }
        <TableRow>
          <TableCell>{securityMappingSample[securityIdentifier][0]}</TableCell>
          <TableCell>{weightFormatSample[weightFormat][0]}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell><b>...</b></TableCell>
          <TableCell><b>...</b></TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{securityMappingSample[securityIdentifier][1]}</TableCell>
          <TableCell>{weightFormatSample[weightFormat][1]}</TableCell>
        </TableRow>
      </Table>
      )
    } else if(type == 'security'){
      return(
        <Table size='small' sx={{"& .MuiTableCell-root": {
          border: "1px solid rgba(81, 81, 81, 1)"
        }}}>
        {hasHeaders &&
          <TableRow>
            <TableCell><b>security</b></TableCell>
          </TableRow>
        }
        <TableRow>
          <TableCell>{securityMappingSample[securityIdentifier][0]}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell><b>...</b></TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{securityMappingSample[securityIdentifier][1]}</TableCell>
        </TableRow>
      </Table>
      )
    }
  }

  const csvWeightsToArray = (str, delimiter = ",") => {
    // slice from start of text to the first \n index
    // use split to create an array from string by delimiter
    let headers = str.slice(0, str.indexOf("\n")).split(delimiter);
    for (let i = 0; i < headers.length; i++) {
        headers[i] = headers[i].replace('\r','')
    }
    let res = {}
    for (let i = 0; i < headers.length; i++) {
      if(headers[i] !== ''){
        res[headers[i].substring(0,10)] = {}
      }
    }
    // slice from \n index + 1 to the end of the text
    // use split to create an array of each csv value row
    const rows = str.slice(str.indexOf("\n") + 1).split("\n");
    for (let i = 0; i < rows.length; i++) {
      let values = rows[i].split(delimiter);
      if(values.length <= 1){continue}
      for (let ii = 0; ii < values.length; ii++) {
        let v = values[ii].replace('\r','')
        if(!isNaN(v)){
          v = parseFloat(values[ii])
          if(v !== 0){
            res[headers[ii].substring(0,10)][values[0]] = v
          }
        }
      }
    }
    console.log(res)
    props.setWeighting(props.type,res)
  }

  const csvPanelToObj = (str, delimiter = ",") => {
    // slice from start of text to the first \n index
    // use split to create an array from string by delimiter
    let res = {}
    const rows = str.split("\n");
    for (let i = 0; i < rows.length; i++) {
      let v = rows[i].replace('\r','')
      v = v.split(delimiter);
      if(v.length <= 2){continue}
      let time = v[0]
      let ticker = v[1]
      let weight = v[2]
      if(!isNaN(weight)){
        weight = parseFloat(weight)
        if(weight !== 0){
          if(!res.hasOwnProperty(time)){
            res[time] = {[ticker]:weight}
          } else {
            res[time][ticker] = weight
          }
        }
      }
    }
    props.setWeighting(props.type,res)
  }

  const csvUniverseToArray = (str, delimiter = ",") => {
    console.log(str)
    // slice from start of text to the first \n index
    // use split to create an array from string by delimiter
    let headers = str.slice(0, str.indexOf("\n")).split(delimiter);
    for (let i = 0; i < headers.length; i++) {
        headers[i] = headers[i].replace('\r','')
    }
    let res = {}
    for (let i = 0; i < headers.length; i++) {
      if(headers[i] !== ''){
        res[headers[i].substring(0,10)] = []
      }
    }
    // slice from \n index + 1 to the end of the text
    // use split to create an array of each csv value row
    const rows = str.slice(str.indexOf("\n") + 1).split("\n");
    console.log(headers)
    console.log(res)
    console.log(rows)
    for (let i = 0; i < rows.length; i++) {
      let values = rows[i].split(delimiter);
      console.log(values)
      if(values.length <= 1){continue}
      for (let ii = 0; ii < values.length; ii++) {
        let v = values[ii].replace('\r','')
        if(v.length !== 0){
          res[headers[ii].substring(0,10)].push(v)
        }
      }
    }
    console.log(res)
    props.setWeighting(props.type,res)
  }

  const csvFixedToArray = (str, delimiter = ",") => {
    console.log(str)
    let res = []
    // slice from \n index + 1 to the end of the text
    // use split to create an array of each csv value row
    const rows = str.split("\n");
    console.log(rows)
    for (let i = 0; i < rows.length; i++) {
      res.push(rows[i].replace('\r',''))
    }
    console.log(res)
    props.setWeighting(props.type,res)
  }

  const csvFixedToObj = (str, delimiter = ",") => {
    console.log(str)
    let res = {}
    // slice from \n index + 1 to the end of the text
    // use split to create an array of each csv value row
    const rows = str.split("\n");
    for (let i = 0; i < rows.length; i++) {
      let values = rows[i].split(delimiter);
      if(values[1] == 0){continue}
      res[values[0]] = parseFloat(values[1].replace('\r',''))
    }
    console.log(res)
    props.setWeighting(props.type,res)
  }
  
  const handleFileSelected = (event,format) => {
    if(props.setLoading){props.setLoading(true)}
    if (event.target.files[0].length === 0){return}
    const file = event.target.files[0]
    const reader = new FileReader()

    reader.onabort = () => console.log('file reading was aborted')
    reader.onerror = () => console.log('file reading has failed')
    reader.onload = () => {
    // Do whatever you want with the file contents
      if(!file.name.endsWith(".csv")){
        alert('File extension must be .csv')
        return
      }
      let data = reader.result
      const refData = {
        ...format,
        ...{"has_headers":hasHeaders,"identifier":securityIdentifier,"weight_format":weightFormat},
        ...{"file_type":"universe"}
      }
      alert(JSON.stringify(refData))
      if(props.handleFile){props.handleFile(data,refData)}
      const uploadResponse = uploadFile(
        data,
        file.name.split(".")[0],
        "csv",
        "",
        teamId,
        refData,
        "team",
        "file_bucket"
      )
      console.log(uploadResponse)
      //props.returnData(data)
    }
    reader.readAsText(file)
    if(props.setLoading){props.setLoading(false)}
    props.setUploadDialogOpen(false)
  }

  return (
    <Dialog maxWidth={"md"} open={props.uploadDialogOpen} onClose={e=>props.setUploadDialogOpen(false)}>
      <DialogTitle>Create New Universe</DialogTitle>
      <DialogContent>
      <Stack direction='row' spacing={3}>
      <FormControlLabel checked={hasHeaders} onChange={e=>setHasHeaders(e.target.checked)} control={<Switch />} label="File has headers" />
      <TextField onChange={e=>setSecurityIdentifier(e.target.value)} value={securityIdentifier} select id="select-identifier" label="Security Identifier" variant="standard" size='small'>
          {identifiers.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
      </TextField>
      <TextField onChange={e=>setWeightFormat(e.target.value)} value={weightFormat} select id="select-weight-format" label="Weight Format" variant="standard" size='small'>
        <MenuItem key="decimal" value="decimal">Decimal</MenuItem>
        <MenuItem key="percent" value="percent">Percent</MenuItem>
      </TextField>
      </Stack>
      <Grid container spacing={3} sx={{paddingTop:'1em'}} alignItems='stretch'>
      {universeFileTypes.map(fileType=>(
        <Grid item xs={6}>
          <Paper sx={{padding:'1em', height:'100%'}} elevation={5}>
            <Stack direction='row' justifyContent='space-between' sx={{paddingBottom:'1em'}}>
              <Typography variant='body2'>
                {
                  fileType == 'basket' ?
                    'Time-series universe & weights.'
                  : fileType == 'basket_unweighted' ?
                    'Time-series universe using regular weighting criteria.'
                  : fileType == 'fixed' ?
                    'Fixed universe & weights using a regular rebalancing schedule.'
                  : fileType == 'fixed_unweighted' ?
                    'Fixed universe using regular weighting criteria and rebalancing schedule.'
                  : fileType == 'panel' ?
                    'Panel data of time and weights'
                  :
                    ''
                }
              </Typography>
              <label htmlFor={fileType.format}>
                <input
                  id={fileType.format}
                  type="file"
                  accept=".csv" // You can specify the accepted file types here
                  style={{ display: 'none' }}
                  onChange={e=>handleFileSelected(e,fileType)}
                />
                <IconButton size='small' variant='contained' component='span' sx={{height:'30px'}}><FileUploadIcon/></IconButton>
              </label>
              </Stack>
              {returnSampleCsv(fileType.format)}
            </Paper>
        </Grid>
      ))}
      </Grid>
      </DialogContent>

    </Dialog>
  )
}