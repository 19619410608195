import * as React from 'react';
import {Box,Avatar} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

const columns = [
    {   
        field: 'logo',
        headerName: '',
        width: 90,
        renderCell: (params) => (
          <Avatar sx={{ width: 24, height: 24 }} alt={params.row.symbol} src={`https://storage.googleapis.com/iex/api/logos/${params.row.symbol}.png`} />
        ),
        flex:1
    },
    {   
        field: 'symbol',
        headerName: 'Symbol',
        width: 90 ,
        flex:2
    },
    /*{
      field: 'company_name',
      headerName: 'Security Name',
      width: 150,
      editable: true,
      flex:1
    },*/
    {
      field: 'weight',
      headerName: 'Weight',
      width: 150,
      flex:2,
      valueGetter: (params) => {
        if (!params.value) {
          return params.value;
        }
        return params.value * 100;
      },
      valueFormatter: (params) => {
        if (params.value == null) {
          return '';
        }
        return `${params.value.toLocaleString()} %`;
      },
    }
  ];

export default function WeightingTable(props) {

    return (
          <Box sx={{ height: '100%', width: '100%' }}>
      <DataGrid
        sx={{border:0}}
        checkboxSelection={false}
        getRowId={(r) => r.figi}
        rows={props.rowData ?? []}
        columns={columns}
        rowHeight={35}
        headerHeight={35}
        hideFooter={true}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        disableRowSelectionOnClick
      />
    </Box>
    );
  }