import React, { useState,useContext } from 'react';
import { setAuthToken } from '../../../store/reducer';
import { useLoginMutation, usePrefetch,useLazySelfQuery } from '../../../store/indexOneApi';
import { connect, useDispatch } from 'react-redux'
import { useNavigate, Link } from 'react-router-dom'
import { NotificationContext } from '../../elements/NotificationContext';

import Avatar from '@mui/material/Avatar';
import { LoadingButton } from '@mui/lab'
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import { set } from 'lodash';



export default function Login() {
  const { createNotification } = useContext(NotificationContext)
  document.title = `Index One | Login`
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [login, { isLoading }] = useLoginMutation()
  const history = useNavigate()
  const prefetchSelf = usePrefetch('self')
  const [trigger] = useLazySelfQuery()



  const newLogin = async (e) => {
    e.preventDefault()
    try {
      const loginResponse = await login({username:email,password:password})
      dispatch(setAuthToken(loginResponse.data.id_token))
      //alert("LOGINDONE")
      //await trigger({"include_team":true})
      //alert("trigger")
      prefetchSelf({"include_team":true})
      //alert("PREFETCHDONE")
      navigate({pathname:"/dashboard"})
    } catch (err) {
      createNotification({
        message: "Email and password do not match.",
        status: 'warning',
      })
    }
  }


  return (
    <Container maxWidth="xs">
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={1}
        style={{height:'70vh'}}
      >
          <Typography variant="h4" style={{textAlign:'center'}}>
            Sign in to <b style={{fontWeight:300}}>index</b>one.
          </Typography>
          <Container maxWidth='xs'>
          <form noValidate /*onSubmit={e=>handleLogin(e)}*/>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <LoadingButton
              loading={isLoading}
              type='submit'
              fullWidth
              variant="contained"
              color="primary"
              onClick={(e)=>newLogin(e)}
            >
              Sign In
            </LoadingButton>
            <Grid container style={{marginTop:'1em'}}>
              <Grid item xs>
                <Link to="/resetpassword" style={{color:'black'}}>Forgot password?</Link>
              </Grid>
              <Grid item>
                <Link to="/register" style={{color:'black'}}>Don't have an account? Sign Up</Link>
              </Grid>
            </Grid>
          </form>
          </Container>
          </Stack>
    </Container>
  );
}