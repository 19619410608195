import React, { useEffect, useState } from 'react';

import Stack from '@mui/material/Stack'

import Box from '@mui/material/Box';

import { DataGrid,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';

import Typography from '@mui/material/Typography';

import LinearProgress from '@mui/material/LinearProgress';

const columns = [
  { field: 'id', headerName: 'ID',width:150},
  { field: 'action', headerName: 'Action', width:150},
  { field: 'shares_change', headerName: 'Shares Delta',flex:2},
  { field: 'weight_change', headerName: 'Weight Delta',flex:2},
  { field: 'shares_start', headerName: 'Shares Start',flex:2},
  { field: 'weight_start', headerName: 'Weight Start',flex:2},
  { field: 'shares_end', headerName: 'Shares End',flex:2},
  { field: 'weight_end', headerName: 'Weight End',flex:2},
];

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

function TrackerTableFunc(props){
    return(
      <Box sx={{height:'500px',width:'100%'}}>
        <DataGrid
          rows={props.trackerData}
          rowHeight={25}
          columns={columns}
          autoPageSize
          disableMultipleSelection={true}
          components={{
            Toolbar: CustomToolbar,
          }}
        />
      </Box>
    )
  }
  const comparisonFn = function (prevProps, nextProps) {
    return JSON.stringify(prevProps) === JSON.stringify(nextProps)
  }

  const TrackerTable = React.memo(TrackerTableFunc,comparisonFn)
  export default TrackerTable