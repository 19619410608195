import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';

import JSONPretty from 'react-json-pretty';
import Chip from '@mui/material/Chip';

//home logo link
import { Link } from 'react-router-dom'
import logotransparentbgnopadding from '../../../images/logotransparentbgnopadding.svg'

//table
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const drawerWidth = 240;

const parts = [
  {
    title:'Introduction',
    id:'introdivider',
    type:'divider'
  },
  {
    title:'API Documentation',
    id:'intro',
    text:`The Index One API is organized around REST. Our API has resource-oriented URLs, returns JSON-encoded responses, and uses standard HTTP response codes and authentication.

    At present, our public API documentation only covers authentication and data retrieval. In future releases, we will make public endpoints for index creation, manipulation, and management.`
  },
  {
    title:'URLs',
    id:'urls',
    text:`The base URL for the live API is https://api.indexone.io/
    `
  },
  {
    title:'Authentication',
    id:'auth',
    text:`Our API has two authentication methods; OAuth 2.0, and API Key.
    
    The required authentication depends on the API endpoint as well as access rights of the requested resource. The authentication level is defined for each endpoint individually in our documentation.
    
    Authentication tokens are passed in the request header under "Authorization". Authentication tokens expire after 30 minutes, and can be re-issued and refreshed through the API.

    API Keys are passed in the request header under "x-api-key". You can access and rotate your API keys by contacting our support team.
    `
  },
  {
    title:'Authentication',
    id:'authdivider',
    type:'divider'
  },
  {
    title:'Generate Token',
    id:'generatetoken',
    text:`Generate a new authentication token in order to call authenticated endpoints.
  
    `,
    url:'/signin',
    method:'POST',
    request:{
      username:'demo@indexone.io',
      password:'demoPassword123!'
    },
    response:{
      "id_token": "eydW.....QiOjE2",
      "refresh_token": "eyJj.....iOygw",
      "expires_in": 3600
    },
    response_attributes:[
      {
        key:'id_token',
        type:'string',
        description:`An ID token used to authenticate requests`
      },
      {
        'key':'expires_in',
        'type':'number',
        'description':`The amount of time until the token expires (in seconds)`
      }
    ]
  },
  {
    title:'Refresh Token',
    id:'refreshtoken',
    text:`
    Refresh your authentication token using a refresh token.
    `,
    url:'/refresh_token',
    method:'POST',
    request:{
      refresh_token:'eydW.....QiOjE2'
    },
    response:{
      "id_token": "eydW.....QiOjE2",
      "expires_in": 3600
    },
    response_attributes:[
      {
        key:'id_token',
        type:'string',
        description:`An ID token used to authenticate requests`
      },
      {
        'key':'expires_in',
        'type':'number',
        'description':`The amount of time until the token expires (in seconds)`
      }
    ]
  },
  {
    title:'Index Data Retrieval',
    id:'dataRetrievalDivider',
    type:'divider'
  },
  {
    title:'Index Values',
    id:'getIndexValues',
    authentication:['API Key'],
    text:`
      Return index values for the specified index. This endpoint supports different time ranges and increments specified in the Query String Parameters.
    `,
    url:'/index/{index_id}/values',
    method:'GET',
    response:[
      {
        time:'2020-01-01 00:00:00',
        value:1000
      },
      {
        time:'2020-01-02 00:00:00',
        value:1010
      },
      {
        time:'2020-01-03 00:00:00',
        value:1020
      }
    ],
    path_parameters:[
      {
        'key':'index_id',
        'required':'Required',
        'description':`ID of the index you are retrieving.`
      }
    ],
    query_string_parameters:[
      {
        'key':'duration',
        'required':'Optional',
        'description':`Duration of index values to retrieve from the current time.
          Supports the following periods: s(seconds), m(minutes), h(hours), d(days), mo(months) itd(inception-to-date), ytd(year-to-date), qtd(quarter-to-date).
          Valid values are e.g. 7d, 1y, itd, etc.  
        `
      
        },
      {
        key:'increment',
        required:'Optional',
        description:`Time increments between index values returned.
        Supports: h(hour), d(day), w(week), mo(month).
        Valid values are e.g. 1d, 4w, etc.
        `
      },
      {
        key:'limit',
        required:'Optional',
        description:`Maximum number of values to return.`
      }
    ],
    response_attributes:[
      {
        'key':'expires_in',
        'type':'number',
        'description':`The amount of time until the token expires (in seconds)`
      }
    ]
  },
  {
    title:'Index Constituents',
    id:'getIndexConstituents',
    authentication:['API Key'],
    text:`
      Return the current constituents for the given index.
      Constituents are by default keyed by their FIGI Composite, which can be changed using the category parameter.
    `,
    url:'/index/{index_id}/weighting',
    method:'GET',
    response:{
      BBG000B9XRY4:0.5,
      BBG000BPH459:0.25,
      BBG001S5PQL7:0.25
    },
    path_parameters:[
      {
        'key':'index_id',
        'required':'Required',
        'description':`ID of the index you are retrieving.`
      }
    ],
    query_string_parameters:[
      {
        'key':'category',
        'required':'Optional',
        'description':`
          Specify an attribute to categorize and return index constituents by.

        `
        }
    ]
  }

]

export default function Documentation(props) {
  document.title = `Index One | API Documentation`
  const JSONPrettyMon = require('react-json-pretty/dist/monikai');
  const returnMenu = (part) => {
    if (part.type){
      if (part.type === 'divider'){
        return(
          <>
            <Divider/>
            <p style={{paddingLeft:'1em', fontWeight:400, color:'white'}}>{part.title}</p>
          </>
        )
      }
    }

    return(
      <a href={`#${part.id}`} style={{textDecoration:'none'}}>
        <ListItem button key={part.id}>
          <ListItemText primary={part.title} sx={{color:'white', textDecoration:'none', paddingLeft:'1em'}}/>
        </ListItem>
      </a>
    )
  }


  const returnPart = (part) => {
    if (part.type){
      if (part.type === 'divider'){
        return null
      }
    }

    return(
      <>
        <a id={part.id}></a>
        <Divider style={{margin:'50px'}}/>
        <Typography variant='h4' id={part.id}>{part.title}</Typography>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            {part.text.split('\n\n').map(paragraph =>
                <p>
                    {paragraph.split('\n').reduce((total, line) => [total, <br />, line])}
                </p>
            )}
          </Grid>
          <Grid item xs={6}>
              {part.method &&
                <Grid container>
                  <Grid item>
                    <Chip label={part.method} color="primary" />
                  </Grid>
                  <Grid item>
                    <Typography variant='h6' sx={{fontWeight:300}}>&nbsp; {part.url}</Typography>
                  </Grid>
                </Grid>
              }
              {part.authentication &&
                <>
                <br/>
                <Grid container>
                <Grid item>
                  <Typography variant='h6' sx={{fontWeight:300}}>Authentication: &nbsp;</Typography>
                </Grid>
                <Grid item>
                {part.authentication.map((row) => (
                  <Chip label={row} color="primary" />
                ))}  
                </Grid>
              </Grid>
              </>
              }
              {part.request && 
                <>  
                <Typography variant='h6' style={{paddingTop:'1em'}}>Sample Request</Typography>
                <div style={{background:'#272822',padding:'10px 20px', borderRadius:'5px'}}>
                  <JSONPretty id="json-pretty" theme={JSONPrettyMon} data={part.request}></JSONPretty>
                </div>
                </>
              }
              {part.response && 
                <>
                <Typography variant='h6' style={{paddingTop:'1em'}}>Sample Response</Typography>
                <div style={{background:'#272822',padding:'10px 20px',borderRadius:'5px'}}>
                  <JSONPretty id="json-pretty" theme={JSONPrettyMon} data={part.response}></JSONPretty>
                </div>
                </>
              }
          </Grid>
        </Grid>

        {part.path_parameters &&
        <>
        <Typography variant='h6' style={{paddingTop:'1em'}}>Path Parameters</Typography>
        <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>parameter</TableCell>
              <TableCell align="right">required</TableCell>
              <TableCell align="right">description</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {part.path_parameters.map((row) => (
              <TableRow
                key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.key}
                </TableCell>
                <TableCell align="right">{row.required}</TableCell>
                <TableCell align="right">{row.description}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      </>
    }

    {part.query_string_parameters &&
        <>
        <Typography variant='h6' style={{paddingTop:'1em'}}>Query String Parameters</Typography>
        <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>parameter</TableCell>
              <TableCell>required</TableCell>
              <TableCell>description</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {part.query_string_parameters.map((row) => (
              <TableRow
                key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.key}
                </TableCell>
                <TableCell>{row.required}</TableCell>
                <TableCell>{row.description}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      </>
    }
        
      {part.response_attributes &&
        <>
        <Typography variant='h6' style={{paddingTop:'1em'}}>Response Attributes</Typography>
        <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>key</TableCell>
              <TableCell align="right">type</TableCell>
              <TableCell align="right">description</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {part.response_attributes.map((row) => (
              <TableRow
                key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.key}
                </TableCell>
                <TableCell align="right">{row.type}</TableCell>
                <TableCell align="right">{row.description}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      </>
    }


      </>
    )
  }



  return (
    <Box sx={{ display: 'flex' }}>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            backgroundColor: '#343a40 !important'
          },
          backgroundColor: '#343a40 !important'
        }}
        variant="permanent"
        anchor="left"
      >
        <Link to='/' target='_blank' style={{ fontSize: 1, padding:'15em' }}><img style={{ width: 100, marginRight: 45 }} src={logotransparentbgnopadding}></img></Link>
        <List>
          {parts.map((part, idx) => (
            returnMenu(part)
          ))}
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
      >
        {
          parts.map((entry, index) => ( returnPart(entry) ))
        }
      </Box>
    </Box>
  );
}