import React, {useState, useEffect} from 'react';
import { useNavigate, useParams } from "react-router-dom";

import { makeStyles } from '@mui/styles';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'

import { Typography } from '@mui/material';

//images
import index_adjustments1 from '../../../images/index_adjustments1.png'
import index_universe1 from '../../../images/index_universe1.png'
import index_overview1 from '../../../images/index_overview1.png'
import index_events1 from '../../../images/index_events1.png'
import index_integrations1 from '../../../images/index_integrations1.png'
import index_page1 from '../../../images/index_page1.png'

import CodeIcon from '@mui/icons-material/Code';

//dummy chart
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
const dummyData = [
  {
    key: 7,
    value: 1000
  }
];
//realtime calculation
//automated index management
//index design
//index backtesting
//index monitoring
//api connectivity
//direct indexing / your index

const useStyles = makeStyles({
  sideImage:{
    width:'100%',
    textAlign:'center'
  }
})

export default function Solutions(props) {
  document.title = `Index One | Solutions`
  const classes = useStyles()
  const navigate = useNavigate()
  const [chartData,setChartData] = useState(dummyData)
  useEffect(() => {
    const interval = setInterval(() => {
      setChartData(oldArray => [...oldArray, {key:8,value:1000 + Math.random()*500}])
    }, 1000)
    return () => clearInterval(interval)
  }, [])
  return (
    <Container component="main" disableGutters maxWidth={false}>
    <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' style={{backgroundColor:'#343a40', minHeight:'40vh', clipPath: 'polygon(0 0, 100% 0%, 100% 80%, 0% 100%)', marginTop:0}}>
        <Typography style={{color:'#fafafa', margin:'1rem'}} variant="h3">Our Services</Typography>
        <br/>
        <Typography style={{color:'#fafafa', margin:'1rem', marginBottom:'2rem'}} variant="h5"><b style={{fontWeight:300}}>index</b>one provides financial professionals with an array of indexing services.</Typography>
    </Box>
    <Container maxWidth='md' style={{padding:'50px 1rem'}}>

    {/* REALTIME CALC */}
    <Typography variant='h4' textAlign='center'>Backtesting</Typography>
    <br/>
    <Grid container>
      <Grid item sm={6}>
      Index One allows users to generate backtests on customized baskets and index strategies in a matter of seconds, with the possibility to share backtest results externally and within teams, including the ability to view detailed stats on the backtest.
      <br/><br/>
      <Stack direction= 'row' spacing={2} alignItems='center' justifyContent='center'>
    <Button sx={{ width: '200px' }} onClick={e => navigate(`/indexbuilder`)} variant="outlined">Backtest a strategy</Button>
    </Stack>
      </Grid>
      <Grid item sm={6}>
      <img className={classes.sideImage} src={index_overview1}/>
      </Grid>
      </Grid>






    <br/>
    <br/>
        <Typography variant='h4' textAlign='center'>Custom Index Calculation</Typography>
    <br/>
    <Grid container spacing={3}>
    <Grid item sm={6}>
      <img className={classes.sideImage} src={index_universe1}/>
      </Grid>
        <Grid item sm={6}>
        With Index One, you can turn any systematic/ discretionary strategy into an index, with flexibility on various parameters.
        <br/><br/>
      <Stack direction= 'row' spacing={2} alignItems='center' justifyContent='center'>
    <Button sx={{ width: '150px' }} onClick={e => navigate(`/contact`)} variant="outlined">Learn more</Button>
    </Stack>
        </Grid>
      </Grid>

  


    <br/>
    <br/>
        <Typography variant='h4' textAlign='center'>Index Design</Typography>
    <br/>
    <Grid container spacing={3}>
      <Grid item sm={6}>
      Design indices using a step-by-step rules-based methodology in a matter of minutes, while maintaining full control over the most important aspects of your index.
      <br/><br/>
      <Stack direction= 'row' spacing={2} alignItems='center' justifyContent='center'>
    <Button sx={{ width: '150px' }} onClick={e => navigate(`/indexbuilder`)} variant="outlined">Build an index</Button>
    </Stack>
      </Grid>
      <Grid item sm={6}>
        <img className={classes.sideImage} src={index_adjustments1}/>
      </Grid>
    </Grid>




   
    <br/>
    <br/>
    <Typography variant='h4' textAlign='center'>Market Indices</Typography>
    <br/>
    <Grid container spacing={3}>
    <Grid item sm={6}>
      <img className={classes.sideImage} src={index_page1}/>
      </Grid>
      <Grid item sm={6}>
      Index One offers a suite of generic indices for use as a benchmark, base universe, display purposes, and other use cases.
      <br/><br/>
      <Stack direction= 'row' spacing={2} alignItems='center' justifyContent='center'>
    <Button sx={{ width: '150px' }} onClick={e => navigate(`/indices`)} variant="outlined">View indices</Button>
    </Stack>
      </Grid>
    </Grid>


<br/><br/><br/>
<Stack direction= 'row' spacing={2} alignItems='center' justifyContent='center'>
    <Button sx={{ width: '150px' }} onClick={e => navigate(`/contact`)} variant="outlined">Contact Us</Button>
    <Button sx={{ width: '150px' }} color='secondary' onClick={e => navigate(`/register`)} variant="outlined">Register</Button>
    </Stack>
    

    {/*
    <Typography variant='h4' textAlign='center'>BLANK</Typography>
    <br/>
    <Grid container spacing={3}>
      <Grid item sm={6}>
        blank
      </Grid>
      <Grid item sm={6}>
        blank
      </Grid>
    </Grid>
    */}



    </Container>
  </Container>



)}