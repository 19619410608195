import React, {  useEffect } from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@mui/styles';
import "./CreditCard.css"

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography';

import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';

export default function CreditCard(props) {
  return(
    <div className={`credit-card ${props.brand}`}>
        <div className="credit-card-last4">{props.last4}</div>
        <br/>
        <div className="credit-card-expiry">{`${props.expMonth} / ${props.expYear}`}</div>
    </div>
  )
}