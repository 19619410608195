import React, {  useEffect } from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@mui/styles';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography';

import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';

const useStyles = makeStyles({
  diagonal: {
    backgroundImage: 'linear-gradient(to top right, #343a40 calc(50% - 1px), #fafafa 50%)',
    height: '15vh'
  },
  whiteLink:{
    textDecoration: 'none',
    color:'white'
  }
})





export default function Footer() {
  const classes = useStyles()

  useEffect(() => {(async()=>{
    const head = document.querySelector('head');
    const script = document.createElement('script');
    script.setAttribute('src', 'https://platform.twitter.com/widgets.js');
    head.appendChild(script);
  })()},[])


  return(
  <Container disableGutters maxWidth={false} style={{overflow: 'hidden'}}>
  <div className={classes.diagonal}/>
  <Grid spacing={1} container justifyContent='space-evenly' style={{backgroundColor:'#343a40', color:'#fafafa', paddingTop:25,paddingBottom:20}}>
    <Grid item>
      <Typography variant='h5'>Contact</Typography>
      <Typography><br/>contact@indexone.io</Typography>
      <Typography><br/>Kemp House<br/>160 City Road<br/>London<br/>United Kingdom<br/>EC1V 2NX</Typography>
    </Grid>
    <Grid item>
      <Typography variant='h5'>Engage</Typography>
      <br/>
      <Typography className={classes.whiteLink} component={Link} to='/register'>Register</Typography>
      <br/>
      <Typography className={classes.whiteLink} component={Link} to='/contact'>Contact</Typography>
      <br/>
      <Typography className={classes.whiteLink} component={Link} to='/about'>About Us</Typography>
      <br/>
      <Typography className={classes.whiteLink} component={Link} to='/careers'>Careers</Typography>
      <br/>
      <Link to='/careers' style={{textDecoration:'none', fontSize:8}}><Chip size='small' color='primary' label="We're Hiring!" variant="filled" onClick={e => null} sx={{fontWeight:'bold'}} /></Link>
    </Grid>
    <Grid item>
      <Typography variant='h5'>Learn</Typography>
      <br/>
      <Typography className={classes.whiteLink} component={Link} to='/solutions'>Solutions</Typography>
      <br/>
      <Typography className={classes.whiteLink} component={Link} to='/resources'>Resources</Typography>
      <br/>
      <Typography className={classes.whiteLink} component={Link} to='/pricing'>Pricing</Typography>
      <br/>
      <Typography className={classes.whiteLink} component={Link} to='/integrations'>API Integrations</Typography>
      <br/>
      <Typography className={classes.whiteLink} component={Link} to='/terms'>T&C's</Typography>
    </Grid>
    <Grid item>
      <Typography variant='h5'>Knowledge</Typography>
      <br/>
      <Typography className={classes.whiteLink} component={Link} to='/pillar'>Custom Indexing</Typography>
      <br/>
      <Typography className={classes.whiteLink} component={Link} to='/directindexing'>Direct Indexing</Typography>
      <br/>
      <Typography className={classes.whiteLink} component={Link} to='/esg'>ESG and Sustainable Indices</Typography>
      <br/>
      <Typography className={classes.whiteLink} component={Link} to='/indexfunds'>Low-Cost Index Funds</Typography>
      <br/>
      <Typography className={classes.whiteLink} component={Link} to='/rebalancing'>Rebalancing and Corporate Actions</Typography>
      <br/>
      </Grid>
    <Grid item>
      <Typography variant='h5'>Start</Typography>
      <br/>
      <Typography className={classes.whiteLink} component={Link} to='/dashboard'>Dashboard</Typography>
      <br/>
      <Typography className={classes.whiteLink} component={Link} to='/indexbuilder'>Index Builder</Typography>
      <br/>
      <Typography className={classes.whiteLink} component={Link} to='/indices'>Indices</Typography>
      <br/>
      <Typography className={classes.whiteLink} component={Link} to='/teamindices'>Team Indices</Typography>
    </Grid>
    <Grid item xs={12} sm={2}>
    <a class="twitter-timeline" data-height="300" data-theme="dark" href="https://twitter.com/index_one1?ref_src=twsrc%5Etfw">Tweets by Index One</a> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
    </Grid>
  </Grid>
  <Grid spacing={4} container justifyContent='center'style={{backgroundColor:'#343a40', color:'#fafafa', paddingTop:20,paddingBottom:20}}>
    <Grid item>
      <a href="https://www.facebook.com/Index-One-109253073751321/">
        <FacebookIcon color='primary' fontSize='large'/>
      </a>
    </Grid>
    <Grid item>
      <a href="https://www.linkedin.com/company/index-one">
        <LinkedInIcon color='primary' fontSize='large'/>
      </a>
    </Grid>
    <Grid item>
      <a href="https://twitter.com/index_one1">
        <TwitterIcon color='primary' fontSize='large'/>
      </a>
    </Grid>
    <Grid item>
      <a href="https://www.instagram.com/indexone1">
        <InstagramIcon color='primary' fontSize='large'/>
      </a>
    </Grid>
  </Grid>
  </Container>
  )
}