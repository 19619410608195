import React, { useEffect,useState } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { logOut } from '../../../store/reducer';
import { Link } from 'react-router-dom'
import { makeStyles } from '@mui/styles';

import MobileDrawer from '@mui/material/Drawer';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import theme from '../../../theme'
import Snackbar from '@mui/material/Snackbar'
import { styled, useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Hidden from '@mui/material/Hidden';
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import logotransparentbgnopadding from '../../../images/logotransparentbgnopadding.svg'
import {usePopupState,bindHover,bindPopover,} from 'material-ui-popup-state/hooks'
import Popover from 'material-ui-popup-state/HoverPopover'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';


//side menu
import Tooltip from '@mui/material/Tooltip'
import DashboardIcon from '@mui/icons-material/Dashboard';
import AddIcon from '@mui/icons-material/Add';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import TrendingUpOutlinedIcon from '@mui/icons-material/TrendingUpOutlined';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import GroupWorkOutlinedIcon from '@mui/icons-material/GroupWorkOutlined';
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import CodeIcon from '@mui/icons-material/Code';
import KeyIcon from '@mui/icons-material/Key';
import SupportIcon from '@mui/icons-material/Support';




const drawerWidth = 200;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  backgroundColor: '#343a40 !important',
  color: 'white !important',
  overflowX: 'hidden'
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: '#343a40 !important',
  overflowX: 'hidden',
  color: 'white !important',
  width: `calc(${theme.spacing(5)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(7)} - 10px)`
  }
});


const useStyles = makeStyles((theme) => ({
  hide: {
    display: 'none',
  },
  darklink: {
    color: '#353b41',
    fontWeight: 400
  },
  content: {
    flexGrow: 1
    //padding: theme.spacing(3),
  },
  RoutesWrapper: {
    position: 'absolute'
  }
}))

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  zIndex: 100,
  position:'fixed',
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  zIndex:99,
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  marginTop:'-25px'
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    color: 'white',
    zIndex: 99,
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);



export default function TopMenu() {
  const dispatch = useDispatch()
  const authenticated = useSelector(state => state.main.authenticated)
  const pageTitle = useSelector(state => state.main.pageTitle)
  const teamName = useSelector(state => state.main.teamData?.name ?? "")
  //const desktopMenuOpen = useSelector(state => state.main.desktopMenuOpen)
  const [drawerOpen, toggleDrawer] = useState(false)
  const [desktopMenuOpen,setDesktopMenuOpen] = useState(false)
  const classes = useStyles()



  useEffect(() => {(async()=>{

  })()},[])

  const handleLogOut = () => {
    dispatch(logOut())
  }

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'demoPopover',
    overflow: 'hidden',
    disableAutoFocus: true
  })


  const returnPopover = () => {
    return (
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        disableRestoreFocus
      >
        <Grid container spacing={5} style={{ padding: 20, marginBottom: 0 }}>
          <Grid item sm={6}>
            <Typography style={{ margin: 20 }}>By Company</Typography>
            <Divider />
            <List>
              <ListItem onClick={e => handleMenuClose()} className={classes.darklink} component={Link} to="/usecase/ria">RIAs</ListItem>
              <ListItem onClick={e => handleMenuClose()} className={classes.darklink} component={Link} to="/usecase/hedgefunds">Hedge Fund Managers</ListItem>
              <ListItem onClick={e => handleMenuClose()} className={classes.darklink} component={Link} to="/usecase/assetowners">Asset Owners</ListItem>
              <ListItem onClick={e => handleMenuClose()} className={classes.darklink} component={Link} to="/usecase/dataproviders">Data Providers</ListItem>
              <ListItem onClick={e => handleMenuClose()} className={classes.darklink} component={Link} to="/usecase/structuredproducts">Structured Products</ListItem>
              <ListItem onClick={e => handleMenuClose()} className={classes.darklink} component={Link} to="/usecase/wealthmanagers">Wealth Managers</ListItem>
              <ListItem onClick={e => handleMenuClose()} className={classes.darklink} component={Link} to="/usecase/research">Equity Researchers</ListItem>
              <ListItem onClick={e => handleMenuClose()} className={classes.darklink} component={Link} to="/usecase/fintech">Fintech</ListItem>
              <ListItem onClick={e => handleMenuClose()} className={classes.darklink} component={Link} to="/usecase/academics">Academics</ListItem>
              <ListItem onClick={e => handleMenuClose()} className={classes.darklink} component={Link} to="/contact">other...</ListItem>
            </List>
          </Grid>
          <Grid item sm={6}>
            <Typography style={{ margin: 20 }}>By Function</Typography>
            <Divider />
            <List>
              <ListItem onClick={e => handleMenuClose()} className={classes.darklink} component={Link} to="/usecase/calculation">Custom Index Calculation</ListItem>
              <ListItem onClick={e => handleMenuClose()} className={classes.darklink} component={Link} to="/usecase/benchmarking">Custom Benchmarks and Market Indices</ListItem>
              <ListItem onClick={e => handleMenuClose()} className={classes.darklink} component={Link} to="/usecase/backtesting">Backtesting and Basket Calculation</ListItem>
              <ListItem onClick={e => handleMenuClose()} className={classes.darklink} component={Link} to="/usecase/directindexing">Direct Indexing</ListItem>
              <ListItem onClick={e => handleMenuClose()} className={classes.darklink} component={Link} to="/usecase/rebalancing">Rebalancing and Corporate Actions</ListItem>
              <ListItem onClick={e => handleMenuClose()} className={classes.darklink} component={Link} to="/usecase/replication">Index Replication</ListItem>
              <ListItem onClick={e => handleMenuClose()} className={classes.darklink} component={Link} to="/usecase/verification">Index Verification</ListItem>
              <ListItem onClick={e => handleMenuClose()} className={classes.darklink} component={Link} to="/usecase/delivery">Index Data Delivery</ListItem>
              <ListItem onClick={e => handleMenuClose()} className={classes.darklink} component={Link} to="/usecase/research">Equity Research</ListItem>
              <ListItem onClick={e => handleMenuClose()} className={classes.darklink} component={Link} to="/usecase/factsheets">Custom Factsheet Generation</ListItem>
              <ListItem onClick={e => handleMenuClose()} className={classes.darklink} component={Link} to="/usecase/integrations">Index Integrations</ListItem>
              <ListItem onClick={e => handleMenuClose()} className={classes.darklink} component={Link} to="/contact">other...</ListItem>
            </List>
          </Grid>
        </Grid>
      </Popover>
    )
  }

  const handleMenuClose = () => {
    popupState.close()
    toggleDrawer(false)
  }


  return(
    <>
    <AppBar
    open={desktopMenuOpen}
    sx={{ backgroundColor: '#343a40' }}
  >
    <Toolbar variant='dense' style={{ paddingLeft: '1em',height:'10px',minHeight:'40px' }}>
      {authenticated &&
        <Hidden mdDown>
          <IconButton
            color="primary"
            aria-label="open drawer"
            onClick={e => setDesktopMenuOpen(prev => (!prev)) /*dispatch({ type: 'DESKTOP_MENU' })*/}
            edge="start"
            sx={{marginRight:'10px',paddingLeft:'11px'}}
          >
            {desktopMenuOpen ? <CloseIcon /> : <MenuIcon />}
          </IconButton>
        </Hidden>
      }
      <Hidden mdUp>
        <IconButton
          color="primary"
          aria-label="open drawer"
          onClick={e => toggleDrawer(prev => (!prev))}
          edge="start"
        >
          <MenuIcon />
        </IconButton>
        <MobileDrawer anchor='top' open={drawerOpen} onClose={e => toggleDrawer(false)}>
          {authenticated ?
            <>
              <Button component={Link} onClick={e => toggleDrawer(false)} to="/dashboard">Dashboard</Button>
              <Button component={Link} onClick={e => toggleDrawer(false)} to="/files">Files</Button>
              <Button component={Link} onClick={e => toggleDrawer(false)} to="/indexbuilder">Index Builder</Button>
              <Button component={Link} onClick={e => toggleDrawer(false)} to="/indices">Indices</Button>
              <Button component={Link} onClick={e => toggleDrawer(false)} to="/teamindices">Team Indices</Button>
              <Button component={Link} onClick={e => toggleDrawer(false)} to="/streams">Streams</Button>
              <Button component={Link} onClick={e => toggleDrawer(false)} to="/team">Team Overview</Button>
              <Button component={Link} onClick={e => toggleDrawer(false)} to="/teamkeys">API Keys</Button>
              <Button component={Link} onClick={e => toggleDrawer(false)} to="/billing">Billing</Button>
              <Button component={Link} onClick={e => toggleDrawer(false)} to="/docs/api">Documentation</Button>
            </>
            :
            <>
              <Button component={Link} onClick={e => toggleDrawer(false)} to="/solutions">Solutions</Button>
              <Button component={Link} onClick={e => toggleDrawer(false)} to="/services">Services</Button>
              <Button component={Link} onClick={e => toggleDrawer(false)} to="/indices">Indices</Button>
              <Button component={Link} onClick={e => toggleDrawer(false)} to="/resources">Resources</Button>
              <Button component={Link} onClick={e => toggleDrawer(false)} to="/pricing">Pricing</Button>
              <Button  onClick={e => popupState.open([e])} >Use Cases</Button>
              {returnPopover()}
            </>
          }
        </MobileDrawer>
      </Hidden>
      <Link to='/' style={{ fontSize: 1, paddingBottom: 5 }}><img style={{ width: 80, marginLeft: '1em', marginRight: 45,marginTop:3 }} src={logotransparentbgnopadding}></img></Link>
      <div style={{ flexGrow: 1 }} />
      {authenticated ?
        <Typography style={{ textTransform: 'uppercase', fontWeight: 300, fontSize: '14px', color: 'white' }}>{pageTitle}</Typography>
        :
        <Hidden mdDown>
          <Button component={Link} className={classes.navlink} to="/solutions" style={{ color: 'white', fontWeight: 300 }}>Solutions</Button>
          
          <Button component={Link} className={classes.navlink} {...bindHover(popupState)} style={{ color: 'white', fontWeight: 300 }}>Use Cases</Button>
          {returnPopover()}
          <Button component={Link} className={classes.navlink} to="/indices" style={{ color: 'white', fontWeight: 300 }}>Indices</Button>
          <Button component={Link} className={classes.navlink} to="/resources" style={{ color: 'white', fontWeight: 300 }}>Resources</Button>
          <Button component={Link} className={classes.navlink} to="/pricing" style={{ color: 'white', fontWeight: 300 }}>Pricing</Button>
        </Hidden>
      }
      <div style={{ flexGrow: 1 }} />
      {authenticated ?
        <>

          <Typography style={{ color: '#fafafa' }}>{teamName}</Typography>
          <Button color='secondary' onClick={() => handleLogOut()}>Logout</Button>
        </>
        :
        <>
          <Button component={Link} to="/register" color="primary">Register</Button>
          <Button component={Link} to="/login" color="secondary">Login</Button>
        </>
      }
    </Toolbar>
  </AppBar>
  {authenticated &&
                <Hidden mdDown>
                  <Drawer
                    variant="permanent"
                    open={desktopMenuOpen}
                    PaperProps={{ elevation: 5 }}
                  >
                    <DrawerHeader></DrawerHeader>
                    <List sx={{marginLeft:'-3px'}}>
                      <Tooltip title='Dashboard' placement="right">
                      <ListItem button={Button ? true : false} key="Dashboard" component={Link} to="/dashboard">
                        <ListItemIcon><DashboardIcon style={{ color: 'white' }} /></ListItemIcon>
                        <ListItemText className={classes.navlink} primary="Dashboard" />
                      </ListItem>
                      </Tooltip>
                      <Tooltip title='Index Builder' placement="right">
                      <ListItem button={Button ? true : false} key="Index Builder" component={Link} to="/indexbuilder">
                        <ListItemIcon><AddIcon style={{ color: 'white' }} /></ListItemIcon>
                        <ListItemText primary="Index Builder" />
                      </ListItem>
                      </Tooltip>
                      <Tooltip title='Indices' placement="right">
                      <ListItem button={Button ? true : false} key="Indices" component={Link} to="/indices">
                        <ListItemIcon><TrendingUpOutlinedIcon style={{ color: 'white' }} /></ListItemIcon>
                        <ListItemText primary="Indices" />
                      </ListItem>
                      </Tooltip>
                      <Tooltip title='Team Indices' placement="right">
                      <ListItem button={Button ? true : false} key="Team Indices" component={Link} to="/teamindices">
                        <ListItemIcon><GroupWorkOutlinedIcon style={{ color: 'white' }} /></ListItemIcon>
                        <ListItemText primary="Team Indices" />
                      </ListItem>
                      </Tooltip>
                      <Tooltip title='Files' placement="right">
                      <ListItem button={Button ? true : false} key="Files" component={Link} to="/files">
                        <ListItemIcon><FolderOpenIcon style={{ color: 'white' }} /></ListItemIcon>
                        <ListItemText primary="Files" />
                      </ListItem>
                      </Tooltip>
                      <Tooltip title='Streams' placement="right">
                      <ListItem button={Button ? true : false} key="Streams" component={Link} to="/streams">
                        <ListItemIcon><SettingsEthernetIcon style={{ color: 'white' }} /></ListItemIcon>
                        <ListItemText primary="Streams" />
                      </ListItem>
                      </Tooltip>
                    </List>
                    <div style={{flexGrow:1}}></div>
                    <List sx={{marginLeft:'-3px'}}>
                      <Tooltip title='Team' placement="right">
                      <ListItem button={Button ? true : false} key="Team" component={Link} to="/team">
                        <ListItemIcon><GroupOutlinedIcon style={{ color: 'white' }} /></ListItemIcon>
                        <ListItemText primary="Team" />
                      </ListItem>
                      </Tooltip>
                      <Tooltip title='API Keys' placement="right">
                        <ListItem button={Button ? true : false} key="API Keys" component={Link} to="/teamkeys">
                          <ListItemIcon><KeyIcon style={{ color: 'white' }} /></ListItemIcon>
                          <ListItemText primary="API Keys" />
                        </ListItem>
                      </Tooltip>
                      <Tooltip title='Resources' placement="right">
                        <ListItem button={Button ? true : false} key="Resources" component={Link} to="/resources">
                          <ListItemIcon><MenuBookIcon style={{ color: 'white' }} /></ListItemIcon>
                          <ListItemText primary="Resources" />
                        </ListItem>
                      </Tooltip>
                      <Tooltip title='Billing' placement="right">
                      <ListItem button={Button ? true : false} key="Billing" component={Link} to="/billing">
                        <ListItemIcon><CreditCardOutlinedIcon style={{ color: 'white' }} /></ListItemIcon>
                        <ListItemText primary="Billing" />
                      </ListItem>
                      </Tooltip>
                      <Tooltip title='Documentation' placement="right">
                      <ListItem button={Button ? true : false} key="Documentation" component={Link} to="/docs/api" target="_blank">
                        <ListItemIcon><CodeIcon style={{ color: 'white' }} /></ListItemIcon>
                        <ListItemText primary="Documentation" />
                      </ListItem>
                      </Tooltip>
                      <Tooltip title='Support' placement="right">
                      <ListItem button={Button ? true : false} key="Support" component={Link} to="/support">
                        <ListItemIcon><SupportIcon style={{ color: 'white' }} /></ListItemIcon>
                        <ListItemText primary="Support" />
                      </ListItem>
                      </Tooltip>
                    </List>
                  </Drawer>
                </Hidden>
              }
  </>)
}